import { useInfiniteQuery } from 'react-query';
import { FetchAssetsResponse, QUERY_KEYS } from '@24i/nxg-sdk-photon/src';

import { useContentData } from '../../../context/ContentData';
import { useMinSearchQueryLength } from '../../search';

export const useSearchQuery = ({
    query,
    size,
    enabled,
}: {
    query: string;
    size: number;
    enabled: boolean;
}) => {
    const contentDataClient = useContentData();
    const { minSearchQueryLength } = useMinSearchQueryLength();

    return useInfiniteQuery<FetchAssetsResponse>({
        queryKey: [QUERY_KEYS.search, query],
        queryFn: ({ pageParam = 0 }) =>
            contentDataClient.searchPaginatedAssets(query, pageParam, size),

        getNextPageParam: (lastPage) => {
            const targetPageSize = size || lastPage.items.length;
            const offset = lastPage.offset ?? 0;
            const total = lastPage.total ?? 0;
            return offset + targetPageSize < total ? offset + targetPageSize : undefined;
        },
        enabled: enabled && query.length >= minSearchQueryLength,
    });
};
