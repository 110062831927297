import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';

const styles = (theme: Theme) => ({
    mediaContainer: {
        flexDirection: 'row',
    },
    logoStyles: {
        height: 38,
        width: 48,
    },
    titleAndSubtitleContainer: {
        marginLeft: 12,
        width: 224,
    },
    titleStyles: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h4'),
    },
    subtitleStyles: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h4'),
    },
    additionalDisconnectButtonStyles: {
        marginLeft: 14,
        alignSelf: 'center',
    },
});

export default styles;
