import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { FooterStylesGetter, FooterStylesObj } from './types';

const Footer: FooterStylesGetter = (theme: Theme) => {
    const typed: FooterStylesObj = {
        footerContainer: {
            marginTop: 40,
            backgroundColor: 'transparent',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            alignSelf: 'center',
            bottom: 0,
            '@media (min-width: 441px) and (max-width: 640px)': { width: 400 },
            '@media (min-width: 641px) and (max-width: 864px)': { width: 600 },
            '@media (min-width: 865px)': { width: 800 },
        },
        footerBackground: {
            marginTop: 40,
            alignItems: 'center',
            justifyContent: 'center',
            bottom: 0,
        },
        iconsContainer: {
            marginBottom: 32,
            flexDirection: 'row',
            width: '100%',
            flexWrap: 'wrap',
            alignSelf: 'center',
            '@media (max-width: 440px)': {
                justifyContent: 'center',
                alignSelf: 'center',
            },
        },
        itemsContainer: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginBottom: 32,
            width: '100%',
            alignSelf: 'center',
            '@media (max-width: 440px)': {
                flexDirection: 'column',
                alignItems: 'center',
                alignSelf: 'center',
                paddingRight: 16,
                paddingLeft: 16,
            },
        },
        disclaimerContainer: {
            display: 'flex',
            marginBottom: 24,
            '@media (max-width: 440px)': {
                display: 'table',
            },
        },
        disclaimerText: {
            marginBottom: 24,
            alignSelf: 'flex-start',
            ...getFont(theme, 'h5'),
            fontSize: 13,
            opacity: 0.4,
            color: theme.color.textPrimary,
            '@media (max-width: 440px)': {
                display: 'table-cell',
                verticalAlign: 'middle',
                textAlign: 'center',
                marginLeft: 20,
                marginRight: 20,
            },
        },
    } as FooterStylesObj;
    return typed;
};
export default Footer;
