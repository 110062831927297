import React, { FC, useCallback, useEffect, useState } from 'react';
import type { TextStyle } from 'react-native';
import { isPlatformWeb } from 'renative';
import { SvgUri } from 'react-native-svg';

import { View, Image } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { ThemeIconName } from '@24i/nxg-sdk-photon/src';

import { ProjectLocalIcon } from '../ProjectLocalIcon';
import { FallbackIcon } from '../FallbackIcon';
import { extractIconPath, getIconStyles, isURL } from '../utils';
import { BaseIconProps, IconVariant } from '../types';

type Props = BaseIconProps<string | ThemeIconName> & { variant: IconVariant };

/*
    This icon is the base for the both the Theme and Navigation icons. As per specs, the flow is as follows:

    - check if the icons path is present in the normal theme (ThemeIcon) or in the navigation-specific theme (NavigationIcon).
    - if no path is available, return the FallbackIcon to signal that this icon is not present
    - if the path is not a url, but a icon name (e.g. account.svg), check the project-specific icons via the ProjectLocalIcon component

*/
export const BaseIcon: FC<Props> = ({ iconName, size, iconStyles, variant, isActive }) => {
    const [hasErrored, setHasErrored] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        };
    }, []);

    const { imageStyles, svgStyles } = getIconStyles(iconStyles, size);
    const { theme } = useTheme();
    const isLightTheme = theme?.name?.toLowerCase().includes('light');

    const { path, ...icon } = extractIconPath({ theme, iconName, variant, isActive });

    const handleSvgError = useCallback((error): void => {
        console.error('handleSvgError', iconName, error, path);
        if (isMounted) {
            setHasErrored(true);
        }
    }, []);

    if (!path) {
        if (icon) {
            /* eslint-disable @typescript-eslint/no-var-requires */
            /* eslint-disable global-require */
            // Legacy navigation icons (fallback is provided but we should not be using fonts anymore)
            // @ts-ignore
            const fontIcon = icon.font || icon.iconFont;
            // @ts-ignore
            const fontIconName = icon.name || icon.iconName;
            if (fontIcon && fontIconName) {
                let FontIcon;
                switch (fontIcon) {
                    case 'fontAwesome':
                        if (
                            require('react-native-vector-icons/glyphmaps/FontAwesome.json')[
                                fontIconName
                            ]
                        ) {
                            FontIcon = require('react-native-vector-icons/FontAwesome').default;
                        } else {
                            FontIcon = FallbackIcon;
                        }
                        break;
                    case 'materialIcons':
                        if (
                            require('react-native-vector-icons/glyphmaps/MaterialIcons.json')[
                                fontIconName
                            ]
                        ) {
                            FontIcon =
                                require('react-native-vector-icons/MaterialIcons').default ||
                                FallbackIcon;
                        } else {
                            FontIcon = FallbackIcon;
                        }
                        break;
                    default:
                        FontIcon = FallbackIcon;
                }
                // If no iconStyles color is provided, guess default
                const defaultColor = isLightTheme ? 'black' : 'white';
                const iconStyle = iconStyles as TextStyle;
                const iconColor = iconStyle?.color ? iconStyle.color : defaultColor;
                return (
                    <FontIcon
                        name={fontIconName}
                        size={size}
                        color={iconColor}
                        style={iconStyles}
                    />
                );
            }
            /* eslint-enable @typescript-eslint/no-var-requires */
            /* eslint-enable global-require */
        }
        return <FallbackIcon size={size} iconStyles={iconStyles} />;
    }

    if (!isURL(path)) {
        return <ProjectLocalIcon iconName={path} size={size} iconStyles={iconStyles} />;
    }

    const isSvg = path.indexOf('svg') > -1;

    const svg = isPlatformWeb ? (
        <img src={path} style={imageStyles} onError={handleSvgError} alt={path} />
    ) : (
        <View style={{ width: size, height: size }}>
            {isSvg ? (
                <SvgUri
                    width="100%"
                    height="100%"
                    uri={path}
                    // @ts-expect-error: onError is missing in type declarations for this package version
                    onError={handleSvgError}
                    style={svgStyles}
                />
            ) : (
                <Image
                    source={{ uri: path }}
                    style={{
                        width: size,
                        height: size,
                    }}
                />
            )}
        </View>
    );

    return hasErrored ? <FallbackIcon size={size} iconStyles={iconStyles} /> : svg;
};
