import { isPlatformIos } from 'renative';
import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon/src';
import { MiniControllerStyles } from './types';

const styles = (theme: Theme): MiniControllerStyles => ({
    mainContainer: {
        backgroundColor: theme.color.primaryBlur1.color,
        flexGrow: 1,
        height: 80,
    },
    blurViewContainer: {
        position: isPlatformIos ? 'absolute' : 'relative',
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 9999,
        top: 0,
    },
    metadataContainer: {
        flexDirection: 'row',
        height: '100%',
    },
    titleAndSubtitleContainer: {
        alignSelf: 'center',
        width: '60%',
        marginLeft: 20,
    },
    titleStyles: {
        color: theme.color.textPrimary,
        marginBottom: 3,
        ...getFont(theme, 'h4'),
    },
    subtitleStyles: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h5'),
    },
    playPauseContainer: {
        alignSelf: 'center',
        position: 'absolute',
        right: 0,
        marginRight: 28,
    },
    progressBar: {
        width: '100%',
        height: 0.8,
        bottom: 0,
    },
});

export default styles;
