import React, { ReactElement } from 'react';
import { I18nManager, ViewStyle } from 'react-native';
import { ASSET_TYPE } from '@24i/nxg-sdk-photon';
import { ThemeIcon, View, Text } from '@24i/nxg-sdk-quarks';
import LinearGradient from 'react-native-linear-gradient';
import { isPlatformWeb } from 'renative';
import AssetLabel from '@24i/nxg-sdk-smartott/src/components/AssetLabel';
import { ResizeModeWeb } from '@24i/nxg-sdk-quarks/src/components/Image/types';
import { useTranslation } from 'react-i18next';
import FastImage from 'react-native-fast-image';
import { ViewModelReturnType } from '../types';
import { selectedStyles } from '../styles';
import {
    defaultRenderChannelOverlay,
    defaultRenderImage,
    defaultRenderLiveBadge,
    defaultRenderPackshotActionButton,
    defaultRenderPackshotTitle,
    defaultRenderProgressBar,
} from '../..';
import { RenderPropsOptions } from '../../../types';

const PackshotContent = (props: ViewModelReturnType): ReactElement => {
    const {
        channelLogo = null,
        contentStyles,
        isGrid = false,
        image = '',
        imageStyles = { height: 0, width: 0 },
        isChannel = false,
        isGenre = false,
        isHighlighted = false,
        isLive = false,
        onLayout,
        onPress,
        packshotGradient = null,
        packshotGradientColors = ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0.7)'],
        progress = 0,
        renderChannelOverlay = defaultRenderChannelOverlay,
        renderImage = defaultRenderImage,
        renderLiveBadge = defaultRenderLiveBadge,
        renderPackshotTitle = defaultRenderPackshotTitle,
        renderPackshotActionButton = defaultRenderPackshotActionButton,
        renderProgressBar = defaultRenderProgressBar,
        resizeMode = 'cover',
        actionIcon = null,
        styles,
        subtitle = '',
        textPlacement = '',
        time = '',
        title,
        index = 0,
        sliderIndex = 0,
        assetLabel,
        fallbackImage,
        type,
        zoomPackshotOnHover = true,
        poster,
        testId = 'packshot',
        isObscured,
        obscuredImage,
        orientation = 'landscape',
        actionButtonStyles,
        liveBadgeStyles,
        progressBarStyles,
        theme,
        channelOverlayStyles,
        packshotTitleStyles,
        dynamicProgress,
        isSelected = false,
        showMetadata = true,
        isGeoBlocked,
        isSeeAll,
        packshotWidth = 0,
        packshotHeight = 0,
        hideTitle = false,
        textColor,
    } = props;

    const { colors } = theme.color.gradient2;
    const { t } = useTranslation('sott');

    const renderPropsOptions: RenderPropsOptions = {
        index,
        scopeIndex: sliderIndex,
        colors,
    };

    const isLiveEvent = type === ASSET_TYPE.LIVE_EVENT;
    const isEpisode = type === ASSET_TYPE.EPISODE;
    const isCustom = type === ASSET_TYPE.CUSTOM;
    const showPackshotTitle = hideTitle
        ? false
        : (isCustom && textPlacement === 'over') || (showMetadata && (!!title || isObscured));

    const hoverLandscapeStyles = ((): ViewStyle => {
        if ('packshotHoverLandscape' in styles && !isHighlighted) {
            return styles.packshotHoverLandscape;
        }
        if ('packshotHoverLandscapeHighlighted' in styles && isHighlighted) {
            return styles.packshotHoverLandscapeHighlighted;
        }
        return {};
    })();

    const hoverPortraitStyles = ((): ViewStyle => {
        if ('packshotHoverPortrait' in styles && !isHighlighted) {
            return styles.packshotHoverPortrait;
        }
        if ('packshotHoverPortraitHighlighted' in styles && isHighlighted) {
            return styles.packshotHoverPortraitHighlighted;
        }
        return {};
    })();

    const seeMoreIcon = (
        <ThemeIcon
            iconName="onPrimary-seeAll"
            size={isPlatformWeb ? 21 : 15}
            iconStyles={{ opacity: 0.6 }}
        />
    );

    const renderSeeAll = () => (
        <LinearGradient colors={colors} style={styles.seeAllGradient}>
            <View style={{ ...styles.labelContainer }}>
                <View>
                    <Text
                        style={{ ...styles.label, ...(textColor ? { color: textColor } : {}) }}
                        allowFontScaling={false}
                    >
                        {title}
                    </Text>
                </View>
                <View style={styles.arrows}>
                    {/* Temporary hack, since at the moment there is no onPrimary-seeAll_rtl icon available */}
                    {I18nManager.isRTL ? (
                        <View style={{ transform: [{ scaleX: -1 }] }}>{seeMoreIcon}</View>
                    ) : (
                        seeMoreIcon
                    )}
                </View>
            </View>
        </LinearGradient>
    );

    const renderMetadata = () =>
        renderPackshotTitle(
            {
                styles: packshotTitleStyles,
                isGenre,
                isGrid,
                isChannel,
                textPlacement,
                subtitle: (isObscured ? t('asset.blocked.metadata') : subtitle) || '',
                time,
                title: isObscured ? t('asset.blocked.title') : title,
                infoTestId: isEpisode ? `${type}_${index + 1}` : testId,
                isEpisode,
                isCustom,
                isHighlighted,
                orientation,
                imageStyles: { width: packshotWidth, height: packshotHeight },
                textColor,
            },
            renderPropsOptions
        );

    return (
        <>
            <View
                style={[
                    contentStyles,
                    isSelected ? selectedStyles : null,
                    {
                        ...(isPlatformWeb &&
                            zoomPackshotOnHover &&
                            (poster ? hoverPortraitStyles : hoverLandscapeStyles)),
                    },
                ]}
                onLayout={onLayout}
                renderToHardwareTextureAndroid
                shouldRasterizeIOS
            >
                <View
                    colors={colors || packshotGradientColors}
                    style={[imageStyles as ViewStyle, { backgroundColor: theme.color.darker1 }]}
                >
                    {isSeeAll && renderSeeAll()}
                    {!isGenre &&
                        !isSeeAll &&
                        renderImage(
                            {
                                // LinearGradient is not compatible with type Element
                                // @ts-expect-error
                                children:
                                    isObscured && !isChannel ? (
                                        <View
                                            style={[
                                                'obscuredImageContainer' in styles &&
                                                    styles.obscuredImageContainer,
                                            ]}
                                        >
                                            <ThemeIcon
                                                iconName="onPrimary-lock"
                                                size={isPlatformWeb ? 48 : 36}
                                                iconStyles={{ opacity: 0.3 }}
                                            />
                                        </View>
                                    ) : (
                                        packshotGradient
                                    ),
                                style: imageStyles,
                                source: isObscured ? obscuredImage : { uri: image },
                                draggable: false,
                                noFallback: isObscured,
                                resizeMode: resizeMode as FastImage.ResizeMode & ResizeModeWeb,
                                fallbackImage,
                                hasGradientAlready: !!colors || !!packshotGradientColors,
                                theme,
                            },
                            renderPropsOptions
                        )}
                </View>
                <View style={styles.topContentContainer}>
                    {!!assetLabel && !isChannel && (
                        <View style={styles.assetLabelContainer}>
                            <AssetLabel text={assetLabel} />
                        </View>
                    )}
                    {isGeoBlocked && (
                        <View style={styles.geoblockIconContainer}>
                            <ThemeIcon
                                iconName="onLabel-geoblocked"
                                size={isPlatformWeb ? 16 : 14}
                            />
                        </View>
                    )}
                </View>
                {!isLiveEvent &&
                    !!channelLogo &&
                    renderChannelOverlay(
                        {
                            hasBigChannelLogo: isChannel,
                            channelLogo,
                            orientation,
                            styles: channelOverlayStyles,
                            theme,
                            progressBarDisplayed: progress > 0,
                        },
                        renderPropsOptions
                    )}
                {isLive &&
                    renderLiveBadge(
                        {
                            styles: liveBadgeStyles,
                            hasProgressBar: !isLiveEvent && progress > 0,
                        },
                        renderPropsOptions
                    )}
                {!isLiveEvent &&
                    progress > 0 &&
                    renderProgressBar(
                        {
                            progress: isLive || isChannel ? dynamicProgress : progress,
                            theme,
                            styles: progressBarStyles,
                            isLive,
                        },
                        renderPropsOptions
                    )}
                {!!actionIcon &&
                    !isObscured &&
                    !isChannel &&
                    !isSeeAll &&
                    renderPackshotActionButton(
                        {
                            onPress,
                            styles: actionButtonStyles,
                            isHighlighted,
                            actionIcon,
                            overrideDisplayIcon: !isCustom,
                        },
                        renderPropsOptions
                    )}
                {isObscured && !!channelLogo && isChannel && (
                    <View
                        style={[
                            'obscuredChannelContainer' in styles && styles.obscuredChannelContainer,
                        ]}
                    >
                        <ThemeIcon
                            iconName="onPrimary-lock"
                            size={isPlatformWeb ? 24 : 22}
                            iconStyles={{ opacity: 0.3 }}
                        />
                    </View>
                )}
                {showPackshotTitle && isCustom && textPlacement === 'over' && renderMetadata()}
            </View>
            {showPackshotTitle && !isSeeAll && textPlacement !== 'over' && renderMetadata()}
        </>
    );
};

export default PackshotContent;
