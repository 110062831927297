import { WebLink } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { PRODUCT_SCREENS } from '@24i/nxg-sdk-photon';
import { SignoutModalProps } from '../../components/SignOut';
import { StackParamList } from '../../navigation/types';

export enum SubMenuStackScreens {
    // A special entry in the otherwise dynamic stack
    ScreensList = 'ScreensList',

    // The other screens are added dynamically and cannot be enumerated
}

export type Screen = ScreenWeb | ScreenNative;

export interface ScreenWeb {
    title: string;
    webLink: WebLink;
    icon?: string;
    onPress?: () => void;
    screenTypeToUse?: PRODUCT_SCREENS;
}

export interface ScreenNative {
    title: string;
    nativeName: string;
    onPress?: () => void;
    icon?: string;
    reference?: string | null;
    screenTypeToUse?: PRODUCT_SCREENS;
}

export interface SubMenuScreensListProps extends SignoutModalProps {
    containingMenuTitle: string;
    subMenuScreens: Screen[];
}

export type SubMenuStackParams = {
    [SubMenuStackScreens.ScreensList]: SubMenuScreensListProps;
} & StackParamList;
