/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-empty-interface */

/**
 * This ts definition is generated from example code found at
 * https://docs-backstage.vercel.app/FAPI-spec.html and from
 * the api response returned at the moment of writing this document.
 *
 * There might be wrong or missing properties or types. Feel free to update it.
 */

import {
    createGuard,
    extendGuard,
    nullable,
    withEmptyArray,
    createEnum,
} from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { BACKSTAGE_SERVICE_PROVIDERS } from '@24i/adobe-primetime/src/shared/accessEnablerConstants';
import { ImageGuard } from './image';
import { MonitorConfig } from '../clients/MonitoringDataClient';
import { AnalyticsConfig } from '../clients/AnalyticsClient';
import { RATING_SYSTEM_GUARD, ASSET_TYPE_GUARD } from '../enums';
import { PushNotificationsConfig } from '../clients/PushNotificationsClient';

export enum Apis {
    BACKSTAGE = 'backstage',
}

export enum PinProtectionScopes {
    profileManagement = 'profileManagement',
    adultControl = 'adultControl',
    ageControl = 'ageControl',
}

const FeatureGuard = createGuard('Feature', undefined, {
    enabled: t.boolean,
});

const PinProtectionFeatureGuard = createGuard('PinProtectionFeature', undefined, {
    enabled: t.boolean,
    scope: createGuard('ScopesGuard', t.record(t.keyof(PinProtectionScopes), t.number)),
});

const PaymentPinProtectionFeatureGuard = createGuard(
    'PaymentPinProtectionFeatureGuard',
    undefined,
    {
        defaultValue: t.boolean,
        showToggle: t.boolean,
    }
);

const RemindersFeatureGuard = createGuard('RemindersFeature', undefined, {
    enabled: t.boolean,
    showPushNotificationThumbnail: t.boolean,
});

const RecommendedRowFeatureGuard = createGuard('RecommendedRowFeatureGuard', undefined, {
    pageSize: t.number,
});

const RatingFeatureGuard = createGuard('RatingFeature', undefined, {
    enabled: t.boolean,
    system: RATING_SYSTEM_GUARD,
    rateableAssets: t.array(ASSET_TYPE_GUARD),
});

const RowAsset = createGuard('RowAsset', undefined, {
    title: nullable(t.string),
    subtitle_left: nullable(t.string),
    subtitle_right: nullable(t.string),
});

const LandingScreenGuard = createGuard('LandingScreen', undefined, {
    enabled: t.boolean,
    backgroundColor: nullable(t.string),
    externalUrl: nullable(t.string),
    backgroundImage: t.type({
        landscape: t.string,
        portrait: t.string,
    }),
});

const WelcomeScreensGuard = createGuard('WelcomeScreens', undefined, {
    enabled: t.boolean,
    screens: t.array(
        createGuard('Screens', undefined, {
            startsAt: nullable(t.number),
            welcomeScreenId: nullable(t.string),
            endsAt: nullable(t.number),
        })
    ),
});

export type WelcomeScreens = t.TypeOf<typeof WelcomeScreensGuard>;

const ProfileLimitGuard = createGuard('ProfileLimit', undefined, {
    maxCount: nullable(t.number),
});

export type ProfileLimit = t.TypeOf<typeof ProfileLimitGuard>;

export const AppSettingsApplicationGuard = createGuard('Application', undefined, {
    name: nullable(t.string),
    enabled: t.boolean,
});

export const AppSettingsApiGuard = createGuard(
    'Api',
    t.union([
        t.UnknownRecord,
        t.record(
            t.keyof(Apis),
            t.partial({
                externalServices: t.UnknownArray,
            })
        ),
        t.partial({
            namespace: t.string,
            externalMetadataIntegration: t.boolean,
        }),
    ])
);

export const MediaPipelineAnalysisGuard = createGuard('MediaPipelineAnalysis', undefined, {
    config: createGuard('Config', undefined, {
        location: nullable(t.string),
        accountId: nullable(t.string),
        importFaces: t.boolean,
        importLabels: t.boolean,
        importTopics: t.boolean,
        importEmotions: t.boolean,
        importKeywords: t.boolean,
        importSentiments: t.boolean,
        importNamedPeople: t.boolean,
        minimumConfidence: nullable(t.string),
    }),
    provider: nullable(t.string),
});

const GuestModeFeatureGuard = createGuard('GuestModeFeatureFeature', undefined, {
    enabled: t.boolean,
    allowWatchContent: t.boolean,
});

const DeveloperToolsFeatureGuard = createGuard('DeveloperToolsFeatureFeature', undefined, {
    showScrollInfo: t.boolean,
});

const SignInFeatureGuard = createGuard('SignInFeatureFeature', undefined, {
    enabled: t.boolean,
});

const LocaleFeatureGuard = createGuard('LocaleFeature', undefined, {
    enabled: t.boolean,
    defaultLanguage: t.string,
    supportedLanguages: t.array(t.string),
});

const AdobePrimetimeServiceProviders = createEnum<BACKSTAGE_SERVICE_PROVIDERS>(
    BACKSTAGE_SERVICE_PROVIDERS,
    'BACKSTAGE_SERVICE_PROVIDER'
);

const AdobePrimetimeFeatureGuard = createGuard('AdobePrimetime', undefined, {
    tierOneMvpdIds: t.array(t.string),
    baseUrl: AdobePrimetimeServiceProviders,
    enabled: t.boolean,
    protectionLevel: t.string,
    providerJsonUrl: t.string,
    requestorId: t.string,
    secondScreenUrl: nullable(t.string),
});

const AdyenSettingsGuard = createGuard('AdyenSettings', undefined, {
    analytics: t.partial({ enabled: t.boolean }),
    translations: nullable(t.record(t.string, t.record(t.string, t.string))),
    paymentMethodsConfiguration: t.partial({
        card: t.partial({
            name: nullable(t.string),
            billingAddressRequired: nullable(t.boolean),
            billingAddressMode: nullable(
                t.keyof({
                    full: null,
                    partial: null,
                })
            ),
            brands: nullable(t.array(t.string)),
            brandsConfiguration: nullable(t.record(t.string, t.record(t.string, t.string))),
            showBrandIcon: nullable(t.boolean),
            showBrandsUnderCardNumber: nullable(t.boolean),
            positionHolderNameOnTop: nullable(t.boolean),
            billingAddressAllowedCountries: nullable(t.array(t.string)),
            minimumExpiryDate: nullable(t.string),
            styles: nullable(t.record(t.string, t.record(t.string, t.string))),
            data: nullable(t.record(t.string, t.string)),
        }),
        googlePay: t.partial({
            buttonColor: nullable(
                t.keyof({
                    black: null,
                    white: null,
                })
            ), // default: A Google-selected default value. Currently black but it may change over time.
            // black: A black button suitable for use on white or light backgrounds.
            // white: A white button suitable for use on colorful backgrounds.
            buttonType: nullable(
                t.keyof({
                    buy: null,
                    book: null,
                    checkout: null,
                    donate: null,
                    order: null,
                    pay: null,
                    plain: null,
                    subscribe: null,
                })
            ),
            // The type of button you want displayed on your payments form.
            // For a list of supported properties, refer to https://developers.google.com/pay/api/web/reference/request-objects#ButtonOptions
            // Default: 'buy'
            buttonSizeMode: nullable(
                t.keyof({
                    fill: null, // defaul
                    static: null,
                })
            ), // Specifies whether the button changes to fill the size of its container, or has a static width and height.
            // static: Button has a static width and height
            // fill: Button size changes to fill the size of its container.
        }),
    }),
});

const CleengConfigGuard = createGuard('CleenConfig', undefined, {
    publisherId: t.number,
    termsUrl: nullable(t.string),
    myAccountUrl: nullable(t.string),
    environment: t.keyof({
        sandbox: null,
        production: null,
    }),
    adyenSettings: AdyenSettingsGuard,
});

export type CleengSettings = t.TypeOf<typeof CleengConfigGuard>;

const SubscriptionsProviderGuard = createGuard(
    'SubscriptionsProvider',
    t.union([
        t.type({
            name: t.literal('cleeng'),
            settings: CleengConfigGuard,
        }),
        t.type({
            name: t.literal('placeholder_for_future_providers'),
            settings: t.type({
                dummySetting: t.number,
            }),
        }),
    ])
);

export type SubscriptionsProvider = t.TypeOf<typeof SubscriptionsProviderGuard>;

const OpenExternalPageOptions = nullable(
    t.keyof({
        'in-app': null,
        'out-of-app': null,
    })
);

export type OpenExternalPage = t.TypeOf<typeof OpenExternalPageOptions>;

const SubscriptionOptionsGuard = createGuard(
    'SubscriptionOptions',
    t.type({
        exclusivity: t.union([
            t.literal('none'),
            t.literal('id'),
            t.literal('group'),
            t.literal('all'),
        ]),
    })
);
export type SubscriptionOptions = t.TypeOf<typeof SubscriptionOptionsGuard>;

const SearchResultGuard = createGuard(
    'SearchResult',
    t.type({
        enableGrid: t.boolean,
    })
);

const AdInsertionFeatureGuard = createGuard('AdInsertion', undefined, {
    enabled: t.boolean,
    type: nullable(t.string),
    SSAIProvider: nullable(t.string),
});

export const AppSettingsFeaturesGuard = createGuard('Features', undefined, {
    footer: createGuard('Footer', undefined, {
        icons: t.array(
            createGuard('FooterIcon', undefined, {
                font: t.string,
                name: t.string,
                href: t.string,
            })
        ),
        links: t.array(
            createGuard('FooterLink', undefined, {
                title: t.string,
                href: t.string,
            })
        ),
    }),
    pip: FeatureGuard,
    basicEpg: createGuard('BasicEpg', undefined, {
        daysToRequest: t.number,
        dayPicker: createGuard('DayPicker', undefined, {
            availableDays: createGuard('AvailableDays', undefined, {
                past: nullable(t.number),
                future: nullable(t.number),
            }),
            primetime: nullable(t.string),
        }),
    }),
    menus: createGuard('Menus', undefined, {
        main: nullable(t.string),
        footer: nullable(t.string),
        user: nullable(t.string),
    }),
    detail: createGuard('Details', undefined, {
        others: createGuard('Other', undefined, {
            metadata_row: t.UnknownArray,
        }),
        series: createGuard('Series', undefined, {
            metadata_row: t.UnknownArray,
        }),
    }),
    detailsTab: createGuard('detailsTab', undefined, {
        enabled: t.boolean,
    }),
    moreLikeThis: createGuard('moreLikeThis', undefined, {
        enabled: t.boolean,
    }),
    deepLinking: createGuard('DeepLinking', undefined, {
        FQDN: t.array(t.string),
        storeUrls: createGuard('storeUrls', undefined, {
            android: t.string,
            ios: t.string,
        }),
        enabled: t.boolean,
    }),
    trailersExtras: createGuard('trailersExtrasTab', undefined, {
        enabled: t.boolean,
    }),
    seeAll: createGuard('SeeAll', undefined, {
        enabled: t.boolean,
        assetLimit: nullable(t.string),
        supportedRows: nullable(t.string),
    }),
    history: FeatureGuard,
    accounts: createGuard('Accounts', undefined, {
        management: t.boolean,
        login_option: t.array(nullable(t.string)),
        select_profiles: t.boolean,
        login_at_startup: nullable(t.string),
        profile_switch_code: nullable(t.string),
        login_at_content_play: t.boolean,
        prefer_screen: t.boolean,
    }),
    full_epg: FeatureGuard,
    geoblock: createGuard('Geoblock', undefined, {
        enabled: t.boolean,
        includeBlocked: t.boolean,
        global: createGuard('Global', undefined, {
            deny: t.UnknownArray,
            allow: t.UnknownArray,
            blocked: t.boolean,
        }),
    }),
    analytics: t.array(
        createGuard('Analytics', undefined, {
            id: nullable(t.string),
            name: nullable(t.string),
            enabled: t.boolean,
        })
    ),
    favourites: FeatureGuard,
    onboardingIntro: FeatureGuard,
    additionalInfo: FeatureGuard,
    monitoring: t.array(
        createGuard('Monitoring', undefined, {
            id: nullable(t.string),
            name: nullable(t.string),
            plan: nullable(t.string),
            enabled: t.boolean,
        })
    ),
    monitoringPlayback: createGuard('monitoringPlayback', undefined, {
        heartbeatIntervalMs: t.number,
    }),
    trick_play: FeatureGuard,
    data_privacy: createGuard('DataPrivacy', undefined, {
        gdpr: t.boolean,
        ccpa: t.boolean,
    }),
    geolocation: createGuard('Geolocation', undefined, {
        country: nullable(t.string),
    }),
    identityProvider: createGuard('IdentityProvider', undefined, {
        enabled: nullable(t.boolean),
        provider: nullable(t.string),
        registrationURL: nullable(t.string),
    }),
    accountRegistration: createGuard('AccountRegistration', undefined, {
        enabled: nullable(t.boolean),
    }),
    advertisement: createGuard('Advertisement', undefined, {
        enabled: t.boolean,
        defaultAdvertisementStrategyId: nullable(t.string),
    }),
    live_playlist: createGuard('LivePlaylist', undefined, {
        primetime_to: nullable(t.string),
        primetime_from: nullable(t.string),
    }),
    time_shifting: FeatureGuard,
    binge_watching: FeatureGuard,
    direct_to_live: FeatureGuard,
    searchCharRules: nullable(t.record(t.string, t.number)),
    welcomeScreens: withEmptyArray(WelcomeScreensGuard),
    casting_options: createGuard('CastingOptions', undefined, {
        airplay: nullable(t.string),
        chromecast: nullable(t.string),
        screen_mirroring: nullable(t.string),
    }),
    imageResizeTool: createGuard('ImageResizeTool', undefined, {
        enabled: t.boolean,
        endpoint: nullable(t.string),
    }),
    recommendations: createGuard('Recommendations', undefined, {
        vion: createGuard('Vion', undefined, {
            env: nullable(t.string),
            useId: nullable(t.string),
        }),
        provider: nullable(t.string),
    }),
    row_asset_types: createGuard('RowAssetTypes', undefined, {
        clip: RowAsset,
        movie: RowAsset,
        series: RowAsset,
        channel: RowAsset,
        episode: RowAsset,
        program: RowAsset,
        live_event: RowAsset,
    }),
    episode_selector: nullable(t.string),
    ageClassification: createGuard('AgeClassification', undefined, {
        default: nullable(t.union([t.string, t.number])),
        mapping: createGuard('Mapping', undefined, {
            NL: nullable(t.union([t.string, t.number])),
        }),
    }),
    continue_watching: FeatureGuard,
    detail_view_config: createGuard('DetailViewConfig', undefined, {
        type: nullable(t.string),
        background_image: t.boolean,
    }),
    episode_title_style: createGuard('EpisodeTitleStyle', undefined, {
        type: nullable(t.string),
    }),
    multiAudioInManifest: FeatureGuard,
    row_item_show_rating: FeatureGuard,
    profileLimit: withEmptyArray(ProfileLimitGuard),
    mediaPipelineAnalysis: withEmptyArray(MediaPipelineAnalysisGuard),
    content_overlay_player: FeatureGuard,
    allow_video_player_rotation: FeatureGuard,
    profiles: FeatureGuard,
    pinProtection: PinProtectionFeatureGuard,
    paymentsPinProtection: PaymentPinProtectionFeatureGuard,
    recordings: FeatureGuard,
    ratings: RatingFeatureGuard,
    maxNumberOfProfiles: nullable(t.number),
    landingScreen: LandingScreenGuard, // not yet supported by backstage
    contentRatings: createGuard('ContentRatings', {
        // not supported by backstage, added from runtime config
        thresholds: t.array(t.number),
    }),
    deviceManagement: FeatureGuard,
    showWorkInProgressLabels: FeatureGuard,
    reminders: RemindersFeatureGuard,
    storybook: FeatureGuard,
    inAppPurchases: extendGuard(
        'InAppPurchases',
        FeatureGuard,
        {},
        {
            disabledPlatforms: t.array(
                t.union([
                    t.literal('android'),
                    t.literal('ios'),
                    t.literal('web'),
                    t.literal('androidtv'),
                    t.literal('firetv'),
                ])
            ),
            debugMode: createGuard('InAppPurchasesDebugMode', undefined, {
                enabled: nullable(t.boolean),
                accountsId: nullable(t.array(t.string)),
            }),
            // TODO: not approved in PRD
            detailButtonWhenNoPurchases: nullable(
                t.union([t.literal('Purchase'), t.literal('None'), t.literal('Play')])
            ),
        }
    ),
    onboardingTermsAndConditions: FeatureGuard,
    onboardingSubscriptions: FeatureGuard,
    versionCheck: FeatureGuard,
    recommendedRow: RecommendedRowFeatureGuard,
    guestMode: GuestModeFeatureGuard,
    developerTools: DeveloperToolsFeatureGuard,
    adobePrimetime: AdobePrimetimeFeatureGuard,
    subscriptionsProvider: SubscriptionsProviderGuard,
    subscriptionOptions: SubscriptionOptionsGuard,
    signIn: SignInFeatureGuard,
    openExternalPage: OpenExternalPageOptions,
    themes: createGuard('Themes', undefined, {
        defaultThemeId: t.string,
    }),
    locale: LocaleFeatureGuard,
    pal_sdk: nullable(
        createGuard(
            'pal_sdk',
            { enabled: t.boolean },
            {
                descriptionUrl: t.string,
            }
        )
    ),
    searchResult: nullable(SearchResultGuard),
    oneTrust: createGuard('oneTrust', undefined, {
        enabled: t.boolean,
        scriptUrl: t.string,
        domainScript: t.string,
    }),
    adInsertion: AdInsertionFeatureGuard,
});

export type AppSettingsFeatures = t.TypeOf<typeof AppSettingsFeaturesGuard>;

export const AppSettingsThemingGuard = createGuard('Theming', undefined, {
    colors: withEmptyArray(
        createGuard('Colors', undefined, {
            accent: nullable(t.string),
            primary: nullable(t.string),
            tertiary: nullable(t.string),
            secondary: nullable(t.string),
            background: nullable(t.string),
            textPrimary: nullable(t.string),
            textSecondary: nullable(t.string),
        })
    ),
    background: t.array(ImageGuard),
    logo: t.array(ImageGuard),
});

export const AppSettingsGuard = createGuard('AppSettings', {
    application: AppSettingsApplicationGuard,
    api: AppSettingsApiGuard,
    features: AppSettingsFeaturesGuard,
    theming: AppSettingsThemingGuard,
});

export type LandingScreenConfig = t.TypeOf<typeof LandingScreenGuard>;

// NOTE: AppSettingsApiGuard.api is union of several types
// but when using the AppSettings.api type we want it as intersection
// until there is complete openapi spec for app settings
export type AppSettingsGuardType = t.TypeOf<typeof AppSettingsGuard>;
export type AppSettings = t.TypeOf<typeof AppSettingsGuard> & {
    api: Record<string, any> & {
        namespace?: string;
        externalMetadataIntegration?: boolean;
    } & { [key in Apis]: { externalServices: any[] } };
    features: AppSettingsGuardType['features'] & {
        welcomeScreens: WelcomeScreens;
        profileLimit?: ProfileLimit;
        monitoring?: MonitorConfig[];
        analytics?: AnalyticsConfig[];
        loadEpgFakeData?: boolean;
        pushNotifications?: PushNotificationsConfig;
        accountRegistration?: {
            enabled: boolean;
        };
        serviceSwitcher?: {
            backstageLogin?: {
                enabled: boolean;
            };
        };
        socialSharing: {
            baseUrl: string;
        };
        storePassword: string;
    };
    custom: Record<string, any>;
};

export type AssetTypeConfig = {
    action: {
        player: boolean;
        detail: boolean;
    };
};

export interface RuntimeConfigType {
    backstageServiceGuards: {
        enabled: boolean;
    };
    apiService: {
        id: string;
        title: string;
        appId: {
            ios: string;
            androidtv: string;
            tvos: string;
            android: string;
            default: string;
        };
    };
    defaultApiServices: Array<RuntimeConfigType['apiService']>;
    app: {
        rows: {
            rowItemLimit: number;
            heroItemLimit: number;
            enableDynamicSliderImageType: boolean;
        };
        defaultServiceThemeIndex: number;
        grid: {
            pageSize: number;
        };
    };
    menu: {
        mobile: {
            isMoreScreenEnabled: boolean;
            moreScreenName: string;
            maxVisibleNavbarItems: number;
        };
        desktop: {
            isMoreScreenEnabled: never;
            maxVisibleNavbarItems: number;
        };
    };
    screens: {
        details: {
            enableShareButton: boolean;
        };
        player: {
            enableBuffering: boolean;
        };
    };
    reactQuery: {
        staleTime: {
            short: number;
            long: number;
        };
        devTools: {
            enabled: boolean;
        };
    };
    features?: AppSettings['features'];
    appVersion: string;
    timestamp: string;
    logSentryEvents: boolean;
    monitoringEnvironment: string;
    defaultLazyLoading: {
        mobile: number;
        desktop: number;
    };
    assetTypes: {
        clip: AssetTypeConfig;
        movie: AssetTypeConfig;
        series: AssetTypeConfig;
        broadcast: AssetTypeConfig;
        catchup: AssetTypeConfig;
        live: AssetTypeConfig;
        future: AssetTypeConfig;
        episode: AssetTypeConfig;
        live_event: AssetTypeConfig;
        announced_movie: AssetTypeConfig;
        announced_series: AssetTypeConfig;
        announced_episode: AssetTypeConfig;
    };
    devMode: boolean;
    customScripts: string[];
}
