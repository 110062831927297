import type { ImageLoader } from 'next/image';
import { Source } from './types';

enum ImageResizerParams {
    WIDTH = 'w',
    HEIGHT = 'h',
    QUALITY = 'q',
}

enum CloudfrontImageParams {
    WIDTH = 'width',
    HEIGHT = 'height',
    QUALITY = 'quality',
}

export const getSource = (source?: Source) => {
    if (typeof source === 'string') {
        return source;
    }
    if (source && typeof source.uri === 'string') {
        return source.uri;
    }

    return undefined;
};

export const splitUrlAtQueryParams = (url: string): string[] => {
    const splitUrl = url.match(/^([^&]*?)\?(.*)$/)?.splice(1);
    return splitUrl ?? [url];
};

export const consumeQueryParam = (queryParams: URLSearchParams, name: string): string | null => {
    const param = queryParams.get(name);
    queryParams.delete(name);

    return param;
};

export enum ParsedImageProvider {
    IMAGERESIZER = 'imageresizer',
    CLOUDFRONT = 'cloudfront',
}

export interface ParsedImageUrl {
    src: string | undefined;
    provider?: ParsedImageProvider;
    width?: string | number | null;
    height?: string | number | null;
    quality?: string | number | null;
    imageLoader?: ImageLoader;
}

export const imageLoaderImageResizer: ImageLoader = ({ src, width, quality }) =>
    `${src}&w=${width}&q=${quality || 75}`;

export const imageLoaderCloudfront: ImageLoader = ({ src, width, quality }) => {
    if (!src) return src;
    const uriURL = new URL(src);
    // Docs: https://aws.amazon.com/developer/application-security-performance/articles/image-optimization
    uriURL.searchParams.set('format', 'auto');
    uriURL.searchParams.set('width', width.toString());
    uriURL.searchParams.set('quality', (quality || 75).toString());
    return uriURL.href;
};

export const imageLoaderDefault: ImageLoader = ({ src }) => src;

export const parseImgUrl = (url?: Source): ParsedImageUrl => {
    const src = getSource(url);
    if (!src)
        return {
            src: undefined,
            width: null,
            height: null,
            quality: null,
            imageLoader: imageLoaderDefault,
        };

    const [baseUrl, params] = splitUrlAtQueryParams(src);
    const urlSearchParams = new URLSearchParams(params);
    let width: string | number | null;
    let height: string | number | null;
    let quality: string | number | null;
    let provider: ParsedImageProvider | undefined;
    let imageLoader: ImageLoader | undefined;

    if (
        urlSearchParams.has(ImageResizerParams.WIDTH) ||
        urlSearchParams.has(ImageResizerParams.HEIGHT) ||
        urlSearchParams.has(ImageResizerParams.QUALITY)
    ) {
        provider = ParsedImageProvider.IMAGERESIZER;
        imageLoader = imageLoaderImageResizer;
        width = consumeQueryParam(urlSearchParams, ImageResizerParams.WIDTH);
        height = consumeQueryParam(urlSearchParams, ImageResizerParams.HEIGHT);
        quality = consumeQueryParam(urlSearchParams, ImageResizerParams.QUALITY);
    } else if (
        urlSearchParams.has(CloudfrontImageParams.WIDTH) ||
        urlSearchParams.has(CloudfrontImageParams.HEIGHT) ||
        urlSearchParams.has(CloudfrontImageParams.QUALITY)
    ) {
        provider = ParsedImageProvider.CLOUDFRONT;
        imageLoader = imageLoaderCloudfront;
        width = consumeQueryParam(urlSearchParams, CloudfrontImageParams.WIDTH);
        height = consumeQueryParam(urlSearchParams, CloudfrontImageParams.HEIGHT);
        quality = consumeQueryParam(urlSearchParams, CloudfrontImageParams.QUALITY);
    } else {
        width = null;
        height = null;
        quality = null;
        imageLoader = undefined;
    }

    const source = `${baseUrl}?${urlSearchParams.toString()}`;
    return {
        src: source,
        provider,
        imageLoader,
        width,
        height,
        quality,
    };
};

export const isSvgUrl = (url: Source) => {
    const src = getSource(url);
    if (!src) return false;
    return src.endsWith('.svg');
};
