import { BaseApiParams } from '@24i/nxg-core-utils/src/api';
import { AppSettings, AppSettingsDataClient } from '@24i/nxg-sdk-photon';
import { WelcomeScreen } from '@24i/nxg-sdk-photon/src/models/welcomeScreen';
import { TextPageContent } from '@24i/nxg-sdk-photon/src/models/textPageContent';
import autoBind from 'auto-bind';
import {
    ASYNC_STORAGE_KEY_ONBOARDING_PROFILE_SEEN,
    ASYNC_STORAGE_ACCEPTED_TERMS_AND_CONDITIONS,
    ASYNC_STORAGE_ACCEPTED_TERMS_UPDATED_AT,
    ASYNC_STORAGE_KEY_ONBOARDING_SUBSCRIPTION,
    ASYNC_STORAGE_KEY_PIN_CREATED,
    ASYNC_STORAGE_STREAM_ONLY_ON_WIFI,
    ASYNC_STORAGE_KEY_PIN_FOR_PAYMENTS,
} from '@24i/nxg-core-utils/src/constants';
import deepmerge from 'deepmerge';
import { RuntimeConfig } from '@24i/nxg-core-utils/src/globalSingletons';
import { BackstageApiBase } from '../../base';
import { replaceEmptyArraysWithEmptyObjects } from '../../utils';
import {
    AppSettingsResponseGuard,
    WelcomeScreenResponseGuard,
    TextPagesContentResponseGuard,
} from './guards';
import { getStorageItem, setStorageItem, removeStorageItem } from './utils';

export class BackstageAppSettingsDataClient
    extends BackstageApiBase
    implements AppSettingsDataClient
{
    private getStorageItem: (key: string) => Promise<string | undefined>;

    private setStorageItem: (key: string, value: string) => Promise<void>;

    private removeStorageItem: (key: string) => Promise<void>;

    constructor(params: BaseApiParams) {
        super(params);
        autoBind(this);
        this.getStorageItem = getStorageItem;
        this.setStorageItem = setStorageItem;
        this.removeStorageItem = removeStorageItem;
    }

    async fetchServiceConfig(): Promise<AppSettings> {
        const appSettings = await this.request({
            path: '/configurations/application',
            method: 'GET',
            guard: AppSettingsResponseGuard,
        }).then(
            replaceEmptyArraysWithEmptyObjects<AppSettings>([
                'application',
                'api',
                'theming',
                'features',
                'welcomeScreens',
                'profileLimit',
            ])
        );

        if (!appSettings) {
            return appSettings;
        }

        let result: AppSettings = {
            ...appSettings,
            ...(appSettings.features && { features: appSettings.features }),
        };

        if (result.custom) {
            result = deepmerge<AppSettings>(result.custom, result);
            // Backstage bug not sending registrationURL for cleeng_v2, overridable by customJSON
            // null overrides value from customJSON, so we need special handling
            if (result.features?.identityProvider?.registrationURL === null) {
                const customJSONIdentityProvider = result.custom?.features?.identityProvider || {};
                const hackedUrlFromCustomJSON = customJSONIdentityProvider.registrationURL;
                result.features.identityProvider.registrationURL = hackedUrlFromCustomJSON;
            }
        }
        RuntimeConfig.set(deepmerge(RuntimeConfig.get(), result, { arrayMerge: (d, s) => s }));
        return result;
    }

    async fetchWelcomeScreen(welcomeScreensID: string): Promise<WelcomeScreen> {
        return this.request({
            path: `/welcome-screens/${welcomeScreensID}`,
            method: 'GET',
            guard: WelcomeScreenResponseGuard,
        });
    }

    /*
        Overkill but while testing with a new user registered on the app, storage would
        "imply" that the user had already seen that screen which would be untrue.
        As storage is temporary, storing the userId seemed like a good emulation
        to the behavior expected from backstage.
    */
    private async getItemFromStorage<T>(
        userId: number | string | undefined | null,
        key: string
    ): Promise<T> {
        const storageItems = (await this.getStorageItem(key)) || '{}';
        const value = JSON.parse(storageItems);
        return userId && value?.[userId];
    }

    private async setItemInStorage(
        userId: number | string,
        key: string,
        value: boolean | number = true
    ): Promise<void> {
        const storageItems = (await this.getStorageItem(key)) || '{}';
        const item = JSON.parse(storageItems) || {};
        item[userId] = value;
        this.setStorageItem(key, JSON.stringify(item));
    }

    private async removeItemInStorage(userId: string, key: string): Promise<void> {
        this.removeStorageItem(key);
    }

    async setStreamOverWifiOnly(userId: string, value: boolean): Promise<void> {
        if (value) {
            await this.setItemInStorage(userId, ASYNC_STORAGE_STREAM_ONLY_ON_WIFI);
        } else {
            await this.removeItemInStorage(userId, ASYNC_STORAGE_STREAM_ONLY_ON_WIFI);
        }
    }

    async shouldShowProfileOnboarding(userId: string): Promise<boolean> {
        return !(await this.getItemFromStorage<boolean>(
            userId,
            ASYNC_STORAGE_KEY_ONBOARDING_PROFILE_SEEN
        ));
    }

    async setShowProfileOnboarding(userId: string): Promise<void> {
        await this.setItemInStorage(userId, ASYNC_STORAGE_KEY_ONBOARDING_PROFILE_SEEN);
    }

    async fetchTermsAndConditions(): Promise<TextPageContent | undefined> {
        const response = await this.request({
            path: '/textPages',
            method: 'GET',
            guard: TextPagesContentResponseGuard,
        });
        // WIP - Temporary while this page is not integrated with backstage. Then we'll be able to get the specific TermsAndConditions page through its id.
        return response?.find((page) => page.title === 'Terms of Use');
    }

    async shouldStreamOverWifiOnly(userId: string | undefined | null): Promise<boolean> {
        return this.getItemFromStorage<boolean>(userId, ASYNC_STORAGE_STREAM_ONLY_ON_WIFI);
    }

    async isTermsAndConditionsAccepted(
        userId: number | string | undefined | null,
        termsAndConditions: TextPageContent
    ): Promise<boolean> {
        const accepted = await this.getItemFromStorage<boolean>(
            userId,
            ASYNC_STORAGE_ACCEPTED_TERMS_AND_CONDITIONS
        );
        const currentUpdatedAt = termsAndConditions?.updatedAt || termsAndConditions?.createdAt;
        const storedUpdatedAt = await this.getItemFromStorage<number>(
            userId,
            ASYNC_STORAGE_ACCEPTED_TERMS_UPDATED_AT
        );

        return accepted && currentUpdatedAt === storedUpdatedAt;
    }

    async acceptTermsAndConditions(
        userId: number | string,
        termsAndConditions: TextPageContent
    ): Promise<void> {
        await this.setItemInStorage(userId, ASYNC_STORAGE_ACCEPTED_TERMS_AND_CONDITIONS);
        if (termsAndConditions.updatedAt)
            await this.setItemInStorage(
                userId,
                ASYNC_STORAGE_ACCEPTED_TERMS_UPDATED_AT,
                termsAndConditions.updatedAt
            );
    }

    async shouldShowCreatePin(userId: number | string | undefined | null): Promise<boolean> {
        return !(await this.getItemFromStorage<boolean>(userId, ASYNC_STORAGE_KEY_PIN_CREATED));
    }

    async setShowCreatePin(userId: number | string): Promise<void> {
        await this.setItemInStorage(userId, ASYNC_STORAGE_KEY_PIN_CREATED);
    }

    async shouldShowAddSubscription(userId: number | string | undefined | null): Promise<boolean> {
        return !(await this.getItemFromStorage<boolean>(
            userId,
            ASYNC_STORAGE_KEY_ONBOARDING_SUBSCRIPTION
        ));
    }

    async setShowAddSubscription(userId: number | string): Promise<void> {
        await this.setItemInStorage(userId, ASYNC_STORAGE_KEY_ONBOARDING_SUBSCRIPTION);
    }

    async setPinProtectionForPayments(userId: number | string, value: boolean) {
        await this.setItemInStorage(userId, ASYNC_STORAGE_KEY_PIN_FOR_PAYMENTS, value);
    }

    async isPinProtectionForPaymentsSet(
        userId: number | string | undefined | null
    ): Promise<boolean> {
        return this.getItemFromStorage<boolean>(userId, ASYNC_STORAGE_KEY_PIN_FOR_PAYMENTS);
    }
}

export const createBackstageAppSettingDataClient = (params: BaseApiParams) => {
    return new BackstageAppSettingsDataClient(params);
};
