import React, { useState, useRef, RefObject, SetStateAction } from 'react';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useImage } from '@24i/nxg-sdk-gluons/src/context/ImageService';
import { WEB_SCREEN_MAX_WIDTH } from '@24i/nxg-sdk-gluons/src/utils/constants';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Interactable, View } from '@24i/nxg-sdk-quarks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import SwiperClass from 'swiper/types/swiper-class';
import { Arrow } from '@24i/nxg-sdk-smartott/src/screens/UpsertProfileScreen/components/AvatarPicker/components/Arrow/index.web';
import { ViewStyle } from 'react-native';
import { ASSET_TYPE, Asset, isEpisode } from '@24i/nxg-sdk-photon/src';
import { useIsMobileLandscape } from '@24i/nxg-sdk-quantum/src/Dimensions/hooks/useIsMobileLandscape';
import { Breakpoint } from '@24i/nxg-sdk-smartott/src/utils/styles/constants';
import BannerCarouselItem from '../components/Item';
import getBannerCarouselStyles from '../styles';
import { BannerCarouselItemData, BannerCarouselTypes } from '../types';
import withBlockedModal from '../../BlockedModal/hoc';
import LoadingWrapper from '../../LoadingWrapper';
import { WithBlockedModalProps } from '../../BlockedModal/types';
import { getHeroBannerTestID, HERO_BANNER_TEST_IDS } from '../test-utils';
import useAssetBlockersValidation from '../../../hooks/useAssetBlockersValidation';

const BannerCarousel = (props: BannerCarouselTypes & WithBlockedModalProps) => {
    const {
        onItemPlayPress,
        bannerTopGradient,
        playButtonTitle,
        onItemPress,
        bannerHeight,
        index: bannerIndex,
        items,
        showMetadata,
        label,
    } = props;

    const { height, width } = useDimensions();
    const { theme } = useTheme();
    const { getSpecificQualityAsset } = useImage();
    const { isMobileLandscape } = useIsMobileLandscape();
    const styles = getBannerCarouselStyles(theme, isMobileLandscape);
    const slideHeight = bannerHeight || height * 0.66;
    const isMobileWidth = window.innerWidth < WEB_SCREEN_MAX_WIDTH.XS;
    const { handleBlockersCheck } = useAssetBlockersValidation({});
    const swiperRef = useRef<SwiperClass | undefined>();
    const [currentCarouselIndex, setcurrentCarouselIndex] = useState<number>(0);

    const [hoveredIndex, setHoveredIndex] = useState<number>(-1);
    const [hoveredPagination, setHoveredPagination] = useState<boolean>(false);
    const [hoveredArrow, setHoveredArrow] = useState<'left' | 'right' | null>(null);

    const [actionButtonRef, setActionButtonRef] = useState<RefObject<HTMLElement>>();

    const updateActionButtonsRef = (
        buttonRef: SetStateAction<RefObject<HTMLElement> | undefined>
    ) => {
        setActionButtonRef(buttonRef);
    };

    const getBulletStyles = (index: number): ViewStyle => {
        switch (index) {
            case currentCarouselIndex:
                return styles.activeDot;
            case hoveredIndex:
                return styles.hoveredDot || styles.dot;
            default:
                return styles.dot;
        }
    };

    const renderCustomPaginationBullets = (): JSX.Element => (
        <View
            style={[
                styles.pagination,
                hoveredPagination && { backgroundColor: theme.color.darker4 },
            ]}
            onMouseEnter={() => setHoveredPagination(true)}
            onMouseLeave={() => setHoveredPagination(false)}
        >
            {items?.map((item: Asset, index: number) => (
                <Interactable
                    key={`pagination_dot_${item.id}`}
                    onPress={() => swiperRef.current?.slideTo(index + 1)}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(-1)}
                    testID={getHeroBannerTestID(HERO_BANNER_TEST_IDS.PAGINATION_BUTTON, {
                        index,
                    })}
                    style={styles.paginationInteractable}
                >
                    <View style={getBulletStyles(index)} />
                </Interactable>
            ))}
        </View>
    );

    const renderNavigationArrows = (): JSX.Element => (
        <>
            <Interactable
                style={[styles.navigationArrowWrapper, styles.navigationLeftArrow]}
                testID={getHeroBannerTestID(HERO_BANNER_TEST_IDS.LEFT_ARROW)}
                onPress={() => swiperRef.current?.slidePrev()}
                onMouseEnter={() => setHoveredArrow('left')}
                onMouseLeave={() => setHoveredArrow(null)}
            >
                <Arrow
                    orientation="left"
                    isEnabled
                    isHovered={hoveredArrow === 'left'}
                    variant="carousel"
                />
            </Interactable>
            <Interactable
                style={[styles.navigationArrowWrapper, styles.navigationRightArrow]}
                testID={getHeroBannerTestID(HERO_BANNER_TEST_IDS.RIGHT_ARROW)}
                onPress={() => swiperRef.current?.slideNext()}
                onMouseEnter={() => setHoveredArrow('right')}
                onMouseLeave={() => setHoveredArrow(null)}
            >
                <Arrow
                    orientation="right"
                    isEnabled
                    isHovered={hoveredArrow === 'right'}
                    variant="carousel"
                />
            </Interactable>
        </>
    );

    const bannerCarouselItemContainer = (
        bannerCarouselItemData: BannerCarouselItemData,
        index: number
    ): JSX.Element => {
        const {
            data,
            data: { id },
        } = bannerCarouselItemData;

        const onPlayPress = async () => {
            const { hasBlocker } = await handleBlockersCheck({
                asset: data,
            });
            if (hasBlocker) return;
            onItemPlayPress(data);
        };

        return (
            <BannerCarouselItem
                index={index}
                bannerIndex={bannerIndex}
                key={id}
                item={bannerCarouselItemData}
                onPress={(e) => {
                    if (isEpisode(data)) return;
                    onItemPress(data, e);
                }}
                onPlayPress={onPlayPress}
                topGradient={bannerTopGradient}
                playButtonTitle={playButtonTitle}
                hideInfoButton={
                    width < Breakpoint.SM ||
                    isEpisode(data) ||
                    // Web fix for Android task https://aferian.atlassian.net/browse/USAGM04-175 (don't show More info button for channels w/out broadcastMetadata)
                    (data.type === ASSET_TYPE.CHANNEL &&
                        Object.keys((data as any)?.broadcastMetadata).length === 0)
                }
                setActionButtonsRef={updateActionButtonsRef}
                showMetadata={showMetadata}
            />
        );
    };

    const getImageUrlForAsset = (asset: Asset): string => {
        const { heroLandscape, heroPortrait, background, poster, packshotLandscape } = asset;
        const imgType = isMobileWidth
            ? heroPortrait || heroLandscape || poster || packshotLandscape || ''
            : heroLandscape || packshotLandscape || background || '';

        return getSpecificQualityAsset(
            imgType,
            Math.min(window.innerWidth, WEB_SCREEN_MAX_WIDTH.L),
            Math.min(window.innerHeight, 1080)
        );
    };

    const renderItems = (): JSX.Element[] | undefined =>
        items?.map((asset: Asset, index: number) => {
            const obj = {
                title: asset.title,
                subtitle: asset.subtitle,
                illustration: getImageUrlForAsset(asset),
                data: {
                    ...asset,
                    sectionLabel: label,
                },
            };
            return (
                <SwiperSlide key={obj.data.id}>
                    {bannerCarouselItemContainer(obj, index)}
                </SwiperSlide>
            );
        });

    if (items?.length === 1) {
        const [asset] = items;
        const obj = {
            title: asset.title,
            subtitle: asset.subtitle,
            illustration: getImageUrlForAsset(asset),
            data: {
                ...asset,
                sectionLabel: label,
            },
        };

        return (
            <View style={{ width, ...styles.singleItemContainer }}>
                {bannerCarouselItemContainer(obj, 0)}
            </View>
        );
    }

    /* By targeting Play/Info buttons, we can prevent the Swiper's default behaviour
    on these elements. This was causing a random bug: instead of performing the buttons'
    designated actions, we were going to the previous/next element on the slider  */
    const getPlayInfoButtonsSelector = () => {
        if (actionButtonRef?.current) {
            const { nodeName, firstChild } = actionButtonRef.current;
            const element = `${nodeName.toLowerCase()}.${
                (firstChild?.firstChild as HTMLElement)?.className
            }`;
            return element.replaceAll(' ', '.');
        }

        return undefined;
    };

    return (
        <LoadingWrapper
            isLoading={!items}
            loaderProps={{ size: 'large', style: { ...styles.indicator, height: slideHeight } }}
            // Best way to have an "overflow" effect for the caroussel
            wrapperStyle={styles.customContainer}
            placeholderStyle={styles.customContainer}
        >
            <>
                <Swiper
                    onSwiper={(swiper: SwiperClass) => {
                        swiperRef.current = swiper;
                    }}
                    onSlideChange={(swiper: SwiperClass) => {
                        const index = swiper.realIndex;
                        setcurrentCarouselIndex(index === items?.length ? 0 : index);
                    }}
                    mousewheel={{
                        forceToAxis: true,
                        sensitivity: 0.8,
                        thresholdDelta: 10,
                    }}
                    loop={items && items.length > 1}
                    /* required this additional width to prevent making the next slide visible */
                    width={width * 1.05}
                    height={slideHeight}
                    slidesPerView={1}
                    centeredSlides
                    /* required this negative offset as Swiper component gives a left sided showing
                        that there are more slides to see */
                    slidesOffsetBefore={-(0.05 * width)}
                    preventClicks
                    noSwipingSelector={getPlayInfoButtonsSelector()}
                    key={getPlayInfoButtonsSelector()}
                >
                    {renderItems()}
                </Swiper>
                {width > Breakpoint.SM && items && items.length > 1 && renderNavigationArrows()}
                {items && items.length > 1 && renderCustomPaginationBullets()}
            </>
        </LoadingWrapper>
    );
};

export default overridable(withBlockedModal(BannerCarousel), 'BannerCarousel');
