import React, { useEffect } from 'react';

import FirebaseContext from '../context';

let analytics = () => {};
let crashlytics = () => {};
try {
    analytics = require('@react-native-firebase/analytics').default;
    crashlytics = require('@react-native-firebase/crashlytics').default;
} catch (err) {
    // do nothing
}

const FirebaseProvider = ({ children }) => {
    useEffect(() => {
        collectData();
    }, []);

    const collectData = (isCollecting = true) => {
        analytics()?.setAnalyticsCollectionEnabled?.(isCollecting);
        crashlytics()?.setCrashlyticsCollectionEnabled?.(isCollecting);
    };

    const logEvent = (ev) => {
        analytics()?.logEvent?.(ev);
    };

    const recordError = (ev) => {
        if (ev instanceof Error) crashlytics()?.recordError?.(ev);
    };

    const crash = () => {
        crashlytics()?.crash?.();
    };

    const crashlyticsLog = (ev) => {
        crashlytics()?.log?.(ev);
    };

    return (
        <FirebaseContext.Provider
            value={{
                collectData,
                logEvent,
                recordError,
                crash,
                crashlyticsLog,
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

export default FirebaseProvider;
