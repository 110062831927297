import { View } from '@24i/nxg-sdk-quarks';
import React, { FC, useEffect, useState } from 'react';
import { getTelegramWebAppTopInset } from '@24i/nxg-sdk-higgs/src/utils';
import { useTheme } from '@24i/nxg-sdk-higgs';
import BackButton from '@24i/nxg-sdk-gluons/src/components/buttons/BackButton';
import { useDebounceFn } from '@24i/nxg-core-utils/src';
import { BASE_GAP } from '@24i/nxg-core-utils/src/constants';

import { I18nManager } from 'react-native';
import getStyles from '../withWebHeader/styles';
import { styles } from './styles';

const THRESHOLD_FOR_HEADER_BACKGROUND = 200;

type Props = {
    onBack: () => void;
    title: string;
};

export const StickyBackHeader: FC<Props> = ({ onBack, title, children }) => {
    const [shouldShowBackground, setShouldShowBackground] = useState(false);

    const { theme } = useTheme();
    const withWebHeaderStyles = getStyles(theme, false);

    const textColor = shouldShowBackground ? theme.color.textSecondary : theme.color.textPrimary;
    const backIconName = shouldShowBackground ? 'onDark-back' : 'onPrimary-back';
    const paddingTop = BASE_GAP + getTelegramWebAppTopInset();

    const handleBackroundVisibility = (distanceFromTop: number) => {
        if (distanceFromTop >= THRESHOLD_FOR_HEADER_BACKGROUND) {
            setShouldShowBackground(true);
        } else {
            setShouldShowBackground(false);
        }
    };

    const { run: debouncedHandleScroll } = useDebounceFn(
        (distanceFromTop: number) => {
            handleBackroundVisibility(distanceFromTop);
        },
        { wait: 200 }
    );

    useEffect(() => {
        const onScroll = () => {
            const scrolledPixels = window.scrollY;
            debouncedHandleScroll(scrolledPixels);
        };

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [debouncedHandleScroll]);

    return (
        <>
            <View
                style={{
                    ...styles.wrapper,
                    backgroundColor: shouldShowBackground
                        ? theme.color.darkerBlur1.color
                        : 'transparent',
                    borderBottomWidth: shouldShowBackground ? 1 : 0,
                }}
            >
                <View style={{ ...styles.textContainer, paddingTop }}>
                    <BackButton
                        onPress={onBack}
                        iconName={`${backIconName}${I18nManager.isRTL ? '_rtl' : ''}`}
                        iconSize={24}
                        color={textColor}
                        title={title}
                        additionalTextStyles={{
                            ...withWebHeaderStyles.backButtonText,
                            color: textColor,
                            opacity: shouldShowBackground ? 1 : 0,
                        }}
                        additionalIconStyles={withWebHeaderStyles.backButtonIcon}
                        titleNumberOfLines={1}
                    />
                </View>
            </View>
            {children}
        </>
    );
};
