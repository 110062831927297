/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useMemo } from 'react';
import { Interactable, Image } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Link } from '@24i/nxg-core-router';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { Image as ReactNativeImage } from 'react-native';
import getCornerLogoStyles from '../styles/index.web';
import { CornelLogoWebProps } from '../types';

const CornerLogo = ({
    styles: getStyles = getCornerLogoStyles,
    linkProps,
    isClickable = true,
    testID,
}: CornelLogoWebProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { query } = useRouter();
    const { closeModal, modalChildren } = useModal();

    const link = {
        href: `/`,
    };

    const [ratio, setRatio] = useState(0);
    const width = useMemo(() => {
        const { height } = styles.image;
        if (typeof height !== 'number' || !ratio) return 0;
        return height * ratio;
    }, [styles.image.height, ratio]);

    const isRouteHome = linkProps?.as === (`/${query.slug}` as string);

    useEffect(() => {
        if (typeof theme.logo === 'string')
            ReactNativeImage.getSize(theme.logo, (imageWidth, imageHeight) => {
                setRatio(imageWidth / imageHeight);
            });
    }, [theme.logo]);

    const renderLogoImage = () => (
        <Image
            style={[styles.image, { width }]}
            source={typeof theme.logo === 'number' ? theme.logo : { uri: theme.logo }}
            resizeMode="contain"
        />
    );

    if (!theme.logo) return null;

    return isClickable ? (
        <Interactable
            style={styles.holder}
            disabled={!isClickable}
            testID={testID}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onPress={isRouteHome && !!modalChildren ? () => closeModal() : () => {}}
        >
            <Link {...(linkProps || link)} passHref disabled>
                {renderLogoImage()}
            </Link>
        </Interactable>
    ) : (
        renderLogoImage()
    );
};

export { getCornerLogoStyles };
export default overridable(CornerLogo, 'CornerLogo');
