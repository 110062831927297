import { Theme } from '@24i/nxg-sdk-photon';

export const extractThemeColor = (themeColors: Theme['color'], color: string | undefined) => {
    if (!color) return undefined;

    const keys = color.split('.');
    let currentValue = themeColors;

    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys) {
        if (currentValue && key in currentValue) {
            currentValue = currentValue[key];
        } else {
            return undefined;
        }
    }

    return currentValue;
};
