import { getFont, getMargins } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { BottomPartStyles } from '../types';

export const getBottomPartStyles = <TTheme extends Theme>(theme: TTheme): BottomPartStyles => ({
    contentPickerHorizontalLine: {
        top: -2,
        height: 2,
        backgroundColor: theme.color.contrast4,
        borderRadius: 1,
        ...getMargins(),
    },
    contentPickerContainer: {
        ...getMargins(),
        flexDirection: 'row',
        overflow: 'scroll',
        zIndex: 9999,
    },
    contentPickerContainerStyle: {},
    contentPickerItem: {
        marginRight: 32,
        paddingBottom: 12,
        borderBottomWidth: 2,
    },
    contentPickerItemText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h3'),
    },
    bottomPart: {
        overflow: 'visible',
        ...getMargins(),
    },
    bottomPartContainer: {
        paddingTop: 40,
    },
    inactiveTabText: {
        opacity: 0.6,
        ':hover': { opacity: 1 },
        transition: 'opacity 0.2s ease-in',
    },
    pressedTabText: {
        opacity: 0.8,
        transition: 'opacity 0.2s ease-in',
    },
    slider: {
        position: 'absolute',
        top: 'calc(100% - 2px)',
        height: 2,
        backgroundColor: theme.color.buttonPrimary,
        transition: '.3s ease-in-out',
    },
});

export default getBottomPartStyles;
