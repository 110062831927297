import React from 'react';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';
import { useTranslation } from 'react-i18next';
import GenericModal from '../index';

export interface UseErrorModalProps {
    onCloseErrorModal?: () => void;
}

const useErrorModal = (props?: UseErrorModalProps) => {
    const { setModalChildren, setModalProps, closeModal } = useModal();
    const { t, i18n } = useTranslation();

    const getTexts = (title, description): { errorTitle: string; errorDescription: string } => {
        if (i18n.exists(`error.${description}.body`)) {
            return {
                errorTitle: t(`error.${description}.title`) || t('error.A00.title'),
                errorDescription: t(`error.${description}.body`),
            };
        }
        return {
            errorTitle: title,
            errorDescription: description,
        };
    };

    const openErrorModal = (
        title: string,
        description: string,
        defaultHorizontalButtonLayout?: boolean,
        closeButtonLabel?: string,
        descriptionTextColor?: string
    ): JSX.Element => {
        const onModalClose = () => {
            props?.onCloseErrorModal?.();
        };
        setModalProps({
            onModalClose,
        });
        const { errorTitle, errorDescription } = getTexts(title, description);
        return setModalChildren(
            <GenericModal
                requiresModalWrapper={false}
                defaultHorizontalButtonLayout={defaultHorizontalButtonLayout}
                title={{
                    text: errorTitle,
                }}
                description={{
                    text: errorDescription,
                    customStyle: descriptionTextColor ? { color: descriptionTextColor } : undefined,
                }}
                primaryButton={{
                    title: closeButtonLabel || t('common.backGo'),
                    onPress: closeModal,
                }}
            />
        );
    };

    return { openErrorModal };
};

export default useErrorModal;
