import { useRouter, WebRouter } from '@24i/nxg-core-router/src/NextRouter';
import Loader from '@24i/nxg-sdk-gluons/src/components/ui/Loader';
import { ToastProvider } from '@24i/nxg-sdk-gluons/src/components/ui/Toast';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';
import GenericModal from '@24i/nxg-sdk-smartott/src/components/GenericModal';
import { View } from '@24i/nxg-sdk-quarks';
import { useTranslation } from 'react-i18next';
import React, { ComponentProps, useEffect } from 'react';
import NextError from 'next/error';
import { usePrevious } from '@24i/nxg-sdk-gluons';
import { useStore } from '@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import ReactModal from 'react-modal';

import { WEB_SCREEN_MAX_WIDTH } from '@24i/nxg-sdk-gluons/src/utils/constants';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import { useAppConfigQuery } from '@24i/nxg-sdk-smartott/src/hooks/query/useAppConfigQuery';
import { useTriggerOnScreenChange } from '@24i/nxg-sdk-smartott-shared/src/analytics/navigation/useTriggerOnScreenChange';
import { useTriggerOnAppClose } from '@24i/nxg-sdk-smartott-shared/src/analytics/navigation/useTriggerOnAppClose';
import { usePlayerSessionId } from '@24i/nxg-sdk-smartott-shared/src/analytics/player/usePlayerSessionId';
import { useQueryClient } from 'react-query';
import { ErrorCodes } from '@24i/nxg-sdk-smartott/src/utils/errorCodesMapper/types';
import Footer from '../components/Footer';
import { ApplicationStates, useAppStart } from '../context/AppStartContext';
import { useCurrentWebPageConfig } from '../hooks/useCurrentWebPageConfig';
import SearchScreen from '../screens/SearchScreen';
import NavBarMenu from './components/NavBarMenu';
import { NavigationPropsWeb } from './types';
import { SOTT_DEFAULT_WEB_SCREENS } from './constants';
import { useStartPageNavigation } from '../context/AppStartContext/hooks';

export const defaultRenderFooter = (props: ComponentProps<typeof Footer>) => <Footer {...props} />;

const Navigation = ({
    aboveHeaderContent,
    children,
    afterFooterContent,
    renderFooter = defaultRenderFooter,
}: NavigationPropsWeb) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { userData } = useStore();
    const previousUserData = usePrevious(userData);
    const { currentPageConfig, configStatus } = useCurrentWebPageConfig();
    const { logout } = useStartPageNavigation();

    const matchesNotFoundPage =
        currentPageConfig?.name === SOTT_DEFAULT_WEB_SCREENS.NotFound.uniqueWebId;

    useTriggerOnScreenChange();
    useTriggerOnAppClose();
    usePlayerSessionId();

    const router = useRouter() as WebRouter;

    const { appSettings } = useAppConfigQuery();

    const isBlocked = appSettings?.features.geoblock?.global?.blocked;

    const { modalChildren, setModalChildren, setModalProps, closeModal } = useModal();

    const { skipStartSequence, runStartSequence, appState } = useAppStart();

    const props: any = {};

    const search = new URLSearchParams(window.location.hash.replace('#', '?')).get('search');

    const openSearchModal = () => {
        setModalProps({
            name: 'search',
            overlayClassName: 'ReactModal__Overlay__Search',
            onModalClose: () => {
                if (search && search.length) {
                    window.history.back();
                }
            },
        });

        setModalChildren(<SearchScreen.Main {...props} />);
    };

    const openUnauthorizedModal = () => {
        setModalProps({
            overlayClassName: 'ReactModal__Overlay__Unauthorized',
            onModalClose: () => logout(),
        });

        setModalChildren(
            <GenericModal
                requiresModalWrapper={false}
                defaultHorizontalButtonLayout
                title={{
                    text: t('error.E25.title'),
                }}
                description={{
                    text: t('error.E25.body'),
                }}
                primaryButton={{
                    title: t('common.continue'),
                    onPress: async () => {
                        logout();
                        closeModal();
                    },
                }}
            />
        );
    };

    useEffect(() => {
        if (
            appState === ApplicationStates.initialized &&
            currentPageConfig &&
            !matchesNotFoundPage
        ) {
            if (currentPageConfig?.skipStartSequence) {
                skipStartSequence();
            } else {
                runStartSequence(undefined, router);
            }
        }
    }, [appState, currentPageConfig?.name]);

    const { width } = useDimensions();
    useEffect(() => {
        if (search && search.length && width > WEB_SCREEN_MAX_WIDTH.XS) {
            openSearchModal();
        }
    }, [router.asPath]);

    useEffect(() => {
        if (previousUserData && !userData) {
            logout();
        }
    }, [userData]);

    const onQueryCacheUpdate = (event) => {
        if (
            event.query.state.status === 'error' &&
            event.query.state.error.message.includes(ErrorCodes.INVALID_AUTHENTICATION_TOKEN) &&
            userData
        ) {
            // 401 error - unauthorized
            openUnauthorizedModal();
        }
    };

    useEffect(() => {
        if (queryClient) {
            const unsubscribe = queryClient?.getQueryCache().subscribe(onQueryCacheUpdate);
            return unsubscribe;
        }
        return () => null;
    }, [queryClient]);

    // Closes modal window if going back from opened modal using browser
    // back button
    useEffect(() => {
        const listener = (event: HashChangeEvent) => {
            if (event.oldURL.includes('search')) closeModal();
        };
        window.addEventListener('hashchange', listener);
        return () => window.removeEventListener('hashchange', listener);
    }, []);

    // Sets root selector for react-modal
    useEffect(() => {
        ReactModal.setAppElement('#__next');
    }, []);
    if (configStatus === 'config_not_yet_available') {
        return (
            <Loader
                additionalStyles={{
                    minHeight: '100%',
                    justifyContent: 'center',
                }}
            />
        );
    }

    if (configStatus === 'config_not_found' || matchesNotFoundPage) {
        return <NextError statusCode={404} />;
    }

    const { optionsWeb: currentPageOptions } = currentPageConfig || {};
    const { showFooter, showNavBarMenu } = currentPageOptions || {};

    if (appState !== ApplicationStates.initialNavigationComplete) {
        return (
            <Loader
                additionalStyles={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    zIndex: 100,
                    justifyContent: 'center',
                }}
            />
        );
    }

    return (
        <>
            <ToastProvider />
            <View
                style={{
                    minHeight: '100vh',
                    width: '100%',
                    position: modalChildren ? 'fixed' : 'relative',
                    paddingBottom:
                        width <= WEB_SCREEN_MAX_WIDTH.XS && showNavBarMenu !== false ? 51 : 0,
                    overflowY: modalChildren ? 'hidden' : 'visible',
                    overflowX: modalChildren ? 'hidden' : 'visible',
                }}
            >
                {aboveHeaderContent}
                {!isBlocked &&
                    showNavBarMenu !== false &&
                    appState === ApplicationStates.initialNavigationComplete && <NavBarMenu />}
                <View style={{ minHeight: '100vh' }}>{children}</View>
                {!isBlocked && showFooter !== false && renderFooter({})}
                {afterFooterContent}
            </View>
        </>
    );
};

export default Navigation;
