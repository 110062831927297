import { isPlatformWeb } from 'renative';
import { StackNavigationProp } from '@react-navigation/stack';
import useNavigationCore from '@24i/nxg-core-router/src/hooks/useNavigation';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { InAppBrowser } from 'react-native-inappbrowser-reborn';
import { Linking } from 'react-native';
import { PRODUCT_SCREENS } from '@24i/nxg-sdk-photon';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { openComposer } from 'react-native-email-link';
import {
    NavScreenConfig,
    NavScreenConfigWeb,
} from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { useOpenLinkWithInAppBrowser } from '../hooks/inAppBrowser/useOpenLinkWithInAppBrowser';
import { StackParamList } from './types';
import '../types';

export const useNavigation = () => useNavigationCore<StackNavigationProp<StackParamList>>();

export const isExternalLinkValid = (url: string) => {
    // check user didn't forget to put http or https in the link
    const regex = new RegExp('^(http|https)://', 'i');
    return regex.test(url);
};

export const useOpenExternalURL = () => {
    const openExternalPageMode = useFeature('openExternalPage');
    const { openLinkWithInAppBrowser } = useOpenLinkWithInAppBrowser();

    const openExternalURL = async (url: string) => {
        switch (true) {
            case url.includes('mailto:') && !isPlatformWeb:
                openComposer({
                    to: url.split('mailto:')[1],
                });
                break;

            case !isExternalLinkValid(url):
                break;

            case !isPlatformWeb &&
                (await InAppBrowser.isAvailable()) &&
                (openExternalPageMode === 'in-app' || openExternalPageMode == null): {
                await openLinkWithInAppBrowser(url);
                break;
            }

            default: {
                await Linking.openURL(url);
            }
        }
    };

    return { openExternalURL };
};

export const useMenuItemPress = () => {
    const router = useRouter();
    const navigation = useNavigation();
    const { openExternalURL } = useOpenExternalURL();

    const onPress = (menuItem: NavScreenConfig) => {
        const { screenTypeToUse, reference, menuTitle, name, initialParams } = menuItem;
        if (screenTypeToUse === PRODUCT_SCREENS.CONSENT_MANAGEMENT) {
            if (window.OneTrust && typeof window.OneTrust.ToggleInfoDisplay === 'function') {
                window.OneTrust.ToggleInfoDisplay();
                return;
            }
        }
        if (screenTypeToUse === PRODUCT_SCREENS.EXTERNAL) {
            if (reference) {
                openExternalURL(reference);
                return;
            }
        }
        if (!isPlatformWeb) {
            // @ts-ignore
            navigation.navigate(name, {
                ...initialParams,
                title: menuTitle,
            });
            return;
        }

        const { webMenuLink } = menuItem as NavScreenConfigWeb;

        // TODO: Improve typing so these checks are not needed
        if (!webMenuLink) {
            throw new Error(
                `The "webMenuLink" has to be defined if no custom link component is used`
            );
        }

        const { as, href } = webMenuLink;

        router.push(href, as);
    };

    return onPress;
};
