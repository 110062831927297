import Default, { Router, useRouter as useOriginalRouter, withRouter } from 'next/router';

type pushParams = Parameters<Router['push']>;

const useRouter = () => {
    const router = useOriginalRouter();

    // Custom push function to modify the URL before pushing, take pushParams as input
    const customPush = (...args: pushParams) => {
        // Modify the URL or perform additional logic here
        const [url, as, options] = args;
        let modifiedUrl = as;

        if (window?.Telegram?.WebApp) {
            modifiedUrl += `#tgWebAppVersion=8.0`;
        }

        return router.push(url, modifiedUrl, options);
    };

    return {
        ...router,
        push: customPush,
    };
};

export { withRouter, useRouter, Router };
export default Default;
export type WebRouter = Router;
