import { createGuard, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { AGE_CLASSIFICATION_TYPE_GUARD, ASSET_TYPE_GUARD, BLOCKING_REASON_GUARD } from '../enums';
import { GenreGuard } from './genre';
import { AssetImagesGuard, ImageGuard } from './image';
import { PersonGuard } from './person';
import { StreamGuard } from './stream';
import { ProductGuard } from './product';

export const BlockItemGuard = createGuard('BlockItem', undefined, {
    reason: nullable(BLOCKING_REASON_GUARD),
    message: nullable(t.string),
    resolvement: t.partial({
        action: nullable(t.string),
        data: t.partial({
            subscriptionIds: t.UnknownArray,
            productIds: t.UnknownArray,
            products: t.array(ProductGuard),
        }),
    }),
});

export const AgeIndicatorGuard = createGuard('AgeIndicator', undefined, {
    id: t.string,
    system: AGE_CLASSIFICATION_TYPE_GUARD,
    label: t.string,
    description: nullable(t.string),
    images: t.array(ImageGuard),
});

export const AgeClassificationGuard = createGuard('AgeClassification', undefined, {
    type: AGE_CLASSIFICATION_TYPE_GUARD,
    rating: nullable(t.union([t.string, t.number])),
    description: nullable(t.string),
    indicators: t.array(AgeIndicatorGuard),
});

export const ExternalAuthDetailsGuard = createGuard('ExternalAuthDetails', undefined, {
    primetime: t.type({
        isProtected: t.boolean,
        level: t.string,
    }),
});

export const AssetGuard = createGuard(
    'Asset',
    {
        id: t.string,
        type: ASSET_TYPE_GUARD,
    },
    {
        duration: nullable(t.number),
        year: nullable(t.number),
        minimumPrice: nullable(t.unknown),
        clipIds: nullable(t.array(t.string)),
        externalId: nullable(t.string),
        externalAuthDetails: nullable(ExternalAuthDetailsGuard),
        label: nullable(t.string),
        description: nullable(t.string),
        shortDescription: nullable(t.string),
        short_description: nullable(t.string),
        images: nullable(AssetImagesGuard),
        genres: nullable(t.array(GenreGuard)),
        rating: nullable(t.union([t.string, t.number])),
        crew: nullable(t.array(PersonGuard)),
        releaseDate: nullable(t.string),
        fullReleaseDate: nullable(t.string),
        isHidden: nullable(t.boolean),
        blocked: nullable(t.array(BlockItemGuard)),
        seasonNumber: nullable(t.number),
        episodeNumber: nullable(t.number),
        episodeTitle: nullable(t.string),
        series: nullable(t.string),
        startsAt: nullable(t.number),
        start: nullable(t.number),
        endsAt: nullable(t.number),
        end: nullable(t.number),
        number: nullable(t.number),
        geoblock: createGuard('Geoblock', undefined, {
            deny: t.array(t.string),
            allow: t.array(t.string),
        }),
        advertisementStrategyId: nullable(t.string),
        checksum: nullable(t.string),
        sourceId: nullable(t.string),
        translations: nullable(t.unknown),
        ageClassification: AgeClassificationGuard,
        subscriptionTags: t.array(
            createGuard('SubscriptionTags', undefined, {
                id: nullable(t.string),
            })
        ),
        windows: t.array(
            createGuard('Windows', undefined, {
                blocked: nullable(t.boolean),
                blockedMessage: nullable(t.string),
                countries: t.array(t.string),
                applications: t.array(t.string),
                startsAt: nullable(t.number),
                endsAt: nullable(t.number),
                title: nullable(t.string),
            })
        ),
        tags: nullable(t.array(t.string)),
        updatedByUserAt: nullable(t.number),
        createdAt: nullable(t.number),
        updatedAt: nullable(t.number),
        channelId: nullable(t.string),
        channel: nullable(
            // can full asset
            t.type({
                id: t.string,
                number: nullable(t.number),
                label: nullable(t.string),
                features: nullable(
                    t.type({
                        live: t.type({
                            options: t.type({
                                startover: t.boolean,
                            }),
                        }),
                    })
                ),
            })
        ),
        channelLogo: nullable(t.string),
        // TODO: These are duplicate values from image property.
        // We shuld either use these or 'images' not both.
        poster: nullable(t.string),
        still: nullable(t.string),
        square: nullable(t.string),
        packshotLandscape: nullable(t.string),
        background: nullable(t.string),
        heroPortrait: nullable(t.string),
        heroLandscape: nullable(t.string),
        backgroundPortrait: nullable(t.string),
        subtitle: nullable(t.string),
        title: nullable(t.string),
        // There is a bug in BS. This sometimes returns a number and sometimes a string. They will take a look into this. It should be always a number.
        // https://amino24i.slack.com/archives/C66TGFGGL/p1632145918086700
        continueWatchingOffset: nullable(t.number),
        continueWatchingLastTime: nullable(t.number),
        stream: StreamGuard,
        progress: nullable(t.number),
        isLive: nullable(t.boolean),
        enableCatchUp: nullable(t.boolean),
        editionId: nullable(t.string),
        status: t.string,
        assetLabel: nullable(t.string),
        allowsRestart: nullable(t.boolean),
        allowsTimeshift: nullable(t.boolean),
        allowsRecording: nullable(t.boolean),
        isTrailer: nullable(t.boolean),
        seriesId: nullable(t.string),
        textPlacement: nullable(t.string),
        target: nullable(
            t.type({
                type: ASSET_TYPE_GUARD,
                link: nullable(t.string),
            })
        ),
        position: nullable(t.string),
        isAdult: nullable(t.boolean),
        isStartOver: nullable(t.boolean),
        sectionLabel: nullable(t.string),
        seriesName: nullable(t.string),
        podcastName: nullable(t.string),
    }
);

export type Asset = t.TypeOf<typeof AssetGuard>;
export type BlockedItem = t.TypeOf<typeof BlockItemGuard>;
export type Classification = t.TypeOf<typeof AgeClassificationGuard>;
export type Indicator = t.TypeOf<typeof AgeIndicatorGuard>;
