import { Theme } from '@24i/nxg-sdk-photon';
import { getBorderRadius } from '@24i/nxg-sdk-higgs';
import { LiveBadgeStyles } from '../types';
import { getSharedStyles } from './sharedStyles';

export const getLiveBadgeStyles = (theme: Theme): LiveBadgeStyles => ({
    liveBadgeContainer: {
        position: 'absolute',
        backgroundColor: theme.color.progressBarLive,
        left: '5%',
        bottom: 21,
        minWidth: 44,
        height: 24,
        paddingLeft: 5,
        paddingRight: 5,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 2,
        zIndex: 9999,
        ...getBorderRadius(theme.elements, 'label-cornerRadius'),
    },
    ...getSharedStyles(theme),
});
