import { getMargins } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { EpgScreenStyles } from './types';

const PageEpg = (theme: Theme): EpgScreenStyles => ({
    container: {
        flex: 1,
        backgroundColor: theme.color.darkerBlur1.color,
        height: '95%',
    },
    // @ts-ignore: valid responsive styles; the margin-top is handled in the View
    header: {
        ...getMargins(),
    },
});

export default PageEpg;
