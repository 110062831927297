import React, { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WebRouter, useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { View } from '@24i/nxg-sdk-quarks';
import { NavConfigWeb } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import OnboardingSubscriptionButtons from '@24i/nxg-sdk-smartott/src/components/subscriptions/OnboardingSubscriptionButtons';
import { Platform } from '@24i/nxg-sdk-quantum';
import { ASSET_TYPE } from '@24i/nxg-sdk-photon';
import {
    onAnalytics,
    ANALYTICS_TRIGGERS,
    ANALYTICS_EVENTS,
} from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { useSessionId } from '@24i/nxg-sdk-smartott-shared/src/analytics/hooks/useSessionId';

import CurrentSubscriptions from '../../../../../components/subscriptions/CurrentSubscriptions';
import SubscriptionSelection from '../../../../../components/subscriptions/SubscriptionSelection';
import ChangeSubscription from '../../../../../components/subscriptions/ChangeSubscription';
import ChangeSubscriptionConfirm from '../../../../../components/subscriptions/ChangeSubscriptionConfirm';
import CancelSubscription from '../../../../../components/subscriptions/CancelSubscription';
import SubscriptionCancelled from '../../../../../components/subscriptions/SubscriptionCancelled';
import SubscriptionUnableChange from '../../../../../components/subscriptions/SubscriptionUnableChange';
import ConfirmPurchase from '../../../../../components/PurchasesModal/ConfirmPurchase';
import StatusLayout from '../../../../../components/PurchasesModal/components/layout/StatusLayout';
import {
    confirmPurchasesOverrideStyles,
    confirmPurchasesOnboardingOverrideStyles,
} from '../../../styles/index.web';
import useWebPurchaseCheckout from '../../../../../hooks/useWebPurchaseCheckout';
import { MySubscriptionsViewProps } from '../../../types';
import { MySubscriptionsContentProps, Step } from '../types';
import { OnboardingSubscriptionSteps } from '../../../../AddSubscriptionScreen/View/types';
import {
    findParentSubmenuPageByMenuTitle,
    mapPrevStep,
    getStepTitle,
    GetBackButtonTitle,
} from './utils';
import { useNavigationConfigQuery } from '../../../../../hooks/query/useNavigationConfigQuery';
import { goToPurchasePage } from '../../../../../utils/navigation';
import { normalizeQuery } from '../../../../../utils';
import { useInvalidatePurchases } from '../../../../../hooks/query/purchases/useIsAssetPurchasedQuery';

export const useViewModel = (props: MySubscriptionsViewProps): MySubscriptionsContentProps => {
    const { t } = useTranslation();
    const router = useRouter();
    const { navigationConfig } = useNavigationConfigQuery();
    const { purchaseFlow, containingMenuTitle } = router.query;
    const isComingFromPurchaseFlow = purchaseFlow != null;
    const { sessionId } = useSessionId();
    const { invalidatePurchasesQuery } = useInvalidatePurchases();

    const {
        currentPlans,
        availableSubscriptions = [],
        isLoading,
        isOnboarding,
        setOnboardingStep,
        onPurchaseConfirm,
        onSubscriptionCancelConfirm,
    } = props;

    const [step, setStep] = useState(isOnboarding ? Step.AddSelection : Step.Current);
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<string | undefined>();
    const [subscriptionToChangeId, setSubscriptionToChangeId] = useState<string | undefined>();
    const [subscriptionToCancelId, setSubscriptionToCancelId] = useState<string | undefined>();
    const [isCancelConfirmModalVisible, setIsCancelConfirmModalVisible] = useState(false);
    const previousStep = mapPrevStep[step];

    const selectedItem = useMemo(
        () => availableSubscriptions.find((item) => item.id === selectedSubscriptionId),
        [availableSubscriptions, selectedSubscriptionId]
    );

    const subscriptionToChange = useMemo(
        () => currentPlans?.find((item) => item.id === subscriptionToChangeId),
        [availableSubscriptions, subscriptionToChangeId]
    );

    const subscriptionToCancel = useMemo(
        () => currentPlans?.find((item) => item.id === subscriptionToCancelId),
        [availableSubscriptions, subscriptionToCancelId]
    );

    const openCancelSubscriptionConfirmModal = () => setIsCancelConfirmModalVisible(true);

    const closeCancelSubscriptionConfirmModal = () => setIsCancelConfirmModalVisible(false);

    const handleOnAddSubscriptionButtonPress = () => setStep(Step.AddSelection);

    const handleOnSubscriptionSelect = (subscriptionId: string) => {
        goToPurchasePage({
            router: router as WebRouter,
            offerId: subscriptionId,
            referralAssetId: normalizeQuery(router.query?.referralAssetId),
            referralAssetType: normalizeQuery(router.query?.referralAssetType) as
                | ASSET_TYPE
                | undefined,
        });
    };

    const handleOnChangeSubscriptionPress = (subscriptionId: string) => {
        const selectedSubscription = currentPlans?.find((item) => item.id === subscriptionId);
        setSubscriptionToChangeId(subscriptionId);
        if (Platform.OS === 'web' && !selectedSubscription?.isWebPayment) {
            setStep(Step.UnableChange);
            return;
        }
        setStep(Step.ChangeSelection);
    };

    const handleOnChangeSubscriptionSelect = (subscriptionId: string) => {
        setSelectedSubscriptionId(subscriptionId);
        setStep(Step.ChangeConfirm);
    };

    const handleOnSubscriptionCancelPress = (subscriptionId: string) => {
        setSubscriptionToCancelId(subscriptionId);
        setStep(Step.Cancel);
    };

    const handleOnPaymentConfirm = () => {
        onPurchaseConfirm?.();
        setStep(Step.Success);
        // TODO connect to IAP
        onAnalytics(ANALYTICS_TRIGGERS.PURCHASE_COMPLETE, {
            event: ANALYTICS_EVENTS.PURCHASE_COMPLETE,
        });
        onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, {
            screen: t('analytics.documentTitle.purchase.step3'),
            sessionId,
        });
        invalidatePurchasesQuery();
    };

    const { resetQueryParams } = useWebPurchaseCheckout({
        onSuccessPurchaseOverride: handleOnPaymentConfirm,
        onTryAgain: () => {
            setStep(Step.AddSelection);
        },
        disabled: !!isOnboarding,
    });

    const handleOnSubscriptionCancelConfirm = async (callback = onSubscriptionCancelConfirm) => {
        // TODO connect to IAP
        if (!subscriptionToCancel) return;
        try {
            if (callback) await callback(subscriptionToCancel);
            setIsCancelConfirmModalVisible(false);
            setStep(Step.CancelSuccess);
        } catch {
            setIsCancelConfirmModalVisible(false);
        }
    };

    // FIXME: For web, the steps are now only two. Can extra steps be safely removed, or are used by other platforms?
    const getCurrentStep = (): ReactNode => {
        switch (step) {
            case Step.Current:
                return (
                    <CurrentSubscriptions
                        availableSubscriptions={availableSubscriptions}
                        currentPlans={currentPlans}
                        onAddSubscriptionButtonPress={handleOnAddSubscriptionButtonPress}
                        onChangeSubscriptionButtonPress={handleOnChangeSubscriptionPress}
                        isLoading={isLoading}
                    />
                );
            case Step.AddSelection:
                return (
                    <SubscriptionSelection
                        description={t('svod.add.description')}
                        subscriptions={availableSubscriptions}
                        onSubscriptionSelect={handleOnSubscriptionSelect}
                        isOnboarding={!!isOnboarding}
                        onBackPress={() => {
                            if (isOnboarding) {
                                setOnboardingStep?.(OnboardingSubscriptionSteps.Initial);
                            } else {
                                setStep(Step.Current);
                            }
                        }}
                    >
                        {isOnboarding && <OnboardingSubscriptionButtons />}
                    </SubscriptionSelection>
                );
            case Step.AddConfirm:
                return selectedItem ? (
                    <ConfirmPurchase
                        selectedItem={selectedItem}
                        styles={
                            isOnboarding
                                ? confirmPurchasesOnboardingOverrideStyles
                                : confirmPurchasesOverrideStyles
                        }
                        isOnboarding={isOnboarding}
                    />
                ) : null;
            case Step.ChangeSelection:
                return subscriptionToChange ? (
                    <ChangeSubscription
                        currentPlan={subscriptionToChange}
                        availableSubscriptions={[]}
                        onSubscriptionSelect={handleOnChangeSubscriptionSelect}
                        onCurrentSubscriptionCancel={handleOnSubscriptionCancelPress}
                    />
                ) : null;
            case Step.ChangeConfirm:
                return subscriptionToChange && selectedItem ? (
                    <ChangeSubscriptionConfirm
                        currentSubscription={subscriptionToChange}
                        newSubscription={selectedItem}
                        onPaymentConfirm={handleOnPaymentConfirm}
                    />
                ) : null;
            case Step.Cancel:
                return subscriptionToCancel ? (
                    <CancelSubscription
                        subscription={subscriptionToCancel}
                        onCancel={openCancelSubscriptionConfirmModal}
                    />
                ) : null;
            case Step.UnableChange:
                return <SubscriptionUnableChange onCancel={() => setStep(Step.Current)} />;
            case Step.CancelSuccess:
                return <SubscriptionCancelled onContinue={() => setStep(Step.Current)} />;
            case Step.Success:
                return (
                    <View>
                        <StatusLayout
                            title={t('svod.add.addConfirm.title')}
                            actionButton={{
                                title: t('common.continue'),
                                onPress: () => {
                                    resetQueryParams();
                                    setStep(Step.Current);
                                },
                            }}
                            isModalContent={false}
                        >
                            {t('svod.add.addConfirm.description') as string}
                        </StatusLayout>
                    </View>
                );
            default:
                return null;
        }
    };

    const onBackPressOverride = () => {
        if (previousStep !== undefined) {
            setStep(previousStep);
            return;
        }
        if (!isComingFromPurchaseFlow) {
            router?.back();
            return;
        }

        // WORKAROUND - Not a great solution, this is a workaround to navigate to Account submenu main screen when previous page was payment gate,
        // since user needs to have a way out.
        const parentScreen = findParentSubmenuPageByMenuTitle(
            (navigationConfig as NavConfigWeb)?.allNavigationItems,
            containingMenuTitle
        );

        if (parentScreen?.webMenuLink)
            router.push(parentScreen.webMenuLink.href, parentScreen.webMenuLink.as);
    };

    return {
        ...props,
        isCancelConfirmModalVisible,
        subscriptionToCancel,
        webHeaderTitle: getStepTitle(step, t),
        backButtonTitle: GetBackButtonTitle(step),
        forceDisable: step === Step.Success || isOnboarding,
        currentPlans,
        availableSubscriptions,
        isLoading,
        isOnboarding,
        forceShowHeader: !(step === Step.CancelSuccess),
        onBackPressOverride,
        getCurrentStep,
        setOnboardingStep,
        onPurchaseConfirm,
        onSubscriptionCancelConfirm,
        closeCancelSubscriptionConfirmModal,
        handleOnSubscriptionCancelConfirm,
    };
};
