import React, { createContext, useContext } from 'react';
import { getDummyClient, isTheme, ThemeDataClient } from '@24i/nxg-sdk-photon';

const defaultClient = getDummyClient<ThemeDataClient>();

export const ThemeDataContext = createContext<ThemeDataClient>(defaultClient);

export const ThemeDataProvider = ({ client = defaultClient, children, defaultTheme }) => {
    const fetchTheme = async () => {
        try {
            const theme = await client.fetchTheme();
            return theme;
        } catch (e) {
            if (defaultTheme && isTheme(defaultTheme)) return Promise.resolve(defaultTheme);
            throw e;
        }
    };

    return (
        <ThemeDataContext.Provider value={{ ...client, fetchTheme }}>
            {children}
        </ThemeDataContext.Provider>
    );
};

export const useThemeData = () => useContext(ThemeDataContext);
