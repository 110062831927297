import { Theme, Asset, PageSection } from '@24i/nxg-sdk-photon';
import { ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { SeeAllScreenStyles } from './styles/types';

export interface SeeAllScreenProps extends ScreenProps {
    styles?: (theme: Theme) => SeeAllScreenStyles;
    assetsLabel?: string | null;
}
export interface SeeAllScreenMobileProps extends SeeAllScreenProps {
    section: PageSection;
    assets: Asset[];
    handlePackshotPress: ({
        asset,
        action,
    }: {
        asset: Asset;
        action?: PageSection['actionForAllItems'];
    }) => void;
    onEndReached: () => void;
    showLoader: boolean;
    displayType: 'landscape' | 'portrait';
}
export interface SeeAllScreenWebProps extends SeeAllScreenProps {
    assets: Asset[];
    isLoading?: boolean;
    gridContainerRef: React.MutableRefObject<Element | undefined>;
    displayType: 'landscape' | 'portrait';
    handlePackshotPress: ({
        asset,
        action,
    }: {
        asset: Asset;
        action?: PageSection['actionForAllItems'];
    }) => void;
}

export enum SEE_ALL_TEST_IDS {
    CONTAINER = 'see_all_screen_container',
    TITLE = 'see_all_screen_title',
}

export type SeeAllScreenViewModelReturnProps = SeeAllScreenMobileProps & SeeAllScreenWebProps;
