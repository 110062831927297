import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { TextStyle } from 'react-native';

const styles = (theme: Theme) => ({
    mainContainer: {
        flexGrow: 1,
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: { width: '100%', height: '100%' },
    imageContainer: {
        backgroundColor: theme.color.lighterBlur2?.color,
        width: 112,
        height: 168,
        marginBottom: 49,
        justifyContent: 'center',
        alignItems: 'center',
        elevation: 2,
        shadowColor: 'black',
        shadowOffset: {
            width: 1,
            height: 1,
        },
        shadowOpacity: 0.8,
        shadowRadius: 8,
        borderRadius: 8,
    },
    additionalImageStyles: {
        borderRadius: 8,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
    },
    imageTextStyles: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h4'),
        opacity: 0.5,
        textAlign: 'center',
    } as TextStyle,
    mediaContainer: {
        flexDirection: 'row',
    },
    logoStyles: {
        height: 38,
        width: 48,
    },
    titleAndSubtitleContainer: {
        marginLeft: 12,
        width: 224,
    },
    titleStyles: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h4'),
    },
    subtitleStyles: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h4'),
    },
    additionalDisconnectButtonStyles: {
        marginLeft: 14,
        alignSelf: 'center',
    },
    volumeContainer: {
        marginTop: 25,
        flexDirection: 'row',
        alignItems: 'center',
    },
    sliderStyles: {
        width: 255,
        marginLeft: 16,
    },
    subactionContainer: {
        width: 162,
        height: 52,
        borderRadius: 30,
        backgroundColor: theme.color.lighter4,
        marginTop: 36,
    },
    subactionText: {
        marginLeft: 0,
    },
});

export default styles;
