/* eslint-disable no-param-reassign */
import { ISource, PlayerBase } from '@24i/player-base';
import { Asset, isBroadcast, isChannel } from '@24i/nxg-sdk-photon';
import { ISeriesData } from '@24i/nxg-sdk-smartott/src/screens/PlaybackScreen/managers';
import { ISeriesData as ISeriesDataTv } from '@24i/nxg-sdk-smartott-tv/src/screens/PlaybackScreen/managers/seriesData';
import { isCompatibleSource } from '@24i/player-utils-react';

export interface PlaybackState {
    source?: ISource;
    asset?: Asset | null;
    seriesData?: ISeriesData | ISeriesDataTv | undefined;
    hasAccurateTimes?: boolean;
    currentTime?: number;
    duration?: number;
    live?: boolean;
    lastMilestone?: number;
}

export const updatePlaybackState = (
    target: PlaybackState | undefined,
    playerInstance: PlayerBase | undefined
): void => {
    if (!target) return;
    const { source, asset } = target;
    if (
        playerInstance &&
        isCompatibleSource(playerInstance.source, source) &&
        playerInstance.isMetadataLoaded() &&
        Number.isFinite(playerInstance.duration)
    ) {
        target.hasAccurateTimes = true;
        target.live = playerInstance.live;
        target.currentTime = playerInstance.currentTime;
        target.duration = playerInstance.duration;
        if (playerInstance.ended) target.currentTime = playerInstance.duration;
    } else if (asset && !target.hasAccurateTimes) {
        target.live = isBroadcast(asset) || isChannel(asset);
        target.currentTime = (asset.continueWatchingOffset || 0) * 1000;
        target.duration = (asset.duration || 0) * 1000;
    }
};
