import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IPlayerError } from '@24i/player-base';
import { BLOCKING_REASON_TYPES } from '@24i/nxg-sdk-photon';

type UseErrorHandlingParams = {
    sourceError?: Error | IPlayerError | null;
    playbackError?: Error | IPlayerError | null;
    assetError?: Error | IPlayerError | null;
    onError?: (error: Error | IPlayerError) => void;
};

export const useErrorHandling = ({
    sourceError,
    playbackError,
    assetError,
    onError,
}: UseErrorHandlingParams) => {
    const { t } = useTranslation();

    useEffect(() => {
        const error = assetError || sourceError || playbackError;

        if (error) onError?.(error);
    }, [assetError, sourceError, playbackError, onError]);

    const getErrorMessage = (event?: Error | IPlayerError | null) => {
        if (!event) return null;
        if (!('code' in event)) {
            return {
                title: t('error.F00.title'),
                body: t('error.F00.body'),
            };
        }

        const { code: explicitCode, message: explicitMessage } = event;
        let title = t(`error.${explicitCode}.title`, { defaultValue: '', errorCode: explicitCode });
        let body = t(`error.${explicitCode}.body`, { defaultValue: '', errorCode: explicitCode });

        if (
            explicitMessage === BLOCKING_REASON_TYPES.PRODUCTS_MISMATCH ||
            explicitMessage === BLOCKING_REASON_TYPES.SUBSCRIPTION_TYPE
        ) {
            // hack for handlePlaybackError
            title = explicitMessage;
        } else if (!title || !body) {
            // if error code is not known, show generic player error
            title = t('error.F00.title', { errorCode: explicitCode });
            body = t('error.F00.body', { errorCode: explicitCode });
        }
        if (explicitMessage && !['E08', 'E21', 'E26', 'E27', 'F09'].includes(explicitCode)) {
            // message from error is usually more precise so it has priority except with blockers
            body = explicitMessage;
        }

        return {
            title,
            body,
            code: explicitCode,
        };
    };

    return { errorMessage: getErrorMessage(assetError || sourceError || playbackError) };
};
