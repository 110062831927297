import {
    ASSET_TYPE,
    getIsPodcastSeries,
    getIsPodcastEpisode,
    ThemeIconName,
} from '@24i/nxg-sdk-photon';
import type { TFunction } from 'i18next';
import { isPlatformWeb } from 'renative';
import { MainButtonProps } from './types';
import useBlockedModal from '../../../BlockedModal/hooks';
import { BlockModalTypes } from '../../../BlockedModal/types';

const getMainButtonTitle = ({
    item,
    t,
    shouldGoToPlayer,
    buttonTitle,
}: Pick<MainButtonProps, 'item' | 'buttonTitle'> & {
    t: TFunction;
    shouldGoToPlayer: boolean;
}) => {
    if (buttonTitle) return buttonTitle;

    if (item.type === ASSET_TYPE.CHANNEL && !shouldGoToPlayer) {
        return t('player.purchaseNeededButton');
    }

    if (item.type === ASSET_TYPE.LIVE_EVENT || item.type === ASSET_TYPE.CHANNEL || item.isLive) {
        return t('asset.playback.watchLiveButton');
    }

    if (getIsPodcastSeries(item) || getIsPodcastEpisode(item)) {
        return t('asset.playback.playPodcastButton');
    }

    return t('asset.playback.playButton');
};

const getMainButtonOnPress = ({
    onPlayPress,
    onInfoPress,
    shouldGoToPlayer,
    isGeoBlocked,
    openBlockedModal,
    t,
}: Pick<MainButtonProps, 'onPlayPress' | 'onInfoPress' | 'isGeoBlocked'> & {
    shouldGoToPlayer: boolean;
    openBlockedModal: ReturnType<typeof useBlockedModal>['openBlockedModal'];
    t: TFunction;
}) => {
    if (isGeoBlocked)
        return () => {
            openBlockedModal(BlockModalTypes.GEOBLOCK, t('error.E05.body') as string);
        };

    return shouldGoToPlayer ? onPlayPress : onInfoPress;
};

const getMainButtonIcon = ({
    isGeoBlocked,
    shouldGoToPlayer,
}: Pick<MainButtonProps, 'isGeoBlocked'> & {
    shouldGoToPlayer: boolean;
}): { iconName: ThemeIconName | undefined; iconSize: number | undefined } => {
    const iconSize = isPlatformWeb ? 30 : 28;

    if (isGeoBlocked) return { iconName: 'onButtonPrimary-geoblocked', iconSize };

    if (!shouldGoToPlayer) return { iconName: undefined, iconSize: undefined };

    return { iconName: 'onButtonPrimary-play', iconSize };
};

export const getMainButtonData = ({
    item,
    t,
    shouldGoToPlayer,
    onInfoPress,
    onPlayPress,
    isGeoBlocked,
    openBlockedModal,
    buttonTitle,
}: Pick<
    MainButtonProps,
    'item' | 'onPlayPress' | 'onInfoPress' | 'isGeoBlocked' | 'buttonTitle'
> & {
    t: TFunction;
    shouldGoToPlayer: boolean;
    openBlockedModal: ReturnType<typeof useBlockedModal>['openBlockedModal'];
}) => {
    const onPress = getMainButtonOnPress({
        shouldGoToPlayer,
        onInfoPress,
        onPlayPress,
        isGeoBlocked,
        openBlockedModal,
        t,
    });
    const icon = getMainButtonIcon({ isGeoBlocked, shouldGoToPlayer });

    return {
        title: isGeoBlocked
            ? t('asset.playback.unavailableButton')
            : getMainButtonTitle({ item, shouldGoToPlayer, t, buttonTitle }),
        onPress,
        icon,
    };
};
