import React, { useState, createContext, useEffect, useContext, ReactNode, FC } from 'react';

interface MetadataContextProps {
    mediaMetadata: any[];
    setMediaMetadata: React.Dispatch<React.SetStateAction<any[]>>;
}

const MetadataContext = createContext<MetadataContextProps | undefined>(undefined);

interface MetadataProviderProps {
    children: ReactNode;
}

const MetadataProvider: FC<MetadataProviderProps> = ({ children }) => {
    const [mediaMetadata, setMediaMetadata] = useState<any[]>([]);

    useEffect(() => {
        if (!mediaMetadata) {
            setMediaMetadata([]);
        }
    }, [mediaMetadata]);

    const dataContextValue = {
        mediaMetadata,
        setMediaMetadata,
    };

    return <MetadataContext.Provider value={dataContextValue}>{children}</MetadataContext.Provider>;
};

MetadataProvider.displayName = 'MetadataContext.Provider';

const useMetadata = (): MetadataContextProps => {
    const context = useContext(MetadataContext);
    if (!context) {
        throw new Error('useMetadata must be used within a MetadataProvider');
    }
    return context;
};

const withMetadata =
    <P extends object>(Component: React.ComponentType<P>) =>
    (props: P) => {
        function WithMetadata(WrappedComponent: React.ComponentType<P>) {
            return (
                <MetadataContext.Consumer>
                    {(metadataContext) => {
                        if (!metadataContext) {
                            throw new Error('withMetadata must be used within a MetadataProvider');
                        }
                        return <WrappedComponent {...props} {...metadataContext} />;
                    }}
                </MetadataContext.Consumer>
            );
        }

        WithMetadata.displayName = `WithMetadata(${Component.displayName || Component.name})`;

        return WithMetadata(Component);
    };

export { MetadataContext, MetadataProvider, useMetadata, withMetadata };
