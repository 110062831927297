import React, { FC } from 'react';
import { View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';

import { useShouldShowTopNavbar } from '../../../hooks/useShouldShowTopNavbar';
import { getTopGapHeight } from './utils';

// Component for screens that need a dynamic top margin which takes into account the Telegram insets

type TopGapProps = {
    backgroundColor?: Theme['color'];
};

export const TopGap: FC<TopGapProps> = ({ backgroundColor }) => {
    const { shouldShowTopNavbar } = useShouldShowTopNavbar();
    const { theme } = useTheme();
    const background = backgroundColor ?? theme.color.background1;
    const height = getTopGapHeight(shouldShowTopNavbar);

    return (
        <View
            style={{
                width: '100%',
                height,
                backgroundColor: background,
            }}
        />
    );
};
