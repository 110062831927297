/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactElement, SyntheticEvent, useEffect, useState, memo, useRef } from 'react';
import StyleSheet from 'react-native-media-query';
import Img from 'next/image';
import { ViewStyle } from 'react-native';
import { ThemeIcon, View } from '@24i/nxg-sdk-quarks';
import { Theme } from '@24i/nxg-sdk-photon/src';
import { useTheme } from '@24i/nxg-sdk-higgs/src';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import FallbackImage from './components/FallbackImage';
import { parseImgUrl } from './helpers';
import { ImageWithFallBackPropsWeb, Source } from './types';

const isRetina = () => {
    if (typeof window === 'undefined') return false;
    const mediaQuery =
        '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)';
    if (window.devicePixelRatio > 1) return true;
    if (window.matchMedia && window.matchMedia(mediaQuery).matches) return true;
    return false;
};

const retinaWidthMultiplier = isRetina() ? 0.5 : 1;

const ImageWithFallback = React.forwardRef<Element, ImageWithFallBackPropsWeb>(
    (props, _): ReactElement => {
        const {
            style,
            alt,
            onPress,
            onClick,
            source,
            resizeMode,
            pointerEvents,
            priority,
            children,
            fallbackImage = (_theme: Theme) => (
                <ThemeIcon iconName="onPrimary-image" size={40} iconStyles={{ opacity: 0.2 }} />
            ),
            hasGradientAlready = true,
            hasFallbackGradient = true,
            noFallback = false,
            draggable,
            onMouseEnter,
            testID,
        } = props;

        const { src: imageSource, width, height, quality, imageLoader } = parseImgUrl(source);
        const [error, setError] = useState(false);
        const [loading, setLoading] = useState(true);
        const { theme } = useTheme();
        const elementRef = useRef(null);
        const [newWidth, setNewWidth] = useState(null);
        const [newHeight, setNewHeight] = useState(null);
        const newWidthRetina = newWidth ? Number(newWidth) * retinaWidthMultiplier : undefined;
        const sizes = `(min-width: ${newWidthRetina}px) ${newWidthRetina}px, 100vw`;

        const { ids, styles } = StyleSheet.process([
            {
                overflow: 'hidden',
                zIndex: 0,
                height: '100%',
                width: '100%',
                // if position of the child is absolute this has to be explicitely relative
                // for child to be positioned properly
                position: 'relative',
            },
            pointerEvents === 'none' && { pointerEvents: 'none' },
            style,
            // use the loading background when the loading condition is met or when the fallback image condition is met
            loading && { backgroundColor: theme?.color?.darker1 || 'rgba(31, 31, 31, 1)' },
        ]);

        const nextImageWrapper: ViewStyle = {
            position: 'relative',
            width: newWidth || undefined,
            height: newHeight || undefined,
        };

        useEffect(() => {
            // evaluate all the other conditions to not display the loading state
            // except the loading of the image
            if (children || ((error || !imageSource) && !noFallback)) setLoading(false);
        }, [error, imageSource, noFallback]);

        const renderDefaultImage = (): ReactElement =>
            hasFallbackGradient ? (
                <FallbackImage
                    fallbackImage={fallbackImage}
                    hasGradientAlready={hasGradientAlready}
                />
            ) : (
                fallbackImage(theme)
            );

        const handleLoadingComplete = (): void => {
            setLoading(false);
        };

        const handleError = (_e: SyntheticEvent<HTMLImageElement, Event>): void => {
            setError(true);
        };

        const handleClick = (e: SyntheticEvent<HTMLImageElement, Event>): void => {
            onPress?.(e);
            onClick?.(e);
        };

        const onLayout = ({
            nativeEvent: {
                layout: { width: w, height: h },
            },
        }) => {
            setNewWidth(w);
            setNewHeight(h);
        };

        return (
            <View data-media={ids} style={styles} ref={elementRef} onLayout={onLayout}>
                {newWidth && !!imageSource && !error && (
                    <View style={nextImageWrapper}>
                        <Img
                            loader={imageLoader}
                            objectFit={resizeMode || 'cover'}
                            src={imageSource}
                            onLoadingComplete={handleLoadingComplete}
                            alt={alt || ''}
                            sizes={imageLoader ? sizes : undefined}
                            layout="fill"
                            unoptimized={!imageLoader}
                            loading={priority ? undefined : 'lazy'}
                            priority={priority}
                            quality={quality ?? undefined}
                            onError={handleError}
                            onClick={handleClick}
                            draggable={draggable}
                            onMouseEnter={onMouseEnter}
                            // @ts-expect-error
                            testid={testID}
                        />
                    </View>
                )}
                {children}
                {(error || !imageSource) && !noFallback && renderDefaultImage()}
            </View>
        );
    }
);
ImageWithFallback.displayName = 'ImageWithFallback';

const MemoizedImage = overridable(memo(ImageWithFallback), 'ImageWithFallback');
export default MemoizedImage;
MemoizedImage.displayName = 'ImageWithFallback';

export function preloadImage(_sources: Source[]) {
    // not possible on web
}
