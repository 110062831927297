import { useEffect, useMemo, useState } from 'react';
import { isFactorMobile } from 'renative';
import {
    Asset,
    Broadcast,
    Favorite,
    isSeries,
    ASSET_TYPE,
    getIsPodcastSeries,
    getIsPodcastEpisode,
    PRODUCT_SCREENS,
} from '@24i/nxg-sdk-photon/src';
import { ErrorCodes } from '@24i/nxg-sdk-smartott/src/utils/errorCodesMapper/types';
import { log } from '@24i/nxg-core-utils/src/logger';
import prepareAutoPlay from '@24i/nxg-sdk-smartott/src/utils/prepareAutoPlay';
import { useIsMutating } from 'react-query';
import { useRemindersDataClient } from '@24i/nxg-sdk-smartott-shared/src/context/Reminders';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useAssetActions } from '@24i/nxg-sdk-smartott';
import { showToast } from '@24i/nxg-sdk-gluons/src/components/ui/Toast';
import {
    useRecordingAssetQuery,
    useCreateRecordingForBroadcast,
    useDeleteRecordingForBroadcast,
    useCreateSeriesRecordingForBroadcast,
    useCancelRecordingsForSeries,
} from '@24i/nxg-sdk-smartott/src/hooks/query/recordings';
import {
    useAddToFavoritesMutation,
    useDeleteFromFavoritesMutation,
} from '@24i/nxg-sdk-smartott/src/hooks/query/favorites/useFavoritesMutation';
import useMyFavoritesQuery from '@24i/nxg-sdk-smartott/src/hooks/query/favorites/useMyFavoritesQuery';
import useContinueWatchingQuery from '@24i/nxg-sdk-smartott/src/hooks/query/continueWatching/useContinueWatchingQuery';
import {
    useAssetDetailsQuery,
    useAssetRelatedQuery,
    useAssetSeasonsQuery,
    useAssetExtrasQuery,
} from '@24i/nxg-sdk-smartott/src/hooks/query/asset';
import { useTrackBuffering } from '@24i/nxg-sdk-smartott-shared/src/analytics/hooks/useTrackBuffering';
import { BlockModalTypes } from '../../../components/BlockedModal/types';
import { useStore } from '../../../context/ApplicationStore';
import { ScreenOrigin, ScreenOriginType } from '../../../navigation/types';

import { mapAssetToTrailer, mapUserSeasons } from './utils';
import {
    DetailsScreenProps,
    DETAILS_ERROR_ENUM,
    UseSharedModelDetails,
    ErrorToDiplay,
} from '../types';
import useAssetBlockersValidation from '../../../hooks/useAssetBlockersValidation';
import useBlockedModal from '../../../components/BlockedModal/hooks';
import { usePodcastPlayer } from '../../../context/PodcastPlayerProvider';

const useShared = (props: DetailsScreenProps): UseSharedModelDetails => {
    const { query = {}, recordError } = props;

    let { asset: assetDetailsFromQuery } = query;

    const {
        origin,
        id = assetDetailsFromQuery?.id ?? '',
        type = assetDetailsFromQuery?.type ?? ASSET_TYPE.CUSTOM,
        channelId = assetDetailsFromQuery?.channelId ?? '',
        sectionLabel = assetDetailsFromQuery?.sectionLabel ?? '',
        episodeToPlay,
    } = query;

    if (!assetDetailsFromQuery)
        assetDetailsFromQuery = {
            id,
            type,
            channelId,
        };

    const { userData, selectedProfile } = useStore();
    const { data: continueWatchingPlaylist, refetch: refetchContinueWatching } =
        useContinueWatchingQuery();
    const { handleBlockersCheck } = useAssetBlockersValidation({});
    const { t } = useTranslation();
    const { openBlockedModal } = useBlockedModal();
    const {
        closePlayer: closePodcastPlayer,
        startLoading: startLoadingPodcastPlayer,
        stopLoading: stopLoadingPodcastPlayer,
        getIsEpisodeInPlayer: getIsPodcastEpisodeInPlayer,
    } = usePodcastPlayer();

    // Error handling

    const [recordingError, setRecordingError] = useState<string | undefined>(undefined);
    const [errorForModal, setErrorForModal] = useState<ErrorToDiplay | null>(null);

    const handleRecordingError = (error) => {
        const errorMessage = error instanceof Error ? error.message : t('error.A00.body');
        setRecordingError(errorMessage);
    };
    const handleDetailsError = (error, errorSection?: DETAILS_ERROR_ENUM) => {
        if (
            error.error === ErrorCodes.ITEM_NOT_FOUND ||
            error.message.includes(ErrorCodes.ITEM_NOT_FOUND)
        ) {
            setErrorForModal({ title: t('error.A01.title'), message: t('error.A01.body') });
        } else {
            setErrorForModal({ title: t('error.B00.title'), message: t('error.B00.body') });
        }
        log(`Error fetching asset details: ${errorSection}`, JSON.stringify(error));
        recordError?.(JSON.stringify(error));
    };

    // Query hooks

    const {
        data: asset,
        isLoading: isDetailsLoading,
        refetch: refetchDetails,
    } = useAssetDetailsQuery(
        { assetId: id, assetType: type, continueWatchingPlaylist, channelId, sectionLabel },
        {
            onError: (err) => handleDetailsError(err, DETAILS_ERROR_ENUM.DETAILS),
            staleTime: type === ASSET_TYPE.BROADCAST ? 0 : undefined,
        }
    );
    useTrackBuffering({
        asset: {
            ...asset,
            id,
        },
        screen: PRODUCT_SCREENS.DETAILS,
        origin,
    });

    const originFromHere = useMemo<ScreenOrigin>(
        () => ({
            type: type as unknown as ScreenOriginType,
            id,
            label: asset?.title ?? `Detail ${type} ${id}`,
        }),
        [asset]
    );

    const { startPlayback, getEpisodeToWatch, fetchEpisodeToReproduce } = useAssetActions({
        origin: originFromHere,
    });

    const { data: extras, isLoading: isExtrasLoading } = useAssetExtrasQuery(asset, {
        onError: (err) => handleDetailsError(err, DETAILS_ERROR_ENUM.EXTRAS),
        enabled: !_.isEmpty(asset) && type !== ASSET_TYPE.BROADCAST,
    });

    const { data: related, isLoading: isRelatedLoading } = useAssetRelatedQuery(
        { assetId: id, assetType: type },
        {
            onError: (err) => handleDetailsError(err, DETAILS_ERROR_ENUM.RELATED),
            enabled: !_.isEmpty(asset) && type !== ASSET_TYPE.BROADCAST,
        }
    );

    const { data: seasons, isLoading: isSeasonsLoading } = useAssetSeasonsQuery(id, {
        onError: (err) => handleDetailsError(err, DETAILS_ERROR_ENUM.SEASONS),
        enabled:
            (!_.isEmpty(asset) && type === ASSET_TYPE.SERIES) || type === ASSET_TYPE.PODCAST_SERIES,
        select: (data) => mapUserSeasons(data, continueWatchingPlaylist, userData),
    });

    const orderedSeasons = useMemo(() => {
        if (!asset || !asset?.type) return [];
        if (!seasons) return [];
        const sortSeasons =
            asset?.type === ASSET_TYPE.PODCAST_SERIES
                ? (a, b) => b.seasonNumber - a.seasonNumber
                : (a, b) => a.seasonNumber - b.seasonNumber;
        const sortEpisodes =
            asset?.type === ASSET_TYPE.PODCAST_SERIES
                ? (a, b) => b.episodeNumber - a.episodeNumber
                : (a, b) => a.episodeNumber - b.episodeNumber;
        return [...seasons].sort(sortSeasons).map((season) => {
            return {
                ...season,
                episodes: [...season.episodes].sort(sortEpisodes),
            };
        });
    }, [seasons, asset?.type]);

    const mutationLoading = useIsMutating();
    const createRecordingForBroadcast = useCreateRecordingForBroadcast(handleRecordingError);
    const deleteRecordingForBroadcast = useDeleteRecordingForBroadcast({
        handleError: handleRecordingError,
        successToast: null,
    });
    const createSeriesRecordingForBroadcast =
        useCreateSeriesRecordingForBroadcast(handleRecordingError);
    const cancelRecordingsForSeries = useCancelRecordingsForSeries(handleRecordingError);
    const broadcastAsset = (asset || assetDetailsFromQuery) as Broadcast;
    const { data, isLoading: recordingAssetLoading } = useRecordingAssetQuery(broadcastAsset, {
        onError: (err) => handleRecordingError(err),
        enabled: !_.isEmpty(asset) && !!userData,
    });
    const {
        data: userFavorites,
        isFetching: favoritesFetching,
        isLoading: favoritesLoading,
        refetch: refetchUserFavorites,
    } = useMyFavoritesQuery({
        enabled: !_.isEmpty(asset),
    });
    const { mutate: deleteFromFavorites } = useDeleteFromFavoritesMutation();
    const { mutate: addToFavorites } = useAddToFavoritesMutation();

    const favoriteAsset = useMemo(
        () => userFavorites?.find((userFavorite: Favorite) => userFavorite.entityId === id),
        [userFavorites, id]
    );

    // States

    const [isAddedToMyList, setIsAddedToMyList] = useState(!!favoriteAsset);
    const [reminderIsSet, setReminderIsSet] = useState(false);
    const [fetchingReminder, setFetchingReminder] = useState(true);
    const toggleIsAddedToMyList = () => setIsAddedToMyList((prevState) => !prevState);
    const { remindersDataClient } = useRemindersDataClient();

    // Constants

    const isFavoritesLoading = favoritesFetching || favoritesLoading;

    const isLoading = isDetailsLoading || isExtrasLoading || isRelatedLoading || isSeasonsLoading;

    // Functions

    const onMyListPress = async (): Promise<void> => {
        try {
            if (userData) {
                if (!isFavoritesLoading) {
                    if (favoriteAsset) {
                        deleteFromFavorites(favoriteAsset.id);
                        setIsAddedToMyList(false);
                    } else if (type && id) {
                        addToFavorites({ entityType: type, entityId: id });
                        setIsAddedToMyList(true);
                    }
                }
            }
        } catch (err) {
            // If there was an error when adding or removing item, it toggles `My list` button value back
            toggleIsAddedToMyList();
            log('Error adding or removing item from user favorite list', err);
        }
    };

    const handlePurchaseAsset = (
        selectedAsset: Asset,
        openPurchaseModal: (asset: Asset) => void
    ) => {
        if (!userData) {
            openBlockedModal(BlockModalTypes.ACCESS);
            return;
        }

        let episode;
        if (selectedAsset.type === ASSET_TYPE.SERIES && !selectedAsset.isTrailer) {
            episode = getEpisodeToWatch(seasons || []);
        }

        openPurchaseModal(episode ?? selectedAsset);
    };

    const handleOnPlayPress = async ({
        streamAsset,
        isStartOver = false,
        onPurchaseAsset,
    }: {
        streamAsset: Asset;
        isStartOver?: boolean;
        onPurchaseAsset?: (assetToPurchase: Asset) => void;
    }) => {
        await prepareAutoPlay();

        const isPodcastSeries = getIsPodcastSeries(streamAsset);
        const isPodcastEpisode = getIsPodcastEpisode(streamAsset);
        const shouldFollowPodcastFlow = isPodcastSeries || isPodcastEpisode;

        let assetToCheck: Asset | undefined = streamAsset;
        if (isPodcastSeries) {
            assetToCheck = await fetchEpisodeToReproduce(streamAsset, 'latest');
        }
        if (streamAsset.type === ASSET_TYPE.SERIES && seasons) {
            assetToCheck = getEpisodeToWatch(seasons);
        }

        if (shouldFollowPodcastFlow) {
            if (assetToCheck && getIsPodcastEpisodeInPlayer(assetToCheck.id)) {
                return;
            }

            closePodcastPlayer();
            startLoadingPodcastPlayer();
        }

        const { hasBlocker } = await handleBlockersCheck({
            asset: assetToCheck ?? streamAsset,
            handlePurchase: () => onPurchaseAsset?.(assetToCheck ?? streamAsset),
        });

        if (hasBlocker) {
            if (shouldFollowPodcastFlow) closePodcastPlayer();
            return;
        }

        if (shouldFollowPodcastFlow) stopLoadingPodcastPlayer();
        startPlayback(streamAsset, isStartOver);
    };

    const logReminderError = (error: unknown): void => {
        if (error instanceof Error) {
            log('Error setting reminder', error);
        }
        showToast(t('error.B00.title'));
    };

    const fetchReminder = async (): Promise<void> => {
        if (selectedProfile && id) {
            setFetchingReminder(true);
            const { id: profileId } = selectedProfile;
            try {
                const reminder = await remindersDataClient.getReminderForBroadcast(id, profileId);
                if (reminder) {
                    setReminderIsSet(true);
                } else {
                    setReminderIsSet(false);
                }
                setFetchingReminder(false);
            } catch (error: unknown) {
                logReminderError(error);
            }
        }
    };

    const refetchData = () => {
        refetchDetails();
        if (userData) refetchContinueWatching();
    };

    const extraItems = useMemo(() => {
        const mappedExtras = extras?.map((extraAsset) => mapAssetToTrailer(extraAsset, t));
        if (
            isSeries(asset) &&
            mappedExtras &&
            seasons &&
            seasons.length > 0 &&
            seasons[0].name === 'Extra'
        ) {
            return [
                ...mappedExtras,
                // TODO: Temporary limit items to 3 because it causes performance issues on some mobile devices.
                // Figure out what would be a better solution (maybe `see all` feature)
                // P.S. this can be overridden via `renderPackshotGrid` prop if some projects wouldn't need to have this item limit
                ...seasons[0].episodes.filter((_i, index) => !isFactorMobile || index < 3),
            ];
        }
        return mappedExtras;
    }, [extras, asset, seasons]);

    // Use Effects

    useEffect(() => {
        if (selectedProfile?.id) refetchUserFavorites();
    }, [selectedProfile]);

    useEffect(() => {
        if (!isFavoritesLoading) setIsAddedToMyList(!!favoriteAsset);
    }, [favoriteAsset, isFavoritesLoading]);

    useEffect(() => {
        refetchDetails();
    }, [continueWatchingPlaylist]);

    const recordingState = useMemo(() => {
        return {
            status: data?.status,
            error: recordingError,
            loading: recordingAssetLoading || Boolean(mutationLoading),
            seriesId: data?.seriesId,
        };
    }, [data?.status, recordingError, recordingAssetLoading, mutationLoading, data?.seriesId]);
    return {
        isAddedToMyList,
        reminderIsSet,
        fetchingReminder,
        isLoading,
        originFromHere,
        asset,
        seasons: orderedSeasons,
        related,
        extraItems,
        errorForModal,
        recordingState,
        refetchData,
        fetchReminder,
        onMyListPress,
        setReminderIsSet,
        logReminderError,
        handleOnPlayPress,
        onSetSingleRecordingPress: () => createRecordingForBroadcast(broadcastAsset),
        onRemoveSingleRecordingPress: () => deleteRecordingForBroadcast(broadcastAsset),
        onSetMultipleRecordingPress: () => createSeriesRecordingForBroadcast(broadcastAsset),
        onRemoveMultipleRecordingPress: () => cancelRecordingsForSeries(broadcastAsset),
        handlePurchaseAsset,
        episodeToPlay,
    };
};

export { useShared };
