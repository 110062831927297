/* eslint-disable max-len */
import { SwiperSlide } from '@24i/nxg-sdk-gluons/src/components/containers/SingleRow/components/SingleRowSwiperSlide';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View } from '@24i/nxg-sdk-quarks';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import { Asset, ASSET_TYPE } from '@24i/nxg-sdk-photon';
import { Breakpoint } from '@24i/nxg-sdk-gluons/src/utils/styles/constants';
import React from 'react';
import SwiperCore, { Autoplay, Keyboard, Mousewheel, Navigation, Pagination } from 'swiper';
import { Swiper } from 'swiper/react';
import Item from '../components/Item';
import getSliderStyles from '../styles';
import { getSliderTestId, SLIDER_TEST_IDS } from '../test-utils';
import { SliderWebComponentProps } from '../types';

SwiperCore.use([Mousewheel, Navigation, Pagination, Keyboard, Autoplay]);

const Slider = (props: SliderWebComponentProps) => {
    const {
        styles: getStyles = getSliderStyles,
        items: unlimitedItems = [],
        additionalItems,
        itemLimit,
        onItemPress,
        swiperProps,
        index: sliderIndex,
        showMetadata,
    } = props;

    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { orientation } = useDimensions();

    const generateSlides = () => {
        const items = unlimitedItems?.slice(0, itemLimit) || [];
        if (additionalItems) {
            additionalItems
                .slice()
                .reverse()
                .forEach(
                    (
                        { id, textPlacement, position, images, target, title, description, type },
                        i
                    ) => {
                        const item: Asset = {
                            id: id || `${i}`,
                            title,
                            description,
                            background: images?.[0].url,
                            textPlacement,
                            type: type as ASSET_TYPE,
                            target,
                            images: images && { still: [images?.[0]] },
                        };
                        if (position === 'start') items.unshift(item);
                        if (position === 'end') items.push(item);
                    }
                );
        }

        return items.map((item, index) => (
            <SwiperSlide
                // @ts-ignore
                testid={getSliderTestId(SLIDER_TEST_IDS.CONTAINER, {
                    index,
                    scopeIndex: sliderIndex,
                })}
                key={item?.id || index}
                style={{
                    width: '65%',
                    [`@media (min-width: ${Breakpoint.XL + 1}px) and (max-width: ${
                        Breakpoint.XXL
                    }px)`]: {
                        width: '65%',
                    },
                    [`@media (min-width: ${Breakpoint.MD + 1}px) and (max-width: ${
                        Breakpoint.XXL
                    }px)`]: {
                        width: '71%',
                    },
                    [`@media (min-width: ${Breakpoint.SM + 1}px) and (max-width: ${
                        Breakpoint.MD
                    }px)`]: {
                        width: '77%',
                    },
                    [`@media (max-width: ${Breakpoint.SM}px)`]: {
                        width: '90%',
                    },
                }}
            >
                <Item
                    index={index}
                    sliderIndex={sliderIndex}
                    key={item.id}
                    data={item}
                    onPress={() => onItemPress(item)}
                    styles={styles}
                    showMetadata={showMetadata}
                />
            </SwiperSlide>
        ));
    };

    const items = generateSlides();
    return (
        <View
            style={styles.slideHolder}
            testID={getSliderTestId(SLIDER_TEST_IDS.CONTAINER, { scopeIndex: sliderIndex })}
        >
            <div className="slider-container">
                <style jsx>{`
                    :global(.swiper-container) {
                        padding-left: calc(4% - 10px) !important;
                    }
                    .slider-swiper-button.swiper-button-prev.swiper-button-disabled,
                    .slider-swiper-button.swiper-button-next.swiper-button-disabled,
                    .slider-swiper-button {
                        top: 12px !important;
                        display: none !important;
                    }
                    :global(.slider-container:hover .slider-swiper-button) {
                        display: flex !important;
                        height: calc(100% - 12px);
                        width: 48px;
                    }
                    .swiper-button-next {
                        right: 0;
                        opacity: 0.6;
                        background: linear-gradient(
                            to right,
                            rgba(0, 0, 0, 0.2),
                            rgba(0, 0, 0, 0.7) 10%,
                            rgba(0, 0, 0, 0.8) 100%,
                            rgba(0, 0, 0, 0.2)
                        );
                    }
                    .swiper-button-prev {
                        left: 0;
                        opacity: 0.6;
                        background: linear-gradient(
                            to left,
                            rgba(0, 0, 0, 0.2),
                            rgba(0, 0, 0, 0.7) 10%,
                            rgba(0, 0, 0, 0.8) 100%,
                            rgba(0, 0, 0, 0.2)
                        );
                    }
                    .swiper-button-next:hover,
                    .swiper-button-prev:hover {
                        opacity: 1;
                        tansition: opacity 0.1s easy-out 0s;
                    }
                    .swiper-button-next::after,
                    .swiper-button-prev::after {
                        font-size: 32px !important;
                    }
                    .swiper-button-next:hover.swiper-button-next::after,
                    .swiper-button-prev:hover.swiper-button-prev::after {
                        font-size: 36px !important;
                    }

                    @media (max-width: 800px) {
                        :global(.swiper-container) {
                            padding-left: 14px !important;
                        }
                    }
                    @media (min-width: 801px) and (max-width: 1200px) {
                        :global(.swiper-container) {
                            padding-left: 24px !important;
                        }
                    }
                    @media (min-width: 1201px) and (max-width: 1600px) {
                        :global(.swiper-container) {
                            padding-left: 38px !important;
                        }
                    }
                `}</style>
                <Swiper
                    key={orientation}
                    slidesPerView="auto"
                    preloadImages={false}
                    lazy
                    grabCursor
                    threshold={15}
                    mousewheel={{
                        forceToAxis: true,
                        sensitivity: 0.7,
                        thresholdDelta: 40,
                    }}
                    loop
                    slidesOffsetAfter={32}
                    navigation={{
                        nextEl: `.slider-swiper-button.swiper-button-next`,
                        prevEl: `.slider-swiper-button.swiper-button-prev`,
                    }}
                    {...swiperProps}
                >
                    {items}
                </Swiper>
                <div
                    // @ts-ignore
                    testid={getSliderTestId(SLIDER_TEST_IDS.NEXT_BUTTON, {
                        scopeIndex: sliderIndex,
                    })}
                    style={styles.sliderNextButton}
                    className="slider-swiper-button swiper-button-next swiper-button-white swiper-button-small"
                />
                <div
                    // @ts-ignore
                    testid={getSliderTestId(SLIDER_TEST_IDS.PREV_BUTTON, {
                        scopeIndex: sliderIndex,
                    })}
                    style={styles.sliderPrevButton}
                    className="slider-swiper-button swiper-button-prev swiper-button-white swiper-button-small"
                />
            </div>
        </View>
    );
};

export { getSliderStyles };
export default overridable(Slider, 'Slider');
