import { useEffect, useMemo } from 'react';
import { isPlatformWeb } from '@24i/nxg-core-utils';
import { isWebView, expandTelegramApp, getTelegramWebApp } from '@24i/nxg-sdk-higgs/src/utils';

export const useExpandTeleGramWebApp = (condition: boolean) => {
    const TelegramApp = getTelegramWebApp();
    const shouldExpandTelegramApp = useMemo(
        () => isPlatformWeb && isWebView() && TelegramApp && condition,
        [isPlatformWeb, isWebView, TelegramApp, condition]
    );

    // Expand on launch
    useEffect(() => {
        if (!shouldExpandTelegramApp) return;

        expandTelegramApp();
    }, [shouldExpandTelegramApp]);

    // Expand on resize
    useEffect(() => {
        if (!shouldExpandTelegramApp) return;

        window.addEventListener('resize', expandTelegramApp);

        // eslint-disable-next-line consistent-return
        return () => {
            window.removeEventListener('resize', expandTelegramApp);
        };
    }, [shouldExpandTelegramApp]);
};
