import { Theme } from '@24i/nxg-sdk-photon';
import { Breakpoint } from '@24i/nxg-sdk-smartott/src/utils/styles/constants';
import { getMargins } from '@24i/nxg-sdk-higgs';

import { BannerCarouselItemStyles } from './types';

const HEIGHT = {
    L: 746,
    M: 550,
    S: '95vh',
};

const getBannerCarouselItemStyles = (
    theme: Theme,
    isMobileLandscape: boolean
): BannerCarouselItemStyles => ({
    bannerItemContainer: {
        position: 'relative',
        height: isMobileLandscape ? HEIGHT.S : HEIGHT.M,
        ...(!isMobileLandscape && {
            [`@media (min-width: ${Breakpoint.SM}px)`]: {
                height: HEIGHT.L,
            },
        }),
    },
    slideInnerContainer: {
        paddingHorizontal: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    image: {
        flex: 1,
        backgroundColor: 'transparent',
        width: '100%',
        height: isMobileLandscape ? HEIGHT.S : HEIGHT.M,
        ...(!isMobileLandscape && {
            [`@media (min-width: ${Breakpoint.SM}px)`]: {
                height: HEIGHT.L,
            },
        }),
    },
    textContainer: {
        position: 'absolute',
        height: isMobileLandscape ? HEIGHT.S : HEIGHT.L,
        justifyContent: isMobileLandscape ? 'center' : 'flex-end',
        paddingBottom: isMobileLandscape ? 20 : 280,
        zIndex: 999,
        ...(!isMobileLandscape && {
            [`@media (max-width: ${Breakpoint.SM}px)`]: {
                paddingHorizontal: 20,
            },
        }),
        ...getMargins('padding', isMobileLandscape ? '10%' : '5.5%', true),
    },
    primaryButtonContainer: {
        marginTop: 22,
    },
    primaryButtonContainerWithTitle: {
        height: 54,
        alignSelf: 'flex-start',
        width: 'auto',
    },
    topGradient: {
        height: '30%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
    },
    bottomGradient: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        left: 0,
        bottom: 0,
    },
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    itemContainer: {
        flex: 1,
        width: '100%',
        ...(isMobileLandscape
            ? { height: HEIGHT.S }
            : {
                  [`@media (min-width: ${Breakpoint.SM}px)`]: {
                      height: HEIGHT.L,
                  },
                  [`@media (max-width: ${Breakpoint.SM}px)`]: {
                      height: HEIGHT.M,
                  },
              }),

        backgroundColor: theme.color.background1,
        left: 0,
    },
    infoButtonContainer: {
        height: 54,
        paddingLeft: 24,
        alignSelf: 'flex-start',
        width: 'auto',
    },
    activityIndicator: { alignSelf: 'flex-start', marginTop: 22 },
    playButtonContainer: { flexDirection: 'row' },
    obscuredImageContainer: {
        height: '60%',
        width: '100%',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default getBannerCarouselItemStyles;
