import { View } from '@24i/nxg-sdk-quarks';
import React from 'react';
import { SubMenuScreenWebProps } from './types';
import SubMenuScreensList from './screens/SubMenuScreensList/index.web';
import { withWebHeader } from '../../navigation/components/withWebHeader';

export const Screen = ({ subMenuTitle, subMenuScreens }: SubMenuScreenWebProps) => {
    // As long as the index.tsx file exists, this should be fine
    const subMenuScreensWeb = subMenuScreens;

    return (
        <View>
            <SubMenuScreensList
                containingMenuTitle={subMenuTitle}
                subMenuScreens={subMenuScreensWeb.map((screen) => ({
                    name: screen.name,
                    menuTitle: screen.menuTitle,
                    title: screen.menuTitle,
                    onPress: screen.onPress,
                    icon: screen.icon,
                    matchesWebRoute: screen.matchesWebRoute,
                    screenTypeToUse: screen.screenTypeToUse,
                    webLink: screen.webMenuLink || { href: '/404', as: '/404' },
                    webMenuLink: screen.webMenuLink || { href: '/404', as: '/404' },
                }))}
            />
        </View>
    );
};

export const SubMenuScreen = withWebHeader(Screen);
