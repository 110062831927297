import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { useEffect } from 'react';

const loadOneTrustScript = (url: string, domainScript: string) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.setAttribute('data-domain-script', domainScript);
    document.head.appendChild(script);

    // OptanonWrapper function reserved by SDK and used after initialize
    const callback = document.createElement('script');
    callback.type = 'text/javascript';
    callback.text = `function OptanonWrapper() {
        const otConsentSdk = document.getElementById("ot-sdk-btn-floating");
        if (otConsentSdk) {
            otConsentSdk.style.display = "none";
        }
    }`;
    document.body.appendChild(callback);
};

export const useOneTrust = () => {
    const config = useFeature('oneTrust');

    useEffect(() => {
        if (!config?.enabled || !config.scriptUrl || !config.domainScript) return;
        loadOneTrustScript(config.scriptUrl, config.domainScript);
    }, [config]);
};
