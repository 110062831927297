import { Theme } from '@24i/nxg-sdk-photon/src';

export const dummyGluonTheme: Theme = {
    color: {
        textButtonSecondary: '',
        background1: 'ccdd',
    },
    icons: {
        packshot: {
            play: {
                iconFont: 'font',
                iconName: 'name',
            },
        },
    },
    elements: {},
    fonts: {},
} as unknown as Theme;

export const testableStyledComponentConfig = {
    shouldForwardProp: (prop, defaultValidatorFn) =>
        ['testID'].includes(prop) || ['testid'].includes(prop) || defaultValidatorFn(prop),
};
