import 'swiper/swiper-bundle.css';
import React, { ElementType, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SwiperCore, { Mousewheel, Navigation } from 'swiper';
import { Swiper } from 'swiper/react';

import { createTestIDGetter } from '@24i/nxg-core-utils';
import Packshot from '@24i/nxg-sdk-gluons/src/components/ui/Packshot/index.web';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { DEFAULT_BREAKPOINTS, useTheme } from '@24i/nxg-sdk-higgs';
import { ASSET_TYPE } from '@24i/nxg-sdk-photon/src/enums';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import { ActivityIndicator, View } from '@24i/nxg-sdk-quarks';

import { ElementAndPackshotPropsWeb, PackshotPropsWeb } from '../../../ui/Packshot/types';
import { SwiperSlide } from '../components/SingleRowSwiperSlide';
import Title from '../components/Title.web';
import getSingleRowStyles, { Arrow, getArrowClassName } from '../styles/index.web';
import { SINGLE_ROW_TEST_IDS } from '../test-utils';
import {
    getPackshotImage,
    getSubtitle,
    getTimeTextForAsset,
    SEE_ALL_ITEM_ID,
    transformItems,
} from '../utils';
import { SingleRowWebProps } from './types';

const defaultRenderPackshot = (
    props: ElementAndPackshotPropsWeb<ElementType, PackshotPropsWeb>
): ReactElement => <Packshot {...props} />;

const getSlidesPerGroup = (isPoster: boolean, isHighlighted: boolean) => {
    const getSlidesPerGroupVal = (
        highlightedRetVal: number,
        notHighlightedRetVal: number
    ): number => (isHighlighted ? highlightedRetVal : notHighlightedRetVal);

    return {
        0: {
            slidesPerGroup: isPoster ? getSlidesPerGroupVal(2, 3) : getSlidesPerGroupVal(1, 2),
        },
        501: {
            slidesPerGroup: isPoster ? getSlidesPerGroupVal(3, 4) : getSlidesPerGroupVal(2, 3),
        },
        801: {
            slidesPerGroup: isPoster ? getSlidesPerGroupVal(5, 6) : getSlidesPerGroupVal(3, 4),
        },
        1201: {
            slidesPerGroup: isPoster ? getSlidesPerGroupVal(6, 7) : getSlidesPerGroupVal(4, 5),
        },
    };
};

SwiperCore.use([Mousewheel, Navigation]);

const SingleRow = ({
    itemLimit = 999999,
    section,
    isHighlighted = false,
    textColor = '',
    carouselIndex = 0,
    onItemPress,
    onSeeAllItemPress,
    styles: getStyles = getSingleRowStyles,
    title,
    isGenre,
    active,
    renderPackshot = defaultRenderPackshot,
    index: rowIndex,
    isOnPackshotPressDisabled,
    testId = SINGLE_ROW_TEST_IDS.SINGLE_ROW,
    is12HourClock = false,
    fallbackImage,
    getActionIcon,
    showSeeAll = true,
    showMetadata = true,
    showOnlyHours = false,
    showDuration = false,
}: SingleRowWebProps) => {
    const { t } = useTranslation(['sott']);
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const dimensions = useDimensions();

    const { items: sectionItems } = section;

    const getSingleRowTestID = createTestIDGetter(testId);

    const calPercnt = (num, percentage) => num * (percentage / 100);

    const packshotWidth = useMemo(() => {
        const wwidth = window.innerWidth;
        // SMALL < 800px
        if (wwidth < DEFAULT_BREAKPOINTS.small.maxWidth) {
            if (isHighlighted) {
                return section.display === 'portrait'
                    ? calPercnt(wwidth, 50)
                    : calPercnt(wwidth, 100);
            }
            return section.display === 'portrait' ? calPercnt(wwidth, 33) : calPercnt(wwidth, 50);
        }
        // MEDIUM 801 < 1200
        if (wwidth <= DEFAULT_BREAKPOINTS.medium.maxWidth) {
            if (isHighlighted) {
                return section.display === 'portrait'
                    ? calPercnt(wwidth, 33)
                    : calPercnt(wwidth, 50);
            }
            return section.display === 'portrait' ? calPercnt(wwidth, 25) : calPercnt(wwidth, 33);
        }
        // LARGE 1201 < 1600
        if (wwidth <= DEFAULT_BREAKPOINTS.large.maxWidth) {
            if (isHighlighted) {
                return section.display === 'portrait'
                    ? calPercnt(wwidth, 20)
                    : calPercnt(wwidth, 33);
            }
            return section.display === 'portrait' ? calPercnt(wwidth, 16) : calPercnt(wwidth, 25);
        }
        // XL > 1600
        if (isHighlighted) {
            return section.display === 'portrait' ? calPercnt(wwidth, 16) : calPercnt(wwidth, 25);
        }
        return section.display === 'portrait' ? calPercnt(wwidth, 14) : calPercnt(wwidth, 20);
    }, [window.innerWidth]);

    const getSwiperSlideStyles = () => {
        if (section.display === 'portrait') {
            return isHighlighted
                ? styles.highlightedPosterPackshotHolder
                : styles.posterPackshotHolder;
        }

        return isHighlighted
            ? styles.highlightedLandscapePackshotHolder
            : styles.landscapePackshotHolder;
    };

    // this was always any, but now it's explicitly any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hasShowAll = showSeeAll && sectionItems && sectionItems?.length > itemLimit;
    const limitedArray: any = transformItems(section, itemLimit, hasShowAll);

    const containerStyles = styles?.[`${section?.rowId || ''}Container`] || styles.container;

    const navButtonId = `${section?.rowId || section?.id || carouselIndex}`;

    const shouldRenderArrows = useMemo(() => {
        if (limitedArray) {
            return window.innerWidth < limitedArray.length * packshotWidth;
        }
        return false;
    }, [window.innerWidth]);

    const getSlidesOffsetAfter = (): number => {
        const { width } = dimensions;
        if (width > DEFAULT_BREAKPOINTS.xl.minWidth) return 32 + width * 0.04;
        if (width > DEFAULT_BREAKPOINTS.large.minWidth) return 70;
        if (width > DEFAULT_BREAKPOINTS.medium.minWidth) return 44;
        return 22;
    };

    const getArrowDataTestId = (direction) => {
        const keyID = `${direction}_SLIDE_BUTTON`;
        return getSingleRowTestID(SINGLE_ROW_TEST_IDS[keyID], { scopeIndex: rowIndex });
    };

    const getSingleRowHeight = () => {
        if (!showMetadata) return 'calc(100%)';
        if (isGenre) return 'calc(100% - 15px)';
        return 'calc(100% - 75px)';
    };

    return (
        <View
            testID={getSingleRowTestID(SINGLE_ROW_TEST_IDS.CONTAINER, { scopeIndex: rowIndex })}
            style={containerStyles}
        >
            <style jsx>{`
                :global(.swiper-container) {
                    max-width: 100%;
                    min-height: 0;
                    min-width: 0;
                    padding-left: calc(4% - 10px) !important;
                    position: relative;
                }
                :global(.swiper-wrapper) {
                    width: initial !important;
                }
                :global(.single-row-container .single-row-${navButtonId}-swiper-button) {
                    display: flex;
                    height: ${getSingleRowHeight()};
                    width: 48px;
                }
                :global(.single-row-container:hover .single-row-${navButtonId}-swiper-button) {
                    display: flex;
                }
                .swiper-button-disabled {
                    opacity: 0 !important;
                }
                @media (hover: hover) {
                    :global(.single-row-container
                            .single-row-${navButtonId}-swiper-button.swiper-button-prev.swiper-button-disabled,
                            .single-row-container
                            .single-row-${navButtonId}-swiper-button.swiper-button-next.swiper-button-disabled,
                            .single-row-container
                            .single-row-${navButtonId}-swiper-button) {
                        display: none;
                    }
                }
                :global(.swiper-button-next, .swiper-button-prev) {
                    top: 0px;
                    opacity: 0.6;
                    background: linear-gradient(
                        to right,
                        rgba(0, 0, 0, 0.2),
                        rgba(0, 0, 0, 0.7) 10%,
                        rgba(0, 0, 0, 0.8) 100%,
                        rgba(0, 0, 0, 0.2)
                    );
                }
                :global(.swiper-button-next) {
                    right: 0px;
                }
                :global(.swiper-button-prev) {
                    left: -2px;
                }
                :global(.swiper-button-next:hover, .swiper-button-prev:hover) {
                    opacity: 1;
                }
                :global(.swiper-button-next:hover::after, .swiper-button-prev:hover::after) {
                    font-size: 36px !important;
                    transition: opacity 0.1s easy-out 0s;
                }
                :global(.swiper-button-next::after, .swiper-button-prev::after) {
                    font-size: 32px !important;
                }
                :global(.single-row-container) {
                    position: relative;
                }

                @media (max-width: 800px) {
                    :global(.swiper-container)::-webkit-scrollbar {
                        display: none;
                    }
                    :global(.swiper-container) {
                        padding-left: 14px !important;
                    }
                }
                @media (min-width: 801px) and (max-width: 1200px) {
                    :global(.swiper-container) {
                        padding-left: 24px !important;
                    }
                }
                @media (min-width: 1201px) and (max-width: 1600px) {
                    :global(.swiper-container) {
                        padding-left: 38px !important;
                    }
                }
                :global(.single-row-container .swiper-container) {
                    overflow: hidden;
                }
            `}</style>

            <Title
                onSeeAllItemPress={onSeeAllItemPress}
                section={section}
                renderShowAllButton={hasShowAll}
                styles={styles}
                title={title}
                isGenre={isGenre}
                textColor={textColor}
                getSingleRowTestID={getSingleRowTestID}
                rowIndex={rowIndex}
            />
            <div className="single-row-container">
                {sectionItems || section.additionalItems ? (
                    <>
                        {shouldRenderArrows && (
                            <>
                                <Arrow
                                    testid="swipe_left_button"
                                    data-testid={getArrowDataTestId('PREV')}
                                    className={getArrowClassName(navButtonId, 'prev')}
                                />
                                <Arrow
                                    testid="swipe_right_button"
                                    data-testid={getArrowDataTestId('NEXT')}
                                    className={getArrowClassName(navButtonId, 'next')}
                                />
                            </>
                        )}
                        <Swiper
                            direction="horizontal"
                            allowTouchMove={shouldRenderArrows}
                            threshold={15}
                            mousewheel={{
                                forceToAxis: true,
                                sensitivity: 0.8,
                                thresholdDelta: 10,
                            }}
                            slidesPerView="auto"
                            slidesOffsetAfter={getSlidesOffsetAfter()}
                            grabCursor={shouldRenderArrows}
                            breakpoints={getSlidesPerGroup(
                                section.display === 'portrait',
                                isHighlighted
                            )}
                            navigation={{
                                nextEl: `.single-row-${navButtonId}-swiper-button.swiper-button-next`,
                                prevEl: `.single-row-${navButtonId}-swiper-button.swiper-button-prev`,
                            }}
                        >
                            {limitedArray &&
                                limitedArray.map((item: any, index: number) => {
                                    let packshotItem = { ...item, sectionLabel: section.label };
                                    if (packshotItem.id === SEE_ALL_ITEM_ID) {
                                        packshotItem = {
                                            ...packshotItem,
                                            title: t('asset.showAll'),
                                            isSeeAll: true,
                                            textColor: theme.color.textSecondary,
                                            testID: getSingleRowTestID(
                                                SINGLE_ROW_TEST_IDS.SHOW_ALL_BUTTON
                                            ),
                                        };
                                    }

                                    const {
                                        type,
                                        id,
                                        testID: packshotTestID,
                                        progress,
                                        description,
                                        subtitle,
                                        channelLogo,
                                        textPlacement,
                                        isLive,
                                        assetLabel,
                                    } = packshotItem || {};

                                    const itemTitle =
                                        type === ASSET_TYPE.CHANNEL
                                            ? packshotItem.broadcastMetadata?.label
                                            : packshotItem?.title || packshotItem?.label;
                                    return (
                                        <SwiperSlide
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={`swiper-${itemTitle}-${id}-${index}`}
                                            style={getSwiperSlideStyles()}
                                        >
                                            <View>
                                                {renderPackshot(
                                                    {
                                                        item: packshotItem,
                                                        key: id,
                                                        id,
                                                        t,
                                                        isLive,
                                                        type: limitedArray?.[index]?.type,
                                                        isGenre,
                                                        isChannel: type === ASSET_TYPE.CHANNEL,
                                                        displayType: section.display ?? undefined,
                                                        action: section?.actionForAllItems,
                                                        textPlacement,
                                                        title: itemTitle,
                                                        subtitle: getSubtitle(
                                                            packshotItem,
                                                            subtitle,
                                                            t
                                                        ),
                                                        time:
                                                            type === ASSET_TYPE.BROADCAST ||
                                                            type === ASSET_TYPE.LIVE_EVENT ||
                                                            type === ASSET_TYPE.CHANNEL
                                                                ? getTimeTextForAsset(
                                                                      packshotItem,
                                                                      is12HourClock,
                                                                      showOnlyHours
                                                                  )
                                                                : undefined,
                                                        testID:
                                                            packshotTestID ||
                                                            `${testId}_asset_${index + 1}`,
                                                        sectionId: carouselIndex,
                                                        image: getPackshotImage(
                                                            packshotItem,
                                                            section
                                                        ),
                                                        onPress: (e) => {
                                                            if (packshotItem?.isSeeAll) {
                                                                onSeeAllItemPress?.(section);
                                                                return;
                                                            }

                                                            onItemPress?.(packshotItem, e);
                                                        },
                                                        isHighlighted,
                                                        progress:
                                                            progress ||
                                                            packshotItem?.continueWatchingOffset /
                                                                packshotItem?.duration,
                                                        description,
                                                        actionIcon: getActionIcon?.(
                                                            packshotItem,
                                                            section?.actionForAllItems
                                                        ),
                                                        channelLogo,
                                                        active:
                                                            active ===
                                                            packshotItem?.channelData?.[0]?.data
                                                                .channelId,
                                                        index,
                                                        sliderIndex: rowIndex,
                                                        isOnPackshotPressDisabled,
                                                        assetLabel,
                                                        fallbackImage,
                                                        showMetadata,
                                                        showDuration,
                                                        isSeeAll: packshotItem?.isSeeAll,
                                                    },
                                                    { index }
                                                )}
                                            </View>
                                        </SwiperSlide>
                                    );
                                })}
                        </Swiper>
                    </>
                ) : (
                    <ActivityIndicator
                        color={theme.color.textPrimary}
                        size="large"
                        style={styles.indicator}
                    />
                )}
            </div>
        </View>
    );
};

export { getSingleRowStyles, defaultRenderPackshot };
export default overridable(SingleRow, 'SingleRow');
