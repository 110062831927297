import { ViewStyle } from 'react-native';
import { BASE_GAP } from '@24i/nxg-core-utils/src/constants';

export type StickyBackHeaderStyles = {
    wrapper: ViewStyle;
    textContainer: ViewStyle;
};

export const styles: StickyBackHeaderStyles = {
    wrapper: {
        // @ts-ignore: used for web only
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1000,
        width: '100%',
    },
    textContainer: {
        padding: BASE_GAP,
    },
};
