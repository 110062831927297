import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isPlatformWeb } from 'renative';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import {
    onAnalytics,
    ANALYTICS_TRIGGERS,
    ANALYTICS_EVENTS,
} from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { useSessionId } from '@24i/nxg-sdk-smartott-shared/src/analytics/hooks/useSessionId';
import { GenericModalProps } from '../../components/GenericModal/types';
import useGenericModal from '../../components/GenericModal/hooks/useGenericModal';
import { getPaymentGateResultFromUrl, queryToObject } from './utils';
import { useInvalidatePurchases } from '../query/purchases/useIsAssetPurchasedQuery';

interface WebPurchaseCheckoutProps {
    disabled?: boolean;
    onSuccessPurchaseOverride?: () => void;
    onFailurePurchaseOverride?: () => void;
    onCancel?: () => void;
    onTryAgain?: () => void;
}

interface WebPurchaseCheckoutResult {
    isPaymentGateRedirect: boolean;
    resetQueryParams: () => void;
}

const useWebPurchaseCheckout = ({
    disabled,
    onSuccessPurchaseOverride,
    onFailurePurchaseOverride,
    onCancel,
    onTryAgain,
}: WebPurchaseCheckoutProps): WebPurchaseCheckoutResult => {
    const { t } = useTranslation(['sott']);
    const { openGenericModal, closeGenericModal } = useGenericModal();
    const router = useRouter();
    const { sessionId } = useSessionId();
    const { invalidatePurchasesQuery } = useInvalidatePurchases();

    const [isPaymentGateRedirect, setIsPaymentGateRedirect] = useState(false);

    const resetQueryParams = () => {
        const params = queryToObject(window.location.search);

        // Deleting result param inserted by payment gate
        delete params?.result;
        // Deleting purchased param inserted in redirectUri from payment gate
        delete params?.purchased;

        router.replace({
            pathname: window.location.pathname,
            query: params,
        });
    };

    const onModalClose = () => {
        resetQueryParams();
        closeGenericModal();
    };

    const openModal = ({ isSuccessPayment = true }) => {
        const modalProps: GenericModalProps = {
            requiresModalWrapper: false,
            defaultHorizontalButtonLayout: true,
            title: {
                text: isSuccessPayment ? t('tvod.add.purchaseSuccess.title') : t('error.H00.title'),
            },
            description: {
                text: isSuccessPayment
                    ? t('tvod.add.purchaseSuccess.description')
                    : t('error.H00.body'),
            },
            primaryButton: {
                title: isSuccessPayment ? t('common.continue') : t('vod.add.tryAgainButton'),
                onPress: () => {
                    onModalClose();
                    if (!isSuccessPayment) onTryAgain?.();
                },
            },
            closeModal: onModalClose,
        };
        if (!isSuccessPayment) {
            if (!onTryAgain) delete modalProps.primaryButton;
            modalProps.secondaryButton = {
                title: t('common.cancel'),
                onPress: () => {
                    onModalClose();
                    onCancel?.();
                },
            };
        }
        openGenericModal(modalProps);
    };

    const onSuccessPurchase = () => {
        if (onSuccessPurchaseOverride) {
            onSuccessPurchaseOverride();
            return;
        }

        openModal({ isSuccessPayment: true });
        onAnalytics(ANALYTICS_TRIGGERS.PURCHASE_COMPLETE, {
            event: ANALYTICS_EVENTS.PURCHASE_COMPLETE,
        });
        onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, {
            screen: t('analytics.documentTitle.purchase.step3'),
            sessionId,
        });
        invalidatePurchasesQuery();
    };

    const onFailurePurchase = () => {
        onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, {
            screen: t('analytics.documentTitle.purchase.paymentFailure'),
            sessionId,
        });
        if (onFailurePurchaseOverride) {
            onFailurePurchaseOverride();
            return;
        }
        openModal({ isSuccessPayment: false });
    };

    useEffect(() => {
        if (!isPlatformWeb || disabled) return;

        const paymentGateResult = getPaymentGateResultFromUrl(router.asPath);

        if (paymentGateResult) {
            setIsPaymentGateRedirect(true);
            if (paymentGateResult.purchased) onSuccessPurchase();
            else onFailurePurchase();
        } else setIsPaymentGateRedirect(false);
    }, []);

    return {
        isPaymentGateRedirect,
        resetQueryParams,
    };
};

export default useWebPurchaseCheckout;
