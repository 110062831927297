import { Theme } from '@24i/nxg-sdk-photon';
import { getBorderRadius, getFont } from '@24i/nxg-sdk-higgs';
import { Breakpoint } from '@24i/nxg-sdk-smartott/src/utils/styles/constants';
import { GenericModalStyles } from './types';

const getGenericModalStyles = (theme: Theme): GenericModalStyles => ({
    contentContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    webContentContainer: {
        width: 528,
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            width: 'auto',
            marginHorizontal: 24,
        },
        padding: 24,
        height: 'auto',
        backgroundColor: theme?.color?.primary1,
        borderRadius: 8,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            width: '90%',
        },
    },
    titleText: {
        marginBottom: 24,
        color: theme.color?.textPrimary,
        ...getFont(theme, 'h3'),
    },
    descriptionText: {
        color: theme.color?.textPrimary,
        marginBottom: 32,
        opacity: 0.6,
        ...getFont(theme, 'body2'),
    },
    linkText: {
        color: theme.color?.textPrimary,
        marginBottom: 32,
        opacity: 1,
        ...getFont(theme, 'body2'),
    },
    primaryButton: {
        width: 'auto',
        height: 52,
        marginLeft: 16,
        alignSelf: 'flex-end',
    },
    secondaryButton: {
        width: 'auto',
        height: 52,
        marginLeft: 16,
        alignSelf: 'flex-end',
    },
    tertiaryButton: {
        width: 'auto',
        height: 52,
        marginLeft: 16,
        alignSelf: 'flex-end',
    },
});

export default getGenericModalStyles;
