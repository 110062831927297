var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.default=void 0;var _regenerator=_interopRequireDefault(require("@babel/runtime/regenerator"));var _defineProperty2=_interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));var _slicedToArray2=_interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));var _extends2=_interopRequireDefault(require("@babel/runtime/helpers/extends"));var _objectWithoutProperties2=_interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));


var _react=_interopRequireWildcard(require("react"));var _AppState=_interopRequireDefault(require("react-native-web/dist/exports/AppState"));var _ActivityIndicator=_interopRequireDefault(require("react-native-web/dist/exports/ActivityIndicator"));var _Platform=_interopRequireDefault(require("react-native-web/dist/exports/Platform"));

var _browser=require("@sentry/browser");
var _nxgSdkSmartott=require("@24i/nxg-sdk-smartott");




var _AppStartContext=require("@24i/nxg-sdk-smartott/src/context/AppStartContext");
var _DefaultDataProviders=require("@24i/nxg-sdk-smartott-shared/src/context/DefaultDataProviders");
var _DefaultSDKProviders=require("@24i/nxg-sdk-smartott/src/context/DefaultSDKProviders");
var _DefaultClientStubsProvider=require("@24i/nxg-sdk-smartott/src/context/DefaultClientStubsProvider");
var _useStorageLanguageSync=require("@24i/nxg-sdk-smartott/src/hooks/cleeng/useStorageLanguageSync");
var _UserData=require("@24i/nxg-sdk-smartott-shared/src/context/UserData");


var _Query=require("@24i/nxg-sdk-smartott-shared/src/context/Query");

var _logger=require("@24i/nxg-core-utils/src/logger");
var _nxgSdkQuantum=require("@24i/nxg-sdk-quantum");
var _constants=require("@24i/nxg-core-utils/src/constants");
var _renative=require("renative");
var _src=require("@24i/adobe-primetime/src");
var _Store=require("./context/Store");
var _utils=require("./utils");
var _config=_interopRequireWildcard(require("./config"));

var _renativeRuntime=_interopRequireDefault(require("../../platformAssets/renative.runtime.json"));
var _defaultTheme=_interopRequireDefault(require("../../platformAssets/runtime/defaultTheme.json"));var _excluded=["clients"];var _this=this,_jsxFileName="/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/src/experience/app.web.js";function _getRequireWildcardCache(e){if("function"!=typeof WeakMap)return null;var r=new WeakMap(),t=new WeakMap();return(_getRequireWildcardCache=function _getRequireWildcardCache(e){return e?t:r;})(e);}function _interopRequireWildcard(e,r){if(!r&&e&&e.__esModule)return e;if(null===e||"object"!=typeof e&&"function"!=typeof e)return{default:e};var t=_getRequireWildcardCache(r);if(t&&t.has(e))return t.get(e);var n={__proto__:null},a=Object.defineProperty&&Object.getOwnPropertyDescriptor;for(var u in e)if("default"!==u&&{}.hasOwnProperty.call(e,u)){var i=a?Object.getOwnPropertyDescriptor(e,u):null;i&&(i.get||i.set)?Object.defineProperty(n,u,i):n[u]=e[u];}return n.default=e,t&&t.set(e,n),n;}function ownKeys(e,r){var t=Object.keys(e);if(Object.getOwnPropertySymbols){var o=Object.getOwnPropertySymbols(e);r&&(o=o.filter(function(r){return Object.getOwnPropertyDescriptor(e,r).enumerable;})),t.push.apply(t,o);}return t;}function _objectSpread(e){for(var r=1;r<arguments.length;r++){var t=null!=arguments[r]?arguments[r]:{};r%2?ownKeys(Object(t),!0).forEach(function(r){(0,_defineProperty2.default)(e,r,t[r]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(e,Object.getOwnPropertyDescriptors(t)):ownKeys(Object(t)).forEach(function(r){Object.defineProperty(e,r,Object.getOwnPropertyDescriptor(t,r));});}return e;}

var runtimeConfig=_config.default.runtimeConfig,staticSplashConfig=_config.default.staticSplashConfig;

var AppModule=function AppModule(_ref){var clients=_ref.clients,props=(0,_objectWithoutProperties2.default)(_ref,_excluded);
var userDataClient=(0,_UserData.useUserData)();
var _useStore=(0,_nxgSdkSmartott.useStore)(),setBackstageUserData=_useStore.setBackstageUserData;
var currentAppState=(0,_react.useRef)(_AppState.default.currentState);
var setAppState=function setAppState(value){
currentAppState.current=value;
};

var analyticsDataClient=clients.analyticsDataClient;

var _useAppStart=(0,_AppStartContext.useAppStart)(),initApplication=_useAppStart.initApplication;


var backstageAnalyticsLog=function backstageAnalyticsLog(eventName,token){
try{
analyticsDataClient.logAnalyticsEventToBackstage(
eventName,
{timestamp:Date.now()},
{key:token}
);
}catch(err){
(0,_logger.log)(err);
}
};

var handleAppStateChange=function handleAppStateChange(nextAppState){
if(currentAppState.current==='active'&&nextAppState.match(/inactive|background/)){
_nxgSdkQuantum.Storage.getItem(_constants.ASYNC_STORAGE_KEY_USER_TOKEN).then(function(token){
backstageAnalyticsLog('shutdown',token);
});
}
setAppState(nextAppState);
};

(0,_react.useEffect)(function(){
var setBackstageUser=function setBackstageUser(){var backstageUser;return _regenerator.default.async(function setBackstageUser$(_context){while(1)switch(_context.prev=_context.next){case 0:_context.prev=0;_context.next=3;return _regenerator.default.awrap(


userDataClient.fetchBackstageUser());case 3:backstageUser=_context.sent;
setBackstageUserData(backstageUser);_context.next=10;break;case 7:_context.prev=7;_context.t0=_context["catch"](0);

(0,_logger.log)(_context.t0);case 10:case"end":return _context.stop();}},null,null,[[0,7]],Promise);};



setBackstageUser();

var appStateListener=_AppState.default.addEventListener('change',handleAppStateChange);
return function(){
appStateListener==null?void 0:appStateListener.remove();
};
},[]);

(0,_react.useEffect)(function(){
initApplication();
},[]);


return(
_react.default.createElement(_nxgSdkSmartott.Application.Main,(0,_extends2.default)({},
props,{
staticSplashConfig:

staticSplashConfig,__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:95,columnNumber:9}})

));

};

var App=function App(props){
var _useState=(0,_react.useState)(),_useState2=(0,_slicedToArray2.default)(_useState,2),service=_useState2[0],setService=_useState2[1];
var _useState3=(0,_react.useState)(),_useState4=(0,_slicedToArray2.default)(_useState3,2),clients=_useState4[0],setClients=_useState4[1];

var initSentry=function initSentry(){
var getPlatform=function getPlatform(){
if(_Platform.default.isTVOS&&_Platform.default.OS==='ios')return'tvos';
return _Platform.default.OS;
};
try{
var sentry=_renativeRuntime.default.sentry,monitoringEnvironment=_renativeRuntime.default.monitoringEnvironment;
var enabled=Boolean((sentry==null?void 0:sentry.enabled)&&(__DEV__?sentry.debug:true));
var platform=getPlatform();
var options=sentry==null?void 0:sentry[platform];
if(enabled&&options!=null&&options.dsn&&monitoringEnvironment){
_browser.Sentry.init(_objectSpread(_objectSpread({},
options),{},{
environment:monitoringEnvironment})
);
}
}catch(err){
console.log('Sentry initialization failed: ',err);
}
};

(0,_react.useEffect)(function(){
if(service&&service.id){
var serviceParam={
serviceId:service==null?void 0:service.id,
applicationId:service==null?void 0:service.appId
};
var appClients=(0,_config.getClients)(serviceParam);
setClients(appClients);
}
},[service]);

var setSelectedService=function setSelectedService(){var selectedService;return _regenerator.default.async(function setSelectedService$(_context2){while(1)switch(_context2.prev=_context2.next){case 0:_context2.prev=0;_context2.next=3;return _regenerator.default.awrap(

_nxgSdkQuantum.Storage.getItem(_utils.ASYNC_STORAGE_KEY_SERVICE));case 3:selectedService=_context2.sent;if(!
selectedService){_context2.next=9;break;}_context2.next=7;return _regenerator.default.awrap(
setService(JSON.parse(selectedService)));case 7:_context2.next=10;break;case 9:

setService((0,_utils.getInitialApiService)());case 10:_context2.next=15;break;case 12:_context2.prev=12;_context2.t0=_context2["catch"](0);


(0,_logger.log)(_context2.t0);case 15:case"end":return _context2.stop();}},null,null,[[0,12]],Promise);};



(0,_react.useEffect)(function(){
initSentry();
setSelectedService();
},[]);

var loadingIndicator=_renative.isPlatformWeb?null:_react.default.createElement(_ActivityIndicator.default,{__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:159,columnNumber:53}});


(0,_useStorageLanguageSync.useStorageLanguageSync)();

return clients?
_react.default.createElement(_Query.QueryProvider,{__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:165,columnNumber:9}},
_react.default.createElement(_Store.ApplicationStoreProvider,{
defaultService:service,
runtimeConfig:runtimeConfig,
setService:setService,__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:166,columnNumber:13}},

_react.default.createElement(_DefaultDataProviders.DefaultDataProviders,{clients:clients,options:{defaultTheme:_defaultTheme.default},__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:171,columnNumber:17}},
_react.default.createElement(_DefaultSDKProviders.DefaultSDKProviders,{clients:clients,__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:172,columnNumber:21}},
_react.default.createElement(_DefaultClientStubsProvider.DefaultClientStubsProvider,{clients:clients,__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:173,columnNumber:25}},
_react.default.createElement(_nxgSdkSmartott.FirebaseProvider,{__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:174,columnNumber:29}},
_react.default.createElement(_src.AdobePrimetimeProvider,{__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:175,columnNumber:33}},
_react.default.createElement(AppModule,(0,_extends2.default)({},props,{clients:clients,__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:176,columnNumber:37}}))
)
)
)
)
)
)
):

loadingIndicator;

};var _default=

App;exports.default=_default;