import React, { FC } from 'react';
import { Animated } from 'react-native';

import { View, Interactable, ThemeIcon } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import useSafeAreaInsets from '@24i/nxg-sdk-gluons/src/hooks/useSafeAreaInsets';
import useDimensions from '@24i/nxg-sdk-quantum/src/Dimensions/hooks/useDimensions';
import { useSafeAreaFrame } from 'react-native-safe-area-context';

import { getStyles } from './styles';

type Props = {
    onClose: () => void;
    show?: boolean;
    variant?: 'centered';
};

export const ExpandedPlayerContainer: FC<Props> = ({ show, onClose, children, variant }) => {
    const { height } = useSafeAreaFrame();
    const { width } = useDimensions();
    const { top } = useSafeAreaInsets();
    const { theme } = useTheme();
    const styles = getStyles(theme, height ?? 0, width ?? 0, DeviceInfo.isTablet());

    return (
        <Animated.View
            style={[
                styles.container,
                variant === 'centered' && styles.centeredContainer,
                {
                    transform: [
                        {
                            translateY: show ? 0 : height,
                        },
                    ],
                },
            ]}
            pointerEvents={show ? 'auto' : 'none'}
        >
            <View
                style={{
                    ...styles.closeButton,
                    top: Math.max(parseFloat(styles.closeButton.top as any) || 0, top),
                }}
            >
                <Interactable onPress={onClose}>
                    <ThemeIcon iconName="onPrimary-collapseDown" size={24} />
                </Interactable>
            </View>

            <View style={styles.inner}>{children}</View>
        </Animated.View>
    );
};
