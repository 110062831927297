import { SOTT_DEFAULT_WEB_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { isEpisode } from '@24i/nxg-sdk-photon/src/models/episode';
import { ASSET_TYPE, Asset, BlockedItem } from '@24i/nxg-sdk-photon/src';
import { useShared } from './shared';
import { PlaybackScreenProps, PlaybackViewProps } from '../types';
import { ISeriesData } from '../managers';

const first = (input: string | string[] | undefined) => (Array.isArray(input) ? input[0] : input);

const useViewModel = (props: PlaybackScreenProps): PlaybackViewProps => {
    const router = useRouter();
    const isBingeWatchingEnabled = useFeature('binge_watching')?.enabled;

    const {
        id = '',
        type,
        channelId = '',
        isStartOver,
        editionId = '',
        isTrailer,
        sectionLabel,
    } = router.query;

    const goBack = () => {
        if (window?.history?.length && window.history.length > 1) {
            router?.back();
        } else {
            const homeWebLink = SOTT_DEFAULT_WEB_SCREENS.Home.getLink();
            router?.push(homeWebLink.href, homeWebLink.as);
        }
    };

    const onGoToNextEpisode = (asset?: Asset, seriesData?: ISeriesData): boolean => {
        let assetLink: ReturnType<typeof SOTT_DEFAULT_WEB_SCREENS.Playback.getLink> | null = null;
        if (!isBingeWatchingEnabled) {
            return false;
        }

        if (isEpisode(asset) && asset.nextEpisodeId) {
            assetLink = SOTT_DEFAULT_WEB_SCREENS.Playback.getLink({
                type: ASSET_TYPE.EPISODE,
                id: asset.nextEpisodeId,
            });
        } else if (seriesData?.nextEpisode) {
            assetLink = SOTT_DEFAULT_WEB_SCREENS.Playback.getLink({
                type: seriesData.nextEpisode.type,
                id: seriesData.nextEpisode.id,
            });
        }
        if (!assetLink) {
            return false;
        }
        router.replace(
            {
                pathname: assetLink.href,
            },
            assetLink.as
        );
        return true;
    };

    const onBlockedModalAction = (entitlementError?: BlockedItem) => {
        if (entitlementError?.message) {
            const accountWebLink = SOTT_DEFAULT_WEB_SCREENS.Account.getLink();
            return router?.push(accountWebLink.href, accountWebLink.as);
        }

        const signInWebLink = SOTT_DEFAULT_WEB_SCREENS.SignIn.getLink();
        return router?.push(signInWebLink.href, signInWebLink.as);
    };

    const shared = useShared({
        id: first(id),
        type: first(type) as ASSET_TYPE,
        channelId: first(channelId),
        editionId: first(editionId),
        isTrailer: first(isTrailer) === 'true',
        goBack,
        onGoToNextEpisode,
        onBlockedModalAction,
        isStartOver: isStartOver === 'true',
        sectionLabel: first(sectionLabel),
        ...props,
    });

    return {
        ...shared,
    };
};

export { useViewModel };
