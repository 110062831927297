import React, { ComponentType, ReactElement } from 'react';
import { isPlatformWeb } from 'renative';
import { getPackshotTestID, PACKSHOT_TEST_IDS } from '../test-utils';
import { RenderPropsOptions } from '../types';

/**
 * Since packshot content is a multi platform file, importing and using platform specific
 * components creates TypeScript errors where platform specific components have unique
 * props interfaces. This function returns the componet for the platform used at runtime
 * and casts the correct interface for that platform
 *
 * @param {ComponentType<P>} Component component not used on web
 * @param {ComponentType<W>} WebComponent component used on web
 * @param {P | W} props component props of either web or non-web type
 * @param {RenderPropsOptions} options
 * @param {string} testId
 * @returns {ReactElement} platform specific component with correct typing
 */
export const getRenderProps = <P, W>(
    Component: ComponentType<P>,
    WebComponent: ComponentType<W>,
    props: P | W,
    options: RenderPropsOptions,
    testId: PACKSHOT_TEST_IDS
): ReactElement => {
    const testID = getPackshotTestID(testId, options);
    return isPlatformWeb ? (
        <WebComponent testID={testID} {...(props as W)} />
    ) : (
        <Component testID={testID} {...(props as P)} />
    );
};
