import React, { useRef, useEffect, useCallback } from 'react';
import { gradientColors } from '@24i/nxg-core-utils';
import SubactionButton from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Interactable, View, ThemeIcon, ImageBackground } from '@24i/nxg-sdk-quarks';
import { usePlayerData } from '@24i/nxg-sdk-smartott-shared/src/context/PlayerData';
import LinearGradient from 'react-native-linear-gradient';
import { getEpgType } from '@24i/nxg-sdk-smartott/src/screens/DetailsScreen/viewModel/utils';
import { useTranslation } from 'react-i18next';
import { ELEMENT_PLACEMENT, ASSET_TYPE, Theme } from '@24i/nxg-sdk-photon/src';
import TitleView, {
    getPlayerTitleViewStyles,
} from '@24i/nxg-sdk-smartott/src/components/TitleView';
import { useImage } from '@24i/nxg-sdk-gluons/src/context/ImageService';
import useIsAssetPurchasedQuery from '@24i/nxg-sdk-smartott/src/hooks/query/purchases/useIsAssetPurchasedQuery';
import LoadingWrapper from '@24i/nxg-sdk-smartott/src/components/LoadingWrapper';
import { Source } from '@24i/nxg-sdk-quarks/src/components/Image/types';
import { useIsMobileLandscape } from '@24i/nxg-sdk-quantum/src/Dimensions/hooks/useIsMobileLandscape';
import {
    BANNER_CAROUSEL_TEST_IDS,
    getBannerCarouselTestID,
    getHeroBannerTestID,
    HERO_BANNER_TEST_IDS,
} from '../../../test-utils';
import getBannerCarouselItemStyles from '../styles';
import { BannerCarouselItemTypes } from '../../../types';
import { MainButton } from '../../MainButton.tsx';

const BannerCarouselItem = ({
    item,
    onPress,
    onInfoPress,
    onPlayPress,
    playButtonTitle,
    topGradient: propsTopGradient,
    index,
    bannerIndex,
    hideInfoButton,
    isObscured,
    setActionButtonsRef,
    showMetadata,
    isGeoBlocked,
}: BannerCarouselItemTypes) => {
    const actionButtonRef = useRef(null);
    // todo toggle color based on asset in the list or not
    const { theme } = useTheme();
    const { isMobileLandscape } = useIsMobileLandscape();
    const styles = getBannerCarouselItemStyles(theme, Boolean(isMobileLandscape));
    const isPropsToGradientValid =
        propsTopGradient !== undefined && propsTopGradient instanceof Array;
    const bottomGradient = isPropsToGradientValid
        ? propsTopGradient.reverse()
        : gradientColors(theme.color.background1);
    const { getImageSource, getObscuredImage } = useImage();
    const { assetHasPlaybackAction } = usePlayerData();
    const { t } = useTranslation();
    const { data, isFetching: isAssetPurchasedLoading } = useIsAssetPurchasedQuery(item.data, {
        enabled: !!item.data.id,
        cacheTime: 0,
    });
    const { isPurchased } = data ?? {};
    const hasPlaybackAction = assetHasPlaybackAction(item.data, getEpgType(item.data));

    const testIDOptions = {
        scopeIndex: bannerIndex,
        index,
    };

    const obscuredImg = getObscuredImage(item.data);

    const getTitleStyles = useCallback(
        (appTheme: Theme) => {
            const baseTitleStyles = getPlayerTitleViewStyles(appTheme);

            return {
                ...baseTitleStyles,
                ...(isMobileLandscape && {
                    title: {
                        ...baseTitleStyles.title,
                        marginTop: 0,
                        marginBottom: 0,
                    },
                }),
            };
        },
        [isMobileLandscape, getPlayerTitleViewStyles]
    );

    const InfoButton = (): JSX.Element => (
        <View style={styles.primaryButtonContainer} ref={actionButtonRef}>
            <Interactable onPress={onPress}>
                <SubactionButton
                    testID={getHeroBannerTestID(HERO_BANNER_TEST_IDS.INFO_BUTTON, {
                        scopeIndex: index + 1,
                    })}
                    onPress={onPress}
                    additionalContainerStyles={styles.infoButtonContainer}
                    iconName="onButtonSecondary-info"
                    iconSize={30}
                    title={t('asset.info.openButton')}
                />
            </Interactable>
        </View>
    );

    const PlayButton = (): JSX.Element => (
        <View style={styles.primaryButtonContainer} ref={actionButtonRef}>
            <MainButton
                buttonTitle={playButtonTitle}
                hasPlaybackAction={hasPlaybackAction}
                index={index}
                isGeoBlocked={Boolean(isGeoBlocked)}
                isPurchased={Boolean(isPurchased)}
                item={item.data}
                onInfoPress={onInfoPress}
                onPlayPress={onPlayPress}
            />
        </View>
    );

    useEffect(() => {
        if (!isAssetPurchasedLoading && setActionButtonsRef) {
            setActionButtonsRef(actionButtonRef);
        }
    }, []);

    const ImageBackgroundContent = (source: Source | undefined): JSX.Element => {
        let uri: string | undefined;
        if (typeof source === 'string') {
            uri = source;
            // eslint-disable-next-line react/destructuring-assignment
        } else if (typeof source === 'object' && source?.uri) {
            uri = source.uri;
        } else {
            uri = undefined;
        }

        return uri ? (
            <ImageBackground source={{ uri }} style={styles.image} resizeMode="cover" />
        ) : (
            <></>
        );
    };

    let metadata1;
    let assetTitle = item.data.title;

    if (item.data.type === ASSET_TYPE.EPISODE) {
        const { seasonNumber, episodeNumber, title } = item.data;
        assetTitle = item.data?.seriesName || item.data.podcastName;
        const seasonNumberText = seasonNumber
            ? `${t('asset.series.seasonInitial')}${seasonNumber} `
            : '';
        const episodeNumberText = episodeNumber
            ? `${t('asset.series.episode.initial')}${episodeNumber} `
            : '';
        const episodeTitleText = (seasonNumber || episodeNumber) && title ? `- ${title}` : title;

        metadata1 = `${seasonNumberText}${episodeNumberText}${episodeTitleText}`;
    }

    return (
        <View
            testID={getBannerCarouselTestID(
                BANNER_CAROUSEL_TEST_IDS.SLIDE_CONTAINER,
                testIDOptions
            )}
            style={styles.itemContainer}
        >
            <Interactable onPress={hideInfoButton ? onPress : undefined}>
                <View style={styles.textContainer}>
                    {item.title && (
                        <View>
                            <TitleView
                                isInCarousel
                                title={isObscured ? t('asset.blocked.title') : assetTitle || ''}
                                channelLogo={item.data.channelLogo}
                                startsAt={item.data?.startsAt}
                                endsAt={item.data?.endsAt}
                                subtitle={isObscured ? t('asset.blocked.metadata') : metadata1}
                                childrenPlacement={ELEMENT_PLACEMENT.BELLOW}
                                index={index}
                                assetLabel={item.data.assetLabel}
                                testID={getHeroBannerTestID(HERO_BANNER_TEST_IDS.TITLE, {
                                    scopeIndex: index + 1,
                                })}
                                showMetadata={showMetadata}
                                styles={getTitleStyles}
                            >
                                <LoadingWrapper
                                    isLoading={isAssetPurchasedLoading}
                                    loaderProps={{
                                        size: 'large',
                                        style: styles.activityIndicator,
                                        color: theme.color.textPrimary,
                                    }}
                                >
                                    <View style={styles.playButtonContainer}>
                                        {hasPlaybackAction && isPurchased ? (
                                            <>
                                                <PlayButton />
                                                {!hideInfoButton && <InfoButton />}
                                            </>
                                        ) : (
                                            !hideInfoButton && <InfoButton />
                                        )}
                                    </View>
                                </LoadingWrapper>
                            </TitleView>
                        </View>
                    )}
                </View>
                <View style={styles.bannerItemContainer}>
                    {ImageBackgroundContent(
                        isObscured
                            ? obscuredImg
                            : { uri: item.illustration || getImageSource('fallback') }
                    )}
                    {isObscured && (
                        <View style={styles.obscuredImageContainer}>
                            <ThemeIcon iconName="onPrimary-lock" size={112} />
                        </View>
                    )}
                    <LinearGradient colors={bottomGradient} style={styles.bottomGradient} />
                </View>
            </Interactable>
        </View>
    );
};

export default overridable(BannerCarouselItem, 'BannerCarouselItem');
