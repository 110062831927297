import { BASE_MENU_HEIGHT, BASE_GAP } from '@24i/nxg-core-utils/src/constants';
import { calculateTelegramGap } from '@24i/nxg-sdk-higgs/src/utils';

export const getTopGapHeight = (shouldShowTopNavbar: boolean) => {
    if (shouldShowTopNavbar) {
        return BASE_MENU_HEIGHT + calculateTelegramGap(2);
    }

    return Math.max(calculateTelegramGap(), BASE_GAP);
};
