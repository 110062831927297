import { ReactNode } from 'react';

import { UsePushNotifications } from '../../hooks/usePushNotifications/types';

export const useRemotePushNotifications = () => {
    throw new Error('This is not usable in your current context');
};

type Props = {
    children: ReactNode;
    localPushNotifications?: UsePushNotifications;
};

export const RemotePushNotificationsProvider = ({ children }: Props) => children;
