import React, { useEffect } from 'react';
import { useFirebase } from '@24i/nxg-sdk-smartott/src/context/Firebase';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { SOTT_DEFAULT_WEB_SCREENS } from '../../../navigation/constants';
import { WebViewModelReturnType } from './types';
import ForgotPasswordScreen from '../../ForgotPasswordScreen';
import useUserSession from '../../../hooks/useUserSession';
import useShared from './shared';

const UNSUPPORTED_FUNCTION = () => {
    throw new Error(
        'Function is unsupported by the generic view model and is only provided for API consistency because of module switching.'
    );
};

const useViewModel = (): WebViewModelReturnType => {
    const router = useRouter();
    const { signInUser, isLoading } = useUserSession();
    const { closeModal, setModalChildren } = useModal();
    const { crashlyticsLog } = useFirebase();
    const enableCloseButton = router.asPath === '/signin';
    const shared = useShared();
    const { authMethod } = shared;

    useEffect(() => {
        crashlyticsLog('SignIn screen entered');
        return crashlyticsLog('SignIn screen exited');
    }, []);

    const onSigninPress = async (email, password) => {
        crashlyticsLog('Attempt to sign in');
        const signInResult = await signInUser(email, password);

        if (signInResult.login) closeModal();
        return signInResult;
    };

    const onForgotPasswordPress = () => setModalChildren(<ForgotPasswordScreen.Main isModal />);

    const onSignupPress = () => {
        const webLink = SOTT_DEFAULT_WEB_SCREENS.SignUp.getLink();
        router.push(webLink.href, webLink.as);
    };

    const handleOnBack = () => {
        router.back();
    };

    return {
        isLoading,
        handleForgotPasswordPress: onForgotPasswordPress,
        // These properties have to be returned as if the .tv version is removed, this file will be used instead
        // Also the API has to be unified because you can include tv.ts version from .ts because of module switching
        // Notice that these are the values that are not supported by the generic view, only by the TV view
        emailValue: '',
        requestError: null,
        inputsTouched: false,
        passwordValue: '',
        emailValidationError: null,
        passwordValidationError: null,
        handleEmailChange: UNSUPPORTED_FUNCTION,
        handleEmailSubmit: UNSUPPORTED_FUNCTION,
        handleSignInPressTv: UNSUPPORTED_FUNCTION,
        handlePasswordChange: UNSUPPORTED_FUNCTION,
        handlePasswordSubmit: UNSUPPORTED_FUNCTION,
        onSigninPress,
        onSignupPress,
        handleOnBack,
        enableCloseButton,
        authMethod,
    };
};

export { useViewModel };
