import { useMemo } from 'react';
import { isPlatformWeb } from 'renative';
import _ from 'lodash';

import {
    Asset,
    ASSET_TYPE,
    getIsPodcastEpisode,
    getIsPodcastSeries,
    PageSection,
} from '@24i/nxg-sdk-photon';
import { useCast } from '@24i/nxg-sdk-smartott/src/context/Cast';
import prepareAutoPlay from '@24i/nxg-sdk-smartott/src/utils/prepareAutoPlay';

import { useSourceManager } from '../../PlaybackScreen/managers';
import useAssetActions from '../../../hooks/useAssetActions';
import { useStore } from '../../../context/ApplicationStore';
import { CustomAsset, DynaRowSharedProps, DynaRowScreenProps } from '../types';
import useSectionsStructureQuery from '../../../hooks/query/dynarow/useSectionsStructureQuery';
import usePlaylistsQuery from '../../../hooks/query/dynarow/usePlaylistsQuery';
import useAssetBlockersValidation from '../../../hooks/useAssetBlockersValidation';
import { usePodcastPlayer } from '../../../context/PodcastPlayerProvider';

const PLAYLISTS_MOBILE_PAGE_SIZE = 3;

const useShared = ({
    onFetchStream,
    goToPlayer: customGoToPlayer,
    pageId = '',
    sections: sectionsFromProps,
    hasScreenType,
    originFromHere,
}: DynaRowScreenProps): DynaRowSharedProps => {
    const { selectedProfile } = useStore();
    const { getStream } = useSourceManager(undefined, onFetchStream);

    const { startPlayback, isCanGoToPlayer, isCanGoToDetail, fetchEpisodeToReproduce } =
        useAssetActions({
            origin: originFromHere,
        });
    const { isCastConnected, startCastingStream } = useCast();
    const { handleBlockersCheck } = useAssetBlockersValidation({});
    const {
        closePlayer: closePodcastPlayer,
        startLoading: startLoadingPodcastPlayer,
        stopLoading: stopLoadingPodcastPlayer,
        episodeInPlayer: podcastEpisodeInPlayer,
    } = usePodcastPlayer();

    // If sections are delivered from props, there is no need to fetch data through pageId. Placing it an empty string will disable this and following request.
    // REPLY -> This should be improved. React query accepts initialData as an arg for a query. If data is present React Query should pull data from there.
    const shouldFetchData = !sectionsFromProps;
    const pageIdToFetch = shouldFetchData ? pageId : '';

    const {
        data: pageSections,
        isError: isPageSectionsError,
        isLoading: arePageSectionsLoading,
        isIdle: arePageSectionsIdle,
    } = useSectionsStructureQuery(pageIdToFetch, selectedProfile);

    // TODO - Legacy logic, why do we have different behavior for web and mobile?
    const playListSize = isPlatformWeb ? pageSections?.length : PLAYLISTS_MOBILE_PAGE_SIZE;

    const {
        data: sectionsWithPlaylistsPages,
        fetchNextPage,
        refetch,
        isError: isPlaylistsError,
        isLoading: isPlaylistLoading,
        isIdle: isPlaylistIdle,
        isRefetching,
        isFetching,
    } = usePlaylistsQuery(pageSections || [], pageId, playListSize, selectedProfile, hasScreenType);

    const sectionsFromQuery = useMemo(() => {
        return sectionsWithPlaylistsPages?.pages
            ?.flat()
            .filter((section) => !!section?.items?.length || !!section?.additionalItems?.length);
    }, [sectionsWithPlaylistsPages]);

    const sections: PageSection[] | undefined = sectionsFromProps || sectionsFromQuery;

    let isDataLoading =
        arePageSectionsLoading ||
        arePageSectionsIdle ||
        isPlaylistLoading ||
        isPlaylistIdle ||
        (!hasScreenType && isRefetching && isFetching);

    // If data is already present in sections variable it means rows and playlist content has been fetched already, at least partially so we want to render.
    if (!_.isEmpty(sections)) isDataLoading = false;

    const handleGoToPlayer = async (
        item: Asset | CustomAsset,
        handlePurchaseAsset?: (asset: Asset) => void,
        playPodcastFromDetailPage?: boolean,
        redirectToPodcastDetailPage?: (item: Asset) => void
    ) => {
        if (customGoToPlayer) {
            customGoToPlayer(item);
            return;
        }

        const isPodcastSeries = getIsPodcastSeries(item);
        const isPodcastEpisode = getIsPodcastEpisode(item);
        const shouldFollowPodcastFlow = isPodcastSeries || isPodcastEpisode;

        const assetToCheck = isPodcastSeries ? await fetchEpisodeToReproduce(item, 'latest') : item;

        const { hasBlocker, MVPCustomMessage } = await handleBlockersCheck({
            asset: assetToCheck ?? item,
            handlePurchase: handlePurchaseAsset,
            openAPTModalOnBlock: false,
        });

        if (hasBlocker && !MVPCustomMessage) {
            if (shouldFollowPodcastFlow) closePodcastPlayer();
            return; // allow Adobe Primetime block cases to arrive to playbackScreen and show modal there
        }

        if (shouldFollowPodcastFlow) {
            const episodeIsAlreadyInPodcastPlayer = podcastEpisodeInPlayer?.id === assetToCheck?.id;
            if (episodeIsAlreadyInPodcastPlayer) {
                return;
            }

            if (
                isPlatformWeb &&
                playPodcastFromDetailPage &&
                redirectToPodcastDetailPage &&
                assetToCheck &&
                getIsPodcastEpisode(assetToCheck)
            ) {
                redirectToPodcastDetailPage(item);
                return;
            }

            closePodcastPlayer();
            startLoadingPodcastPlayer();
        }

        if (isCastConnected) {
            const sourceData = await getStream(item);
            await startCastingStream(item, sourceData);
        } else {
            if (shouldFollowPodcastFlow) stopLoadingPodcastPlayer();
            await prepareAutoPlay();
            startPlayback(item);
        }
    };

    const handleSeriesPlayerAction = async (
        show: Asset,
        handlePurchaseAsset?: (asset: Asset) => void
    ) => {
        const episode = await fetchEpisodeToReproduce(show, 'first');
        let seriesTitle;
        let episodeWithSeriesTitle;
        if (!show.isTrailer) {
            seriesTitle = show.title;
            episodeWithSeriesTitle = {
                ...episode,
                seriesTitle,
            };
        }
        if (!episode) return;
        handleGoToPlayer(episodeWithSeriesTitle, handlePurchaseAsset);
    };

    const onPackshotPress = async ({
        onLinkPress,
        onGoToDetails,
        section,
        asset,
        onPurchaseAsset,
        playPodcastFromDetailPage,
        redirectToPodcastDetailPage,
    }: {
        onLinkPress: () => void;
        onGoToDetails: () => void;
        section: PageSection;
        asset: Asset;
        onPurchaseAsset?: (asset: Asset) => void;
        playPodcastFromDetailPage?: boolean;
        redirectToPodcastDetailPage?: (item: Asset) => void;
    }) => {
        if ([ASSET_TYPE.CUSTOM, ASSET_TYPE.IMAGE].includes(asset.type)) {
            onLinkPress();
        } else if (section.actionForAllItems === 'player' && asset.type === ASSET_TYPE.SERIES) {
            handleSeriesPlayerAction(asset, onPurchaseAsset);
        } else {
            let shouldGoToPlayer: boolean;
            if (section.actionForAllItems === 'player') {
                shouldGoToPlayer = isCanGoToPlayer(asset) || !isCanGoToDetail(asset);
            } else if (section.actionForAllItems === 'detail') {
                // @ts-ignore TODO: Should cast item to broadcast ?
                const isChannelWithBroadcast = asset.type === ASSET_TYPE.CHANNEL; // && asset.broadcastMetadata?.id;
                shouldGoToPlayer =
                    !isCanGoToDetail(asset) && isCanGoToPlayer(asset) && !isChannelWithBroadcast;
            } else {
                shouldGoToPlayer = !isCanGoToDetail(asset) && isCanGoToPlayer(asset);
            }

            if (shouldGoToPlayer) {
                handleGoToPlayer(
                    asset,
                    onPurchaseAsset,
                    playPodcastFromDetailPage,
                    redirectToPodcastDetailPage
                );
            } else {
                onGoToDetails();
            }
        }
    };

    return {
        handleGoToPlayer,
        fetchNextPage: () => fetchNextPage(),
        refetch: () => refetch(),
        onPackshotPress,
        hasError: isPageSectionsError || isPlaylistsError,
        sections,
        isLoading: isDataLoading,
    };
};

export default useShared;
