import React, { FC, useEffect, useRef } from 'react';
import { View } from '@24i/nxg-sdk-quarks';

type Props = {
    onElementVisible: () => void;
};

export const IntersectionObserverSentinel: FC<Props> = ({ onElementVisible }) => {
    const sentinelRef = useRef(null);

    useEffect(() => {
        if (!sentinelRef.current) return;

        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    onElementVisible();
                }
            },
            { root: null, rootMargin: '0px', threshold: 1.0 }
        );

        observer.observe(sentinelRef.current);

        // eslint-disable-next-line consistent-return
        return () => {
            if (sentinelRef.current) {
                observer.unobserve(sentinelRef.current);
            }
        };
    }, [onElementVisible]);

    return <View ref={sentinelRef} style={{ height: 1 }} />;
};
