import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View } from '@24i/nxg-sdk-quarks';
import { isWebView } from '@24i/nxg-sdk-higgs/src/utils';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { NavScreenConfigWeb } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { getBottomTabBarStyles } from './styles/index.web';
import { TopBarMenuItem } from '../../../components/MenuItem';
import { useMenuItemPress } from '../../utils';

const DEFAULT_TAB_BAR_HEIGHT = 51;
const WEB_VIEW_TAB_BAR_HEIGHT = 72;

// TODO: Type
const BottomTabBar = ({ styles: getStyles = getBottomTabBarStyles, menuItems = [] }: any) => {
    const router = useRouter();
    const { theme } = useTheme();
    const { t } = useTranslation(['sott']);
    const onPress = useMenuItemPress();
    const isInWebView = isWebView();
    const height = isInWebView ? WEB_VIEW_TAB_BAR_HEIGHT : DEFAULT_TAB_BAR_HEIGHT;

    const styles = getStyles(theme);

    const getMenuItemComponent = (menuItem: NavScreenConfigWeb, index) => {
        const { icon, menuTitle, name, optionsWeb: options } = menuItem;

        let titleLabel;
        if (options?.showTitle !== false) {
            const lowerCaseName = name.toLowerCase();
            if (lowerCaseName === 'searching' || lowerCaseName === 'search') {
                titleLabel = t(`search.title`);
            } else {
                titleLabel = t(`menu.${menuTitle}`);
            }
        } else {
            titleLabel = '';
        }

        return (
            <View key={`bottommenu-link-${name}`}>
                <TopBarMenuItem
                    key={`bottommenu-${menuTitle}`}
                    title={titleLabel}
                    icon={icon}
                    index={index}
                    active={router.asPath.includes(name)}
                    spatial
                    onPress={() => onPress(menuItem)}
                    variant="bottom-menu"
                />
            </View>
        );
    };

    return (
        <View
            style={{
                ...styles.menuContainer,
                height,
            }}
        >
            <View style={{ ...styles.sectionContainer, maxHeigh: height }}>
                <View style={styles.section}>{menuItems.map(getMenuItemComponent)}</View>
            </View>
        </View>
    );
};

export default overridable(BottomTabBar, 'BottomTabBar');
