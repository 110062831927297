import { Theme } from '@24i/nxg-sdk-photon';
import { ViewStyle } from 'react-native';
import { Breakpoint } from '@24i/nxg-sdk-smartott/src/utils/styles/constants';

export type ExpandedPlayerContainerStyles = {
    container: ViewStyle;
    inner: ViewStyle;
    closeButton: ViewStyle;
    centeredContainer: ViewStyle;
};

export const getStyles = (
    theme: Theme,
    screenHeight: number,
    screenWidth: number,
    isTablet: boolean
): ExpandedPlayerContainerStyles => ({
    container: {
        height: screenHeight,
        backgroundColor: theme.color.lighter1,
        paddingBottom: screenHeight / 2 - (isTablet ? 256 : 240),
        paddingHorizontal: 20,
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    centeredContainer: {
        justifyContent: 'center',
    },
    inner: {
        maxWidth: 740,
        minWidth: 312,
        width: '100%',
        margin: 'auto',
    },
    closeButton: {
        position: 'absolute',
        top: screenWidth <= Breakpoint.SM ? 20 : 32,
        left: screenWidth <= Breakpoint.SM ? 20 : 32,
        width: 50,
        height: 50,
        zIndex: 99,
    },
});
