import { useMemo } from 'react';
import { isWebMobile } from '@24i/nxg-sdk-higgs/src/utils';
import useDimensions from '@24i/nxg-sdk-quantum/src/Dimensions/hooks/useDimensions';

export const useIsMobileLandscape = () => {
    const { isLandscape } = useDimensions();
    const isMobile = isWebMobile();

    const isMobileLandscape = useMemo(() => isMobile && isLandscape, [isMobile, isLandscape]);

    return { isMobileLandscape };
};
