import React, { FC } from 'react';
import { Platform } from 'react-native';
import { useTranslation } from 'react-i18next';

import { View, Text, Image } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { formatFullReleaseDate, isPlatformWeb, isValidDateString } from '@24i/nxg-core-utils';

import { getStyles } from './styles';
import { MaybeEpisode } from '../../../types';

type Props = {
    episode: MaybeEpisode;
};

const SHOULD_USE_SQUARE_IMAGE = ['ios', 'android', 'web'].includes(Platform.OS);

export const EpisodeDetails: FC<Props> = ({ episode }) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { i18n } = useTranslation();

    const podcastName = episode?.podcastName || '';
    const title = episode?.title || '';
    const imageSource = (SHOULD_USE_SQUARE_IMAGE && episode?.square) || episode?.still || undefined;
    const isSquareImage = SHOULD_USE_SQUARE_IMAGE && episode?.square;
    const date = episode?.fullReleaseDate;

    return (
        <View style={styles.container}>
            {imageSource != null && (
                <View
                    style={{
                        ...styles.imageHolder,
                        width: isSquareImage ? styles.imageHolder.height : styles.imageHolder.width,
                    }}
                >
                    <Image source={imageSource} style={styles.image} />
                </View>
            )}

            <View style={styles.content}>
                {isPlatformWeb && !!date && isValidDateString(date) && (
                    <Text style={styles.date} numberOfLines={1}>
                        {formatFullReleaseDate(date, i18n.language)}
                    </Text>
                )}

                <Text style={styles.title} numberOfLines={1}>
                    {title}
                </Text>

                <Text style={styles.podcastName} numberOfLines={1}>
                    {podcastName}
                </Text>
            </View>
        </View>
    );
};
