import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { useTranslation } from 'react-i18next';

const DEFAULT_MIN_SEARCH_QUERY = 3;

export const useMinSearchQueryLength = () => {
    const searchCharRules = useFeature('searchCharRules');
    const { i18n: i18nTranslation } = useTranslation(['sott']);

    // Function to get the minimum search query length based on the language
    const getMinSearchQuery = (language: string) => {
        // Use default searchCharRules if searchCharRules is null or undefined
        const rules = searchCharRules ?? { default: 3 };

        // Return the language-specific rule or default
        return rules[language] || rules.default;
    };

    // Get the minimum number of characters required for the current language
    const minSearchQueryLength =
        getMinSearchQuery(i18nTranslation?.language) ?? DEFAULT_MIN_SEARCH_QUERY;

    return { minSearchQueryLength };
};
