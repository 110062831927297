import React, { ReactElement, useMemo, useState, useEffect } from 'react';
import { isPlatformWeb } from 'renative';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { ASSET_TYPE, PageSection, ROW_TYPES } from '@24i/nxg-sdk-photon/src';
import { DEFAULT_SEARCH_QUERY_SIZE } from '@24i/nxg-core-utils/src/constants';
import { useSearchQuery } from '@24i/nxg-sdk-smartott-shared/src/hooks/query/search';
import SearchNothingFound from '../../SearchNothingFound';
import { getWebDynaRowScreenStylesOverride } from '../styles';
import { SearchScreenContentProps } from '../types';
import DynaRowScreen from '../../../../DynaRowScreen';
import { DynaRowScreenModeEnum } from '../../../../DynaRowScreen/types';
import { ScreenOrigin, ScreenOriginType } from '../../../../../navigation/types';

const SearchScreenContent = (props: SearchScreenContentProps): ReactElement => {
    const {
        items = [],
        initialData = [],
        query = '',
        overrideInitialDataLayout = null,
        searchEnabled,
        navigation,
        route,
        shouldDisplayGridLayout,
        isLoading,
        totalFoundItems,
    } = props;

    const [data, setData] = useState<PageSection[]>(query === '' ? initialData : items);

    const originFromHere: ScreenOrigin = useMemo(
        () => ({
            type: ScreenOriginType.INTERNAL,
            id: 'search',
            label: route?.params?.title || 'Search',
        }),
        [route]
    );

    const { fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } = useSearchQuery({
        query,
        size: DEFAULT_SEARCH_QUERY_SIZE,
        enabled: shouldDisplayGridLayout,
    });

    const onGridEndReached = async (sections: PageSection[]) => {
        if (hasNextPage && !isFetchingNextPage && !isFetching) {
            const nextPageFetchData = await fetchNextPage();
            const allAssets = (nextPageFetchData.data?.pages ?? []).flatMap(
                (page) => page.items ?? []
            );
            const updatedSection: PageSection = {
                // There is only one category in this case!!
                ...sections[0],
                items: allAssets,
            };

            setData([updatedSection]);
        }
    };

    useEffect(() => {
        setData(query === '' ? initialData : items);
    }, [initialData, items, query]);

    const contentToRender = useMemo(() => {
        if (overrideInitialDataLayout && query === '') return overrideInitialDataLayout();

        if (
            (searchEnabled && query !== '') ||
            (!overrideInitialDataLayout && query === '' && initialData.length > 0)
        ) {
            const shouldGoToPlayer = ASSET_TYPE.EPISODE || ASSET_TYPE.CHANNEL;
            const updatedSections: PageSection[] = [];

            if (data.length > 0) {
                const firstSection = data[0];

                if (shouldDisplayGridLayout && firstSection != null) {
                    const label = firstSection.label?.replace(/\(\d+\)/, `(${totalFoundItems})`);
                    updatedSections.push({
                        ...firstSection,
                        actionForAllItems:
                            firstSection.id === shouldGoToPlayer ? 'player' : 'detail',
                        type: ROW_TYPES.GRID,
                        label,
                    });
                } else {
                    data.forEach((section) => {
                        updatedSections.push({
                            actionForAllItems:
                                section.id === shouldGoToPlayer ? 'player' : 'detail',
                            ...section,
                        });
                    });
                }

                return (
                    <DynaRowScreen.Main
                        originFromHere={originFromHere}
                        sections={updatedSections}
                        styles={isPlatformWeb ? getWebDynaRowScreenStylesOverride : undefined}
                        mode={DynaRowScreenModeEnum.BASIC_DISPLAY}
                        navigation={navigation}
                        route={route}
                        hideTitle
                        playPodcastFromDetailPage={isPlatformWeb}
                        onGridEndReached={
                            shouldDisplayGridLayout
                                ? () => onGridEndReached(updatedSections)
                                : undefined
                        }
                        isLoadingMoreGridItems={isFetchingNextPage}
                    />
                );
            }

            if (!isLoading) {
                return <SearchNothingFound query={query} />;
            }
        }

        return <></>;
    }, [
        data,
        originFromHere,
        shouldDisplayGridLayout,
        totalFoundItems,
        isPlatformWeb,
        onGridEndReached,
        navigation,
        route,
        isLoading,
        query,
        overrideInitialDataLayout,
        initialData,
        searchEnabled,
    ]);

    return contentToRender;
};

export default overridable(SearchScreenContent, 'SearchScreenContent');
