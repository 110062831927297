import React, { useCallback, useEffect, FC } from 'react';
import { PodcastVolume, AudioPlayer } from '@24i/player-ui-react';
import { IPlayerHtmlEngine } from '@24i/player-base';
import { PlayerUIContextProvider } from '@24i/player-ui';
import { usePodcastPlayer } from '../../../context/PodcastPlayerProvider';
import { EpisodeDetails } from '../components/MiniPlayer/EpisodeDetails';
import { Controls } from '../components/MiniPlayer/WebControls';
import { MiniPlayerContainer } from '../components/MiniPlayer/MiniPlayerContainer/index.web';
import { useShared } from '../viewModel/shared';
import { MiniPlayerLoadingSkeleton } from '../components/LoadingSkeleton';

export const PodcastPlayerView: FC = () => {
    const { episodeInPlayer, isLoading } = usePodcastPlayer();
    const {
        source,
        engine,
        onError,
        onEnd,
        onPause,
        onPlay,
        onSeeked,
        onStop,
        startPosition,
        startPositionType,
        onPrevNextPress,
        adjacentEpisodes,
    } = useShared();

    const playerSource = source || null;

    const onEndHandler = useCallback(() => {
        if (onEnd) onEnd();
    }, [onEnd]);

    useEffect(
        () => () => {
            // send stop event when the user leaves the playback screen
            // this seems is not supported by player itself for web
            if (onEnd) onEnd();
        },
        []
    );

    if (!episodeInPlayer && !isLoading) return null;

    if (isLoading) return <MiniPlayerLoadingSkeleton />;

    return (
        <AudioPlayer
            engine={engine as IPlayerHtmlEngine}
            onError={onError}
            onEnd={onEndHandler}
            {...{
                source: playerSource,
                onPlay,
                onSeeked,
                onPause,
                onStop,
                startPosition,
                startPositionType,
            }}
            continueInBackground
            autoPlay
        >
            {(playerInstance) =>
                episodeInPlayer ? (
                    <PlayerUIContextProvider>
                        <MiniPlayerContainer
                            leftContent={<EpisodeDetails episode={episodeInPlayer} />}
                            centerContent={
                                <Controls
                                    playerInstance={playerInstance}
                                    onPrevNextPress={onPrevNextPress}
                                    adjacentEpisodes={adjacentEpisodes}
                                />
                            }
                            rightContent={<PodcastVolume playerInstance={playerInstance} />}
                        />
                    </PlayerUIContextProvider>
                ) : null
            }
        </AudioPlayer>
    );
};
