import useDimensions from '@24i/nxg-sdk-quantum/src/Dimensions/hooks/useDimensions';
import { View } from '@24i/nxg-sdk-quarks';
import LoadingWrapper from '@24i/nxg-sdk-smartott/src/components/LoadingWrapper';
import React, { useEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import Collection from 'react-virtualized/dist/commonjs/Collection';
import { calcMinutes, isPlayingNow, isToday } from '../../../utils/DateHelpers';
import CurrentTimeLine from '../components/CurrentTimeLine';
import DefaultCell from '../components/DefaultCell';
import Sidebar from '../components/Sidebar';
import Timeline from '../components/Timeline';
import { useAnimatedScrolling } from '../hooks/useAnimatedScrolling';
import { useScrollingWithkeys } from '../hooks/useScrollingWithKeys';
import getStyles from '../styles';
import { EPGProps } from '../types';

const EPGView = (props: EPGProps, ref: typeof EPGView): JSX.Element => {
    const {
        currentTime = new Date(new Date().setSeconds(1, 1)),
        theme,
        scrollLeft: leftScroll,
        scrollTop: topScroll,
        data,
        channels,
        onPressCell,
        lockedContentTitle,
        is12HourClock,
        lastDateChange,
        customCell,
        isLoading,
        isFetching,
        onVerticalEndReached,
        onScroll: handleScroll,
        onWebViewportScroll,
        selectedDate,
        currentTimeLabelText,
        timelineRef,
        collectionRef,
        isScrolling,
        handleScrolling,
    } = props;

    const { width: initialWidth } = useDimensions();
    const styles = getStyles();
    const dimensions = useRef({ width: 0, height: 0 });
    const lastClientX = useRef<number | null>(null);
    const lastClientY = useRef<number | null>(null);
    const mouseDown = useRef<boolean | null>(null);

    const [currentScroll, setCurrentScroll] = useState({
        scrollLeft: null,
        scrollTop: 0,
        scrollHeight: 0,
        scrollWidth: 0,
    });
    const setScrollState = (scroll) => {
        setCurrentScroll((prevState) => ({ ...prevState, ...scroll }));
    };
    const {
        reachedStartOfDay,
        reachedEndOfDay,
        scrollOneWidthLeft,
        scrollOneWidthRight,
        scrollOneHeightDown,
        scrollOneHeightUp,
    } = useAnimatedScrolling({
        currentScroll,
        dimensions: dimensions.current,
        theme,
        setScrollState,
    });

    useScrollingWithkeys(
        {
            onUpPress: scrollOneHeightUp,
            onDownPress: scrollOneHeightDown,
            onLeftPress: scrollOneWidthLeft,
            onRightPress: scrollOneWidthRight,
        },
        [dimensions.current.height, dimensions.current.width, currentScroll]
    );

    const getInitialScroll = () => {
        let initialLeftScroll = Math.max(
            0,
            calcMinutes(currentTime) * theme.PIXELS_PER_MIN -
                (initialWidth - theme.sidebar.container.width) / 2
        );

        if (leftScroll !== undefined && topScroll !== undefined) {
            return {
                scrollLeft: initialLeftScroll,
                scrollTop: topScroll,
            };
        }
        return {
            scrollLeft: initialLeftScroll,
            scrollTop: 0,
        };
    };

    useEffect(() => {
        setScrollState(getInitialScroll());
    }, []);

    const calculateViewport = ({ scrollLeft, scrollTop }, width, height) => {
        const dateStart = new Date(selectedDate).setHours(0, 0, 0, 0);
        const timelineStart = new Date(
            Math.floor((scrollLeft / theme.PIXELS_PER_MIN) * 60 * 1000 + dateStart)
        ).getTime();
        const timelineEnd = new Date(
            Math.floor(((scrollLeft + width) / theme.PIXELS_PER_MIN) * 60 * 1000 + dateStart)
        ).getTime();
        const channelStart = Math.floor(scrollTop / theme.cell.container.height);
        const channelEnd = Math.floor((scrollTop + height) / theme.cell.container.height);
        return {
            timeline: { start: timelineStart, end: timelineEnd },
            channels: { start: channelStart, end: channelEnd },
        };
    };

    const timeLineOffset = theme.showTimelineArrows ? theme.sidebar.container.width : 0;

    useEffect(() => {
        onWebViewportScroll?.(
            calculateViewport(
                { scrollLeft: currentScroll.scrollLeft, scrollTop: currentScroll.scrollTop },
                dimensions.current.width,
                dimensions.current.height
            ),
            false
        );
    }, [!!currentScroll.scrollLeft, !!currentScroll.scrollTop, !!data.length, selectedDate]);

    const cellRenderer = ({ index, key, style }) => {
        const timelinePosition = calcMinutes(currentTime) * theme.PIXELS_PER_MIN + timeLineOffset;
        if (index === data.length) return <></>;

        return index === data.length + 1 ? (
            <CurrentTimeLine
                key={key}
                timelinePosition={timelinePosition}
                height={channels.length * theme.cell.container.height}
                theme={theme.currentTime}
            />
        ) : (
            customCell || (
                <DefaultCell
                    data={data[index]}
                    key={key}
                    style={style}
                    isPlayingNow={isPlayingNow(
                        Number(currentTime),
                        data[index].getStart(),
                        data[index].getEnd()
                    )}
                    currentTime={currentTime}
                    timelinePosition={timelinePosition}
                    forceUpdate={lastDateChange}
                    theme={theme.cell}
                    lockedContentTitle={lockedContentTitle}
                    onPressCell={isScrolling ? () => {} : onPressCell}
                    is12HourClock={is12HourClock}
                />
            )
        );
    };

    const cellSizeAndPositionGetter = ({ index }) => {
        const datum = data[index];
        if (index === data.length)
            return {
                height: channels.length * theme.cell.container.height,
                width: theme.currentTime.line.width,
                x: theme.PIXELS_PER_MIN * 24 * 60 + timeLineOffset,
                y: 0,
            };
        return index === data.length + 1
            ? {
                  height: channels.length * theme.cell.container.height,
                  width: theme.currentTime.line.width,
                  x: calcMinutes(currentTime) * theme.PIXELS_PER_MIN + timeLineOffset,
                  y: 0,
              }
            : {
                  height: datum.getHeight(),
                  width: datum.getWidth(),
                  x: datum.getX() + timeLineOffset,
                  y: datum.getY(),
              };
    };

    const onScroll = (scroll, width, height) => {
        const { scrollLeft, scrollTop } = scroll;
        const epgHeight = theme.cell.container.height * (channels.length - 1);
        setScrollState(scroll);
        if (scrollTop + height >= epgHeight && height < epgHeight) {
            onVerticalEndReached();
            handleScrolling(true);
        } else {
            handleScrolling(false);
        }
        if (handleScroll) handleScroll({ scrollLeft, scrollTop }, height);
        if (onWebViewportScroll)
            onWebViewportScroll(calculateViewport(scroll, width, height), isScrolling);
    };

    const onMouseMove = (e, height, width) => {
        if (mouseDown.current) {
            e.persist();
            handleScrolling(true);
            let newScrollLeft = 0;
            let newScrollTop = 0;

            if (currentScroll.scrollLeft !== null && lastClientX.current) {
                newScrollLeft =
                    currentScroll.scrollLeft! +
                    (lastClientX.current - (lastClientX.current = e.clientX));
            }

            if (currentScroll.scrollTop !== null && lastClientY.current) {
                newScrollTop =
                    currentScroll.scrollTop! +
                    (lastClientY.current - (lastClientY.current = e.clientY));
            }

            const maxScrollLeft =
                theme.PIXELS_PER_MIN * 24 * 60 - (width - theme.sidebar.container.width);
            const maxScrollTop =
                theme.cell.container.height * channels.length -
                (height - theme.timeline.container.height);
            setScrollState({
                scrollLeft: Math.min(maxScrollLeft, Math.max(0, newScrollLeft)),
                scrollTop: Math.min(maxScrollTop, Math.max(0, newScrollTop)),
            });
        }
    };

    const onMouseUp = () => {
        mouseDown.current = false;
        handleScrolling(false);
    };

    const onMouseDown = (e) => {
        e.preventDefault();
        if (!mouseDown.current) {
            mouseDown.current = true;
            lastClientX.current = e.clientX;
            lastClientY.current = e.clientY;
        }
    };

    const dataLength = isToday(currentTime, selectedDate) ? data.length + 2 : data.length + 1;
    const NUMBER_OF_CELLS_IN_EMPTY_EPG = 1;

    return (
        <View style={styles.wrapper}>
            <LoadingWrapper
                wrapperStyle={styles.loadingWrapper}
                isLoading={!!isLoading || !!isFetching}
                shouldOverlay
                loaderProps={{
                    size: 'large',
                }}
            >
                <AutoSizer>
                    {({ width, height }) => {
                        dimensions.current = { width, height };
                        return (
                            <>
                                <Timeline
                                    scrollLeft={currentScroll.scrollLeft || undefined}
                                    width={width - timeLineOffset}
                                    theme={theme}
                                    currentTime={currentTime}
                                    showLeftArrow={reachedStartOfDay}
                                    showRightArrow={reachedEndOfDay}
                                    onScrollLeftPress={scrollOneWidthLeft}
                                    onScrollRightPress={scrollOneWidthRight}
                                    showCurrentTime={isToday(currentTime, selectedDate)}
                                    timeLabelPosition={
                                        calcMinutes(currentTime) * theme.PIXELS_PER_MIN
                                    }
                                    timelineRef={timelineRef}
                                    currentTimeLabelText={currentTimeLabelText}
                                    is12HourClock={is12HourClock}
                                />
                                <View
                                    onMouseDown={onMouseDown}
                                    onMouseUp={onMouseUp}
                                    onMouseMove={(e) => onMouseMove(e, height, width)}
                                    style={{ ...{ width }, ...styles.container }}
                                >
                                    <Sidebar
                                        scrollTop={currentScroll.scrollTop}
                                        channels={channels}
                                        height={height - theme.timeline.container.height}
                                        theme={theme}
                                    />
                                    <Collection
                                        style={styles.collection}
                                        cellCount={
                                            !data.length ? NUMBER_OF_CELLS_IN_EMPTY_EPG : dataLength
                                        }
                                        cellRenderer={cellRenderer}
                                        cellSizeAndPositionGetter={cellSizeAndPositionGetter}
                                        height={height - theme.timeline.container.height}
                                        width={width}
                                        onScroll={(e) => onScroll(e, width, height)}
                                        scrollLeft={currentScroll.scrollLeft}
                                        scrollTop={currentScroll.scrollTop}
                                        verticalOverscanSize={0}
                                        horizontalOverscanSize={0}
                                        ref={collectionRef}
                                    />
                                </View>
                            </>
                        );
                    }}
                </AutoSizer>
            </LoadingWrapper>
        </View>
    );
};

export default EPGView;
