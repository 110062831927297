import React, { ReactNode, useEffect, useState } from 'react';

import { log } from '@24i/nxg-core-utils/src/logger';
import type { Theme as ThemeType } from '@24i/nxg-sdk-photon/src';
import { Storage } from '@24i/nxg-sdk-quantum';
import { useThemeData } from '@24i/nxg-sdk-smartott-shared/src/context/ThemeData';

import { ThemeContext } from './context/Theme';

const SERVICE_THEME_ID = 'SERVICE_THEME_ID';

interface ThemeProps {
    theme: ThemeType;
    children: ReactNode;
}

const Theme: React.FC<ThemeProps> = ({ theme, children }) => {
    const [selectedTheme, setSelectedTheme] = useState(theme);
    const [isSelectedTheme, setIsSelectedTheme] = useState(false);
    const [fetchedThemeId, setFetchedThemeId] = useState(null);
    const themeDataClient = useThemeData();

    if (!fetchedThemeId) {
        Storage.getItem(SERVICE_THEME_ID).then((themeId) => {
            if (themeId) setFetchedThemeId(themeId);
        });
    }

    useEffect(() => {
        const getTheme = async () => {
            try {
                const fetchedTheme = await themeDataClient.fetchTheme();
                setSelectedTheme(fetchedTheme);
                setIsSelectedTheme(true);
            } catch (err) {
                log(err);
            }
        };
        if (fetchedThemeId) getTheme();
    }, [fetchedThemeId]);

    const themeContextValue = {
        theme: selectedTheme,
        isSelectedTheme,
        setTheme: setSelectedTheme,
    };

    return <ThemeContext.Provider value={themeContextValue}>{children}</ThemeContext.Provider>;
};

export default Theme;
