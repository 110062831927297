import { useMemo } from 'react';
import { isWebMobile } from '@24i/nxg-sdk-higgs/src/utils';
import { WEB_SCREEN_MAX_WIDTH } from '@24i/nxg-sdk-gluons/src/utils/constants';
import { useDimensions } from '@24i/nxg-sdk-quantum';

export const useShouldShowTopNavbar = () => {
    const { width } = useDimensions();
    const isMobile = isWebMobile();

    const shouldShowTopNavbar = useMemo(
        () => width > WEB_SCREEN_MAX_WIDTH.XS && !isMobile,
        [width, WEB_SCREEN_MAX_WIDTH, isMobile]
    );

    return {
        shouldShowTopNavbar,
    };
};
