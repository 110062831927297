import { isWebMobile } from '@24i/nxg-sdk-higgs/src/utils';
import { StyleSheet } from 'react-native';

const getBottomTabBarStyles = (theme) => ({
    menuContainer: {
        width: '100%',
        flexDirection: 'row',
        display: 'flex',
        padding: '0 4%',
        zIndex: 99,
        backgroundColor: theme.color.darkerBlur1.color,
        backdropFilter: 'saturate(180%) blur(20px)',
        position: 'fixed',
        bottom: '0px',
        top: 'initial',
        ...(!isWebMobile() && {
            '@media (min-width: 801px)': {
                display: 'none',
            },
        }),
        borderTopWidth: StyleSheet.hairlineWidth,
        borderTopColor: theme.color.contrast4,
    },
    section: {
        flexDirection: 'row',
        alignSelf: 'center',
        justifyContent: 'space-around',
        flex: 1,
    },
    sectionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flex: 1,
    },
});

export { getBottomTabBarStyles };
