import React, { useMemo } from 'react';
import { I18nManager, Text } from 'react-native';
import HTML from 'react-native-render-html';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { useDimensions } from '@24i/nxg-sdk-quantum';

import { HtmlViewProps, ListPassProps } from '../types';
import getHtmlStyle from '../styles';

// Function to clean and wrap HTML
const cleanAndWrapHtml = (html: string) => {
    const cleanedHtml = html
        .replace(/<p[^>]*>/g, `<p style="text-align: start">`)
        .replace(/&nbsp;/g, '');
    return cleanedHtml;
};

const HtmlComponent = ({
    html,
    onLinkPress,
    getStyles = getHtmlStyle,
}: HtmlViewProps): JSX.Element => {
    const { theme } = useTheme();
    const dimensions = useDimensions();
    const { baseStyle, tagsStyles, listPointStyle } = getStyles(theme, dimensions);

    const wrappedHtml = useMemo(() => {
        return I18nManager.isRTL ? cleanAndWrapHtml(html) : html;
    }, [html, I18nManager.isRTL]);

    return (
        <HTML
            html={wrappedHtml}
            containerStyle={baseStyle}
            baseFontStyle={baseStyle}
            tagsStyles={tagsStyles}
            onLinkPress={onLinkPress}
            listsPrefixesRenderers={{
                ul: () => <Text style={[tagsStyles.li, listPointStyle]}>•</Text>,
                ol: (_htmlAttribs, _children, _convertedCSSStyles, passProps: ListPassProps) => {
                    const point =
                        passProps?.index !== null && passProps?.index !== undefined
                            ? `${passProps.index + 1})`
                            : '•';
                    return <Text style={[tagsStyles.li, listPointStyle]}>{point}</Text>;
                },
            }}
        />
    );
};

export { getHtmlStyle };
export default HtmlComponent;
