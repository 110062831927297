import React, { useEffect, useState, useRef } from 'react';
import { isPlatformWeb } from 'renative';

import _ from 'lodash';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import Modal from '../../components/containers/Modal';

const noop = () => {};
const ModalContext = React.createContext({
    setModalChildren: noop,
    closeModal: noop,
    setModalProps: noop,
    setPreviousModal: noop,
});

export const ModalProvider = ({ children, ...props }) => {
    const [modalChildren, setChildren] = useState(null);
    const [modalProps, setProps] = useState({});
    const router = useRouter();
    const previousModalChildren = useRef(null);
    const onCloseModalRef = useRef(undefined);

    const setModalChildren = (modal) => {
        setChildren((prev) => {
            if (!previousModalChildren.current) {
                previousModalChildren.current = modal;
            }
            if (!_.isEqual(prev, modal)) {
                previousModalChildren.current = prev;
            }
            return modal;
        });
    };

    const setModalProps = (modal) => {
        setProps(modal);
        onCloseModalRef.current = modal?.onModalClose;
    };

    useEffect(() => {
        if (modalChildren) {
            setModalChildren(null);
            setModalProps({});
        }
    }, [isPlatformWeb && router.asPath]);

    const closeModal = () => {
        onCloseModalRef.current?.();
        setModalProps({});
        setModalChildren(null);
        previousModalChildren.current = null;
    };

    const onRequestClose = () => {
        closeModal();
    };

    const setPreviousModal = () => setModalChildren(previousModalChildren.current);

    return (
        <ModalContext.Provider
            value={{
                setModalChildren,
                closeModal,
                setModalProps,
                setPreviousModal,
                modalChildren,
            }}
        >
            {children}
            <Modal
                visible={!!modalChildren}
                setPreviousModal={setPreviousModal}
                {...props}
                {...modalProps}
                onRequestClose={onRequestClose}
            >
                {modalChildren}
            </Modal>
        </ModalContext.Provider>
    );
};

export const useModal = () => React.useContext(ModalContext);

export const withModal = (Component) => (props) =>
    (
        <ModalContext.Consumer>
            {(modalContext) => <Component {...props} {...modalContext} />}
        </ModalContext.Consumer>
    );
export { ModalContext };
