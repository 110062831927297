import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { Breakpoint } from '@24i/nxg-sdk-gluons/src/utils/styles/constants';
import type { GridStyles } from '../types';

const Grid = (theme: Theme, displayType: 'portrait' | 'landscape'): GridStyles => ({
    outerContainer: {
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        // This is the easiest way to compensate for extra space created by padding
        // the gridPackshotItem to get to the desired gutter size.
        [`@media (max-width: ${Breakpoint.SM}px)`]: {
            marginHorizontal: -(12 / 2),
        },
        [`@media (min-width: ${Breakpoint.SM}px) and (max-width: ${Breakpoint.MD}px)`]: {
            marginHorizontal: -(16 / 2),
        },
        [`@media (min-width: ${Breakpoint.MD}px) and (max-width: ${Breakpoint.L}px)`]: {
            marginHorizontal: -(20 / 2),
        },
        [`@media (min-width: ${Breakpoint.L}px)`]: {
            marginHorizontal: -(20 / 2),
        },
    },
    contentContainer: {},
    gridPackshotItem: {
        [`@media (max-width: ${Breakpoint.SM}px)`]: {
            width: displayType === 'portrait' ? '33%' : '50%',
            padding: 12 / 2,
        },
        [`@media (min-width: ${Breakpoint.SM}px) and (max-width: ${Breakpoint.MD}px)`]: {
            width: displayType === 'portrait' ? '25%' : '33%',
            padding: 16 / 2,
        },
        [`@media (min-width: ${Breakpoint.MD}px) and (max-width: ${Breakpoint.L}px)`]: {
            width: displayType === 'portrait' ? '20%' : '25%',
            padding: 20 / 2,
        },
        [`@media (min-width: ${Breakpoint.L}px)`]: {
            width: displayType === 'portrait' ? '14%' : '20%',
            padding: 20 / 2,
        },
        // width: getPacksotWidth(),
    },
    titleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 19,
    },
    title: {
        paddingRight: 8,
        color: theme.color.textPrimary,
        left: 0,
        ...getFont(theme, 'h3'),
    },
    loaderContainer: {
        position: 'absolute',
        bottom: 10,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    loader: {
        backgroundColor: 'none',
    },
});

export default Grid;
