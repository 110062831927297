import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import { ASSET_TYPE } from '@24i/nxg-sdk-photon';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@24i/nxg-sdk-higgs';
import React, { FC } from 'react';
import { getPlayButtonTestID } from '../../test-utils';
import getBannerCarouselItemStyles from '../Item/styles';
import useBlockedModal from '../../../BlockedModal/hooks';
import { getMainButtonData } from './utils';
import { MainButtonProps } from './types';

export const MainButton: FC<MainButtonProps> = ({
    item,
    hasPlaybackAction,
    isGeoBlocked,
    isPurchased,
    buttonTitle,
    index,
    onPlayPress,
    onInfoPress,
}) => {
    const { theme } = useTheme();
    const { openBlockedModal } = useBlockedModal();
    const { t } = useTranslation();

    if (!hasPlaybackAction || !isPurchased) return <></>;

    const styles = getBannerCarouselItemStyles(theme);

    const shouldGoToPlayer =
        hasPlaybackAction ||
        (item.type === ASSET_TYPE.CHANNEL && item.broadcastMetadata?.id == null);

    const { title, icon, onPress } = getMainButtonData({
        isGeoBlocked,
        shouldGoToPlayer,
        item,
        onInfoPress,
        onPlayPress,
        t,
        openBlockedModal,
        buttonTitle,
    });

    return (
        <ActionButton
            testID={getPlayButtonTestID(item.type, index + 1)}
            iconName={icon.iconName}
            iconSize={icon.iconSize}
            additionalContainerStyles={[styles.primaryButtonContainerWithTitle]}
            onPress={onPress}
            title={title}
        />
    );
};
