import Account from './defaultIcons/account.svg';
import Accountbkstgstateselected from './defaultIcons/account_bkstgState_selected.svg';
import Confirm from './defaultIcons/confirm.svg';
import Creditcard from './defaultIcons/creditCard.svg';
import Creditcardbkstgstateselected from './defaultIcons/creditCard_bkstgState_selected.svg';
import Downloads from './defaultIcons/downloads.svg';
import Downloadsbkstgstateselected from './defaultIcons/downloads_bkstgState_selected.svg';
import Email from './defaultIcons/email.svg';
import Emailbkstgstateselected from './defaultIcons/email_bkstgState_selected.svg';
import Epg from './defaultIcons/epg.svg';
import Epgbkstgstateselected from './defaultIcons/epg_bkstgState_selected.svg';
import Filmroll from './defaultIcons/filmRoll.svg';
import Filmrollbkstgstateselected from './defaultIcons/filmRoll_bkstgState_selected.svg';
import Hamburger from './defaultIcons/hamburger.svg';
import Hamburgerbkstgstateselected from './defaultIcons/hamburger_bkstgState_selected.svg';
import Heart from './defaultIcons/heart.svg';
import Heartbkstgstateselected from './defaultIcons/heart_bkstgState_selected.svg';
import History from './defaultIcons/history.svg';
import Historybkstgstateselected from './defaultIcons/history_bkstgState_selected.svg';
import Home from './defaultIcons/home.svg';
import Homebkstgstateselected from './defaultIcons/home_bkstgState_selected.svg';
import Info from './defaultIcons/info.svg';
import Infobkstgstateselected from './defaultIcons/info_bkstgState_selected.svg';
import Language from './defaultIcons/language.svg';
import Languagebkstgstateselected from './defaultIcons/language_bkstgState_selected.svg';
import List from './defaultIcons/list.svg';
import Listbkstgstateselected from './defaultIcons/list_bkstgState_selected.svg';
import Livetv from './defaultIcons/liveTV.svg';
import Livetvbkstgstateselected from './defaultIcons/liveTV_bkstgState_selected.svg';
import Moreinfo from './defaultIcons/more-info.svg';
import Movieclap from './defaultIcons/movieClap.svg';
import Movieclapbkstgstateselected from './defaultIcons/movieClap_bkstgState_selected.svg';
import Mrcuarrowgeneric from './defaultIcons/mrcu_arrow_generic.svg';
import Onbuttonprimarygeoblocked from './defaultIcons/onButtonPrimary-geoblocked.svg';
import Onbuttonprimarylocked from './defaultIcons/onButtonPrimary-locked.svg';
import Onbuttonprimaryplay from './defaultIcons/onButtonPrimary-play.svg';
import Onbuttonprimarypurchase from './defaultIcons/onButtonPrimary-purchase.svg';
import Onbuttonprimaryrecord from './defaultIcons/onButtonPrimary-record.svg';
import Onbuttonprimaryrecordmultiple from './defaultIcons/onButtonPrimary-recordMultiple.svg';
import Onbuttonprimaryrecordmultiplecancel from './defaultIcons/onButtonPrimary-recordMultiple_cancel.svg';
import Onbuttonprimaryrecordcancel from './defaultIcons/onButtonPrimary-record_cancel.svg';
import Onbuttonprimaryrent from './defaultIcons/onButtonPrimary-rent.svg';
import Onbuttonprimaryupsell from './defaultIcons/onButtonPrimary-upsell.svg';
import OnButtonPrimaryStartOver from './defaultIcons/onButtonPrimary-startOver.svg';
import OnButtonPrimarypurchasemultipleOptions from './defaultIcons/onButtonPrimary-purchase_multipleOptions.svg';
import OnButtonPrimaryinfo from './defaultIcons/onButtonPrimary-info.svg';
import Onbuttonsecondaryedit from './defaultIcons/onButtonSecondary-edit.svg';
import OnbuttonsecondarydownloadLoading from './defaultIcons/onButtonSecondary-downloadLoading.svg';
import OnbuttonsecondarydownloadFailed from './defaultIcons/onButtonSecondary-downloadFailed.svg';
import OnButtonPrimarycheckmark from './defaultIcons/onButtonPrimary-checkmark.svg';
import OnbuttonsecondarydownloadDownloading from './defaultIcons/onButtonSecondary-downloadDownloading.svg';
import OnbuttonsecondarydownloadDownloaded from './defaultIcons/onButtonSecondary-downloadDownloaded.svg';
import Onbuttonsecondarydelete from './defaultIcons/onButtonSecondary-delete.svg';
import Onbuttonsecondarypurchasemultipleoptions from './defaultIcons/onButtonSecondary-Purchase (Multiple Options).svg';
import Onbuttonsecondarydownload from './defaultIcons/onButtonSecondary-download.svg';
import Onbuttonsecondarydownloaddownloaded from './defaultIcons/onButtonSecondary-download_downloaded.svg';
import Onbuttonsecondarydownloaddownloading from './defaultIcons/onButtonSecondary-download_downloading.svg';
import Onbuttonsecondarydownloadfailed from './defaultIcons/onButtonSecondary-download_failed.svg';
import Onbuttonsecondarydownloadloading from './defaultIcons/onButtonSecondary-download_loading.svg';
import Onbuttonsecondarydownloadstart from './defaultIcons/onButtonSecondary-download_start.svg';
import OnbuttonsecondaryGeoBlocked from './defaultIcons/onButtonSecondary-geoblocked.svg';
import Onbuttonsecondaryinfo from './defaultIcons/onButtonSecondary-info.svg';
import Onbuttonsecondaryplay from './defaultIcons/onButtonSecondary-play.svg';
import Onbuttonsecondarypurchase from './defaultIcons/onButtonSecondary-purchase.svg';
import Onbuttonsecondaryrecord from './defaultIcons/onButtonSecondary-record.svg';
import Onbuttonsecondaryrecordmultiple from './defaultIcons/onButtonSecondary-recordMultiple.svg';
import Onbuttonsecondaryrecordmultiplecancel from './defaultIcons/onButtonSecondary-recordMultiple_cancel.svg';
import Onbuttonsecondaryrecordcancel from './defaultIcons/onButtonSecondary-record_cancel.svg';
import Onbuttonsecondaryreminderadd from './defaultIcons/onButtonSecondary-reminder_add.svg';
import Onbuttonsecondaryreminderadded from './defaultIcons/onButtonSecondary-reminder_added.svg';
import Onbuttonsecondaryshare from './defaultIcons/onButtonSecondary-share.svg';
import Onbuttonsecondarystartover from './defaultIcons/onButtonSecondary-startOver.svg';
import Onbuttonsecondarywatchlistadd from './defaultIcons/onButtonSecondary-watchlist_add.svg';
import Onbuttonsecondarywatchlistadded from './defaultIcons/onButtonSecondary-watchlist_added.svg';
import OnbuttonsecondaryArrowUp from './defaultIcons/onButtonSecondary-arrowUp.svg';
import OnbuttonsecondaryArrowDown from './defaultIcons/onButtonSecondary-arrowDown.svg';
import Onbuttontertiaryclose from './defaultIcons/onButtonTertiary-close.svg';
import Onbuttontertiarydelete from './defaultIcons/onButtonTertiary-delete.svg';
import Onbuttontertiarydropdown from './defaultIcons/onButtonTertiary-dropdown.svg';
import Onbuttontertiarydownload from './defaultIcons/onButtonTertiary-download.svg';
import Onbuttontertiarydownloaddownloaded from './defaultIcons/onButtonTertiary-download_downloaded.svg';
import Onbuttontertiarydownloaddownloading from './defaultIcons/onButtonTertiary-download_downloading.svg';
import Onbuttontertiarydownloadfailed from './defaultIcons/onButtonTertiary-download_failed.svg';
import Onbuttontertiarydownloadloading from './defaultIcons/onButtonTertiary-download_loading.svg';
import Onbuttontertiarydownloadstart from './defaultIcons/onButtonTertiary-download_start.svg';
import Onbuttontertiaryedit from './defaultIcons/onButtonTertiary-edit.svg';
import Onbuttontertiaryinfo from './defaultIcons/onButtonTertiary-info.svg';
import Onbuttontertiaryrecord from './defaultIcons/onButtonTertiary-record.svg';
import Onbuttontertiaryrecordmultiple from './defaultIcons/onButtonTertiary-recordMultiple.svg';
import Onbuttontertiaryrecordmultiplecancel from './defaultIcons/onButtonTertiary-recordMultiple_cancel.svg';
import Onbuttontertiaryrecordcancel from './defaultIcons/onButtonTertiary-record_cancel.svg';
import Onbuttontertiaryreminderadd from './defaultIcons/onButtonTertiary-reminder_add.svg';
import Onbuttontertiaryreminderadded from './defaultIcons/onButtonTertiary-reminder_added.svg';
import Onbuttontertiaryshare from './defaultIcons/onButtonTertiary-share.svg';
import Onbuttontertiarystartover from './defaultIcons/onButtonTertiary-startOver.svg';
import Onbuttontertiarywatchlistadd from './defaultIcons/onButtonTertiary-watchlist_add.svg';
import Onbuttontertiarywatchlistadded from './defaultIcons/onButtonTertiary-watchlist_added.svg';
import Onbuttontertiarysort from './defaultIcons/onButtonTertiary-sort.svg';
import OnbuttontertiaryskipNext from './defaultIcons/onButtonTertiary-skipNext.svg';
import OnbuttontertiaryratingEmpty from './defaultIcons/onButtonTertiary-ratingEmpty.svg';
import Onbuttontertiaryrating from './defaultIcons/onButtonTertiary-rating.svg';
import Onbuttontertiaryfilter from './defaultIcons/onButtonTertiary-filter.svg';
import Ondarkdropdown from './defaultIcons/onDark-Dropdown.svg';
import Ondarkgotolive from './defaultIcons/onDark-GoToLive.svg';
import Ondarkpurchase from './defaultIcons/onDark-Purchase.svg';
import Ondarksort from './defaultIcons/onDark-Sort.svg';
import Ondarkairplay from './defaultIcons/onDark-airplay.svg';
import Ondarkallchannels from './defaultIcons/onDark-allChannels.svg';
import Ondarkallepisodes from './defaultIcons/onDark-allEpisodes.svg';
import Ondarkback from './defaultIcons/onDark-back.svg';
import Ondarkbackrtl from './defaultIcons/onDark-back_rtl.svg';
import Ondarkcatchup from './defaultIcons/onDark-catchup.svg';
import Ondarkchromecast from './defaultIcons/onDark-chromecast.svg';
import Ondarkchromecastconnected from './defaultIcons/onDark-chromecast_connected.svg';
import Ondarkclose from './defaultIcons/onDark-close.svg';
import Ondarkconfirm from './defaultIcons/onDark-confirm.svg';
import Ondarkcontrolfastforward from './defaultIcons/onDark-control_fastForward.svg';
import Ondarkcontrolforward10 from './defaultIcons/onDark-control_forward10.svg';
import Ondarkcontrolinfo from './defaultIcons/onDark-control_info.svg';
import Ondarkcontrolpause from './defaultIcons/onDark-control_pause.svg';
import Ondarkcontrolplay from './defaultIcons/onDark-control_play.svg';
import Ondarkcontrolreplay10 from './defaultIcons/onDark-control_replay10.svg';
import Ondarkcontrolrewind from './defaultIcons/onDark-control_rewind.svg';
import Ondarkcontrolskipnext from './defaultIcons/onDark-control_skipNext.svg';
import Ondarkcontrolskipprevious from './defaultIcons/onDark-control_skipPrevious.svg';
import Ondarkcontrolstop from './defaultIcons/onDark-control_stop.svg';
import Ondarkcontrolvolumedown from './defaultIcons/onDark-control_volume_down.svg';
import Ondarkcontrolvolumemute from './defaultIcons/onDark-control_volume_mute.svg';
import Ondarkcontrolvolumeoff from './defaultIcons/onDark-control_volume_off.svg';
import Ondarkcontrolvolumeup from './defaultIcons/onDark-control_volume_up.svg';
import Ondarkdownloaddownloaded from './defaultIcons/onDark-download_downloaded.svg';
import Ondarkdownloaddownloading from './defaultIcons/onDark-download_downloading.svg';
import Ondarkdownloadfailed from './defaultIcons/onDark-download_failed.svg';
import Ondarkdownloadloading from './defaultIcons/onDark-download_loading.svg';
import Ondarkdownloadstart from './defaultIcons/onDark-download_start.svg';
import Ondarkfullscreen from './defaultIcons/onDark-fullscreen.svg';
import Ondarkfullscreenexit from './defaultIcons/onDark-fullscreen_exit.svg';
import Ondarkoverflow from './defaultIcons/onDark-overflow.svg';
import Ondarkpictureinpicture from './defaultIcons/onDark-pictureInPicture.svg';
import Ondarkratingempty from './defaultIcons/onDark-rating-empty.svg';
import Ondarkrating from './defaultIcons/onDark-rating.svg';
import Ondarkrecord from './defaultIcons/onDark-record.svg';
import Ondarkrecordmultiple from './defaultIcons/onDark-recordMultiple.svg';
import Ondarkrecordmultiplecancel from './defaultIcons/onDark-recordMultiple_cancel.svg';
import Ondarkrecordcancel from './defaultIcons/onDark-record_cancel.svg';
import Ondarkrestart from './defaultIcons/onDark-restart.svg';
import Ondarkselect from './defaultIcons/onDark-select.svg';
import Ondarksettings from './defaultIcons/onDark-settings.svg';
import Ondarkshare from './defaultIcons/onDark-share.svg';
import Ondarksubtitles from './defaultIcons/onDark-subtitles.svg';
import Ondarktvguide from './defaultIcons/onDark-tvGuide.svg';
import Ondarkvideoquality from './defaultIcons/onDark-videoQuality.svg';
import Ondarkgeoblocked from './defaultIcons/onDark-geoblocked.svg';
import Ondarkfilter from './defaultIcons/onDark-filter.svg';
import Ondarkcontrol_upsell from './defaultIcons/onDark-control_upsell.svg';
import Ondarkcontrol_downloadStart from './defaultIcons/onDark-control_downloadStart.svg';
import Ondarkcontrol_downloadLoading from './defaultIcons/onDark-control_downloadLoading.svg';
import OndarkcontroldownloadFailed from './defaultIcons/onDark-control_downloadFailed.svg';
import OndarkcontroldownloadDownloading from './defaultIcons/onDark-control_downloadDownloading.svg';
import OndarkcontroldownloadDownloaded from './defaultIcons/onDark-control_downloadDownloaded.svg';
import OndarkbackToLive from './defaultIcons/onDark-backToLive.svg';
import Onlabelpurchase from './defaultIcons/onLabel-Purchase.svg';
import Onlabelcatchup from './defaultIcons/onLabel-catchup.svg';
import Onlabelgeoblocked from './defaultIcons/onLabel-geoblocked.svg';
import Onlabellocked from './defaultIcons/onLabel-locked.svg';
import Onlabelrecord from './defaultIcons/onLabel-record.svg';
import Onlabelrecordmultiple from './defaultIcons/onLabel-recordMultiple.svg';
import Onlabelreminder from './defaultIcons/onLabel-reminder.svg';
import Onprimarybackspace from './defaultIcons/onPrimary-Backspace.svg';
import Onprimarycalendar from './defaultIcons/onPrimary-Calendar.svg';
import Onprimaryimage from './defaultIcons/onPrimary-Image.svg';
import Onprimarysort from './defaultIcons/onPrimary-Sort.svg';
import Onprimaryairplay from './defaultIcons/onPrimary-airplay.svg';
import Onprimaryback from './defaultIcons/onPrimary-back.svg';
import Onprimarybackrtl from './defaultIcons/onPrimary-back_rtl.svg';
import Onprimarycapslockall from './defaultIcons/onPrimary-capslockAll.svg';
import Onprimarycapslockoff from './defaultIcons/onPrimary-capslockOff.svg';
import Onprimarycapslockonce from './defaultIcons/onPrimary-capslockOnce.svg';
import Onprimarychromecast from './defaultIcons/onPrimary-chromecast.svg';
import Onprimarychromecastconnected from './defaultIcons/onPrimary-chromecast_connected.svg';
import Onprimaryclear from './defaultIcons/onPrimary-clear.svg';
import Onprimaryclose from './defaultIcons/onPrimary-close.svg';
import Onprimarycollapsedown from './defaultIcons/onPrimary-collapseDown.svg';
import Onprimaryconfirm from './defaultIcons/onPrimary-confirm.svg';
import Onprimarycontrolforward10 from './defaultIcons/onPrimary-control_forward10.svg';
import Onprimarycontrolpause from './defaultIcons/onPrimary-control_pause.svg';
import Onprimarycontrolplay from './defaultIcons/onPrimary-control_play.svg';
import Onprimarycontrolreplay10 from './defaultIcons/onPrimary-control_replay10.svg';
import Onprimarycontrolskipnext from './defaultIcons/onPrimary-control_skipNext.svg';
import Onprimarycontrolskipprevious from './defaultIcons/onPrimary-control_skipPrevious.svg';
import Onprimarycontrolvolumehigh from './defaultIcons/onPrimary-control_volumeHigh.svg';
import Onprimarycontrolvolumelow from './defaultIcons/onPrimary-control_volumeLow.svg';
import Onprimarycontrolvolumemid from './defaultIcons/onPrimary-control_volumeMid.svg';
import Onprimarycontrolvolumemute from './defaultIcons/onPrimary-control_volumeMute.svg';
import Onprimarydelete from './defaultIcons/onPrimary-delete.svg';
import Onprimarydevicefamily from './defaultIcons/onPrimary-device_family.svg';
import Onprimarydevicegameconsole from './defaultIcons/onPrimary-device_gameConsole.svg';
import Onprimarydevicelaptop from './defaultIcons/onPrimary-device_laptop.svg';
import Onprimarydevicesmartphone from './defaultIcons/onPrimary-device_smartphone.svg';
import Onprimarydevicesmartwatch from './defaultIcons/onPrimary-device_smartwatch.svg';
import Onprimarydevicetablet from './defaultIcons/onPrimary-device_tablet.svg';
import Onprimarydevicetv from './defaultIcons/onPrimary-device_tv.svg';
import Onprimarydownloaddownloaded from './defaultIcons/onPrimary-download_downloaded.svg';
import Onprimarydownloaddownloading from './defaultIcons/onPrimary-download_downloading.svg';
import Onprimarydownloadfailed from './defaultIcons/onPrimary-download_failed.svg';
import Onprimarydownloadloading from './defaultIcons/onPrimary-download_loading.svg';
import Onprimarydownloadstart from './defaultIcons/onPrimary-download_start.svg';
import Onprimaryedit from './defaultIcons/onPrimary-edit.svg';
import Onprimaryadd from './defaultIcons/onPrimary-add.svg';
import Onprimaryerror from './defaultIcons/onPrimary-error.svg';
import Onprimaryexpiration from './defaultIcons/onPrimary-expiration.svg';
import Onprimaryfieldclear from './defaultIcons/onPrimary-field_clear.svg';
import Onprimaryfieldsearch from './defaultIcons/onPrimary-field_search.svg';
import Onprimaryfilter from './defaultIcons/onPrimary-filter.svg';
import Onprimarygeoblocked from './defaultIcons/onPrimary-geoblocked.svg';
import Onprimarylock from './defaultIcons/onPrimary-lock.svg';
import Onprimarymypin from './defaultIcons/onPrimary-myPin.svg';
import Onprimaryopenexternal from './defaultIcons/onPrimary-openExternal.svg';
import Onprimaryoverflow from './defaultIcons/onPrimary-overflow.svg';
import Onprimaryparentalcontrol from './defaultIcons/onPrimary-parental_control.svg';
import Onprimarypaymenthistory from './defaultIcons/onPrimary-payment_history.svg';
import Onprimarypaymentmethod from './defaultIcons/onPrimary-payment_method.svg';
import Onprimarypurchase from './defaultIcons/onPrimary-purchase.svg';
import Onprimaryrating from './defaultIcons/onPrimary-rating.svg';
import Onprimaryratingempty from './defaultIcons/onPrimary-rating–empty.svg';
import Onprimaryscrolldown from './defaultIcons/onPrimary-scroll_down.svg';
import Onprimaryscrollup from './defaultIcons/onPrimary-scroll_up.svg';
import Onprimaryseeall from './defaultIcons/onPrimary-seeAll.svg';
import Onprimaryselect from './defaultIcons/onPrimary-select.svg';
import Onprimaryshare from './defaultIcons/onPrimary-share.svg';
import Onprimarysignout from './defaultIcons/onPrimary-signOut.svg';
import Onprimaryspinner from './defaultIcons/onPrimary-spinner.svg';
import Onprimarysubpage from './defaultIcons/onPrimary-subpage.svg';
import Onprimarysubpagertl from './defaultIcons/onPrimary-subpage_rtl.svg';
import Onprimarysubscription from './defaultIcons/onPrimary-subscription.svg';
import Onprimaryviewchannel from './defaultIcons/onPrimary-viewChannel.svg';
import Onprimaryviewtime from './defaultIcons/onPrimary-viewTime.svg';

import OnprimarytechnicalInfo from './defaultIcons/onPrimary-technicalInfo.svg';
import Onprimarysuccess from './defaultIcons/onPrimary-success.svg';
import OnprimarysignIn from './defaultIcons/onPrimary-signIn.svg';
import Onprimaryreminders from './defaultIcons/onPrimary-reminders.svg';
import Onprimaryrecordings from './defaultIcons/onPrimary-recordings.svg';
import OnprimaryratingEmpty from './defaultIcons/onPrimary-ratingEmpty.svg';
import OnprimaryplaybackSpeed100 from './defaultIcons/onPrimary-playbackSpeed100.svg';
import Onprimaryplay from './defaultIcons/onPrimary-play.svg';
import OnprimarypaymentMethod from './defaultIcons/onPrimary-paymentMethod.svg';
import OnprimaryparentalControl from './defaultIcons/onPrimary-parentalControl.svg';
import Onprimaryorder from './defaultIcons/onPrimary-order.svg';
import Onprimarylink from './defaultIcons/onPrimary-link.svg';
import Onprimarylanguage from './defaultIcons/onPrimary-language.svg';
import Onprimaryinfo from './defaultIcons/onPrimary-info.svg';
import Onprimaryhistory from './defaultIcons/onPrimary-history.svg';
import OnprimaryfavoriteChannelBorder from './defaultIcons/onPrimary-favoriteChannelBorder.svg';
import OnprimaryfavoriteChannel from './defaultIcons/onPrimary-favoriteChannel.svg';
import OnprimarydownloadStart from './defaultIcons/onPrimary-downloadStart.svg';
import OnprimarydownloadLoading from './defaultIcons/onPrimary-downloadLoading.svg';
import OnprimarydownloadFailed from './defaultIcons/onPrimary-downloadFailed.svg';
import OnprimarydownloadDownloading from './defaultIcons/onPrimary-downloadDownloading.svg';
import OnprimarydownloadDownloaded from './defaultIcons/onPrimary-downloadDownloaded.svg';
import Onprimarydownload from './defaultIcons/onPrimary-download.svg';
import Onprimarycontrol_rewind10 from './defaultIcons/onPrimary-control_rewind10.svg';
import Onprimaryactor from './defaultIcons/onPrimary-actor.svg';
import Ontilemainbuttonpurchase from './defaultIcons/onTileMainButton-Purchase.svg';
import Ontilemainbuttonedit from './defaultIcons/onTileMainButton-edit.svg';
import Ontilemainbuttonpause from './defaultIcons/onTileMainButton-pause.svg';
import Ontilemainbuttonplay from './defaultIcons/onTileMainButton-play.svg';
import Ontilemainbuttonpdelete from './defaultIcons/onTileMainButton-delete.svg';
import Ontilemainbuttonpcancel from './defaultIcons/onTileMainButton-cancel.svg';
import Phone from './defaultIcons/phone.svg';
import Phonebkstgstateselected from './defaultIcons/phone_bkstgState_selected.svg';
import Playprimary from './defaultIcons/play-primary.svg';
import Playsecondary from './defaultIcons/play-secondary.svg';
import Play from './defaultIcons/play.svg';
import Playbkstgstateselected from './defaultIcons/play_bkstgState_selected.svg';
import Recordings from './defaultIcons/recordings.svg';
import Recordingsbkstgstateselected from './defaultIcons/recordings_bkstgState_selected.svg';
import Reminders from './defaultIcons/reminders.svg';
import Remindersbkstgstateselected from './defaultIcons/reminders_bkstgState_selected.svg';
import Replay from './defaultIcons/replay.svg';
import Replaybkstgstateselected from './defaultIcons/replay_bkstgState_selected.svg';
import Search from './defaultIcons/search.svg';
import Searchbkstgstateselected from './defaultIcons/search_bkstgState_selected.svg';
import Settings from './defaultIcons/settings.svg';
import Settingsbkstgstateselected from './defaultIcons/settings_bkstgState_selected.svg';
import Showall from './defaultIcons/show-all.svg';
import Signout from './defaultIcons/sign-out.svg';
import Socialfacebook from './defaultIcons/social_facebook.svg';
import Socialinstagram from './defaultIcons/social_instagram.svg';
import Socialtwitter from './defaultIcons/social_twitter.svg';
import Socialyoutube from './defaultIcons/social_youtube.svg';
import Star from './defaultIcons/star.svg';
import Starbkstgstateselected from './defaultIcons/star_bkstgState_selected.svg';
import Streaming from './defaultIcons/streaming.svg';
import Streamingbkstgstateselected from './defaultIcons/streaming_bkstgState_selected.svg';
import Tvguide from './defaultIcons/tv-guide.svg';
import Tv from './defaultIcons/tv.svg';
import Tvbkstgstateselected from './defaultIcons/tv_bkstgState_selected.svg';
import Videolibrary from './defaultIcons/videoLibrary.svg';
import Videolibrarybkstgstateselected from './defaultIcons/videoLibrary_bkstgState_selected.svg';
import Videocam from './defaultIcons/videocam.svg';
import Videocambkstgstateselected from './defaultIcons/videocam_bkstgState_selected.svg';
import Vod from './defaultIcons/vod.svg';
import Vodbkstgstateselected from './defaultIcons/vod_bkstgState_selected.svg';
import Watchlater from './defaultIcons/watchLater.svg';
import Watchlaterbkstgstateselected from './defaultIcons/watchLater_bkstgState_selected.svg';

export const defaultIconsMap = {
    'account.svg': Account,
    'account_bkstgState_selected.svg': Accountbkstgstateselected,
    'confirm.svg': Confirm,
    'creditCard.svg': Creditcard,
    'creditCard_bkstgState_selected.svg': Creditcardbkstgstateselected,
    'downloads.svg': Downloads,
    'downloads_bkstgState_selected.svg': Downloadsbkstgstateselected,
    'email.svg': Email,
    'email_bkstgState_selected.svg': Emailbkstgstateselected,
    'epg.svg': Epg,
    'epg_bkstgState_selected.svg': Epgbkstgstateselected,
    'filmRoll.svg': Filmroll,
    'filmRoll_bkstgState_selected.svg': Filmrollbkstgstateselected,
    'hamburger.svg': Hamburger,
    'hamburger_bkstgState_selected.svg': Hamburgerbkstgstateselected,
    'heart.svg': Heart,
    'heart_bkstgState_selected.svg': Heartbkstgstateselected,
    'history.svg': History,
    'history_bkstgState_selected.svg': Historybkstgstateselected,
    'home.svg': Home,
    'home_bkstgState_selected.svg': Homebkstgstateselected,
    'info.svg': Info,
    'info_bkstgState_selected.svg': Infobkstgstateselected,
    'language.svg': Language,
    'language_bkstgState_selected.svg': Languagebkstgstateselected,
    'list.svg': List,
    'list_bkstgState_selected.svg': Listbkstgstateselected,
    'liveTV.svg': Livetv,
    'liveTV_bkstgState_selected.svg': Livetvbkstgstateselected,
    'more-info.svg': Moreinfo,
    'movieClap.svg': Movieclap,
    'movieClap_bkstgState_selected.svg': Movieclapbkstgstateselected,
    'mrcu_arrow_generic.svg': Mrcuarrowgeneric,
    'onButtonPrimary-geoblocked.svg': Onbuttonprimarygeoblocked,
    'onButtonPrimary-locked.svg': Onbuttonprimarylocked,
    'onButtonPrimary-play.svg': Onbuttonprimaryplay,
    'onButtonPrimary-purchase.svg': Onbuttonprimarypurchase,
    'onButtonPrimary-record.svg': Onbuttonprimaryrecord,
    'onButtonPrimary-recordMultiple.svg': Onbuttonprimaryrecordmultiple,
    'onButtonPrimary-recordMultiple_cancel.svg': Onbuttonprimaryrecordmultiplecancel,
    'onButtonPrimary-record_cancel.svg': Onbuttonprimaryrecordcancel,
    'onButtonPrimary-rent.svg': Onbuttonprimaryrent,
    'onButtonPrimary-upsell.svg': Onbuttonprimaryupsell,
    'onButtonPrimary-startOver.svg': OnButtonPrimaryStartOver,
    'onButtonPrimary-purchase_multipleOptions.svg': OnButtonPrimarypurchasemultipleOptions,
    'onButtonPrimary-info.svg': OnButtonPrimaryinfo,
    'onButtonPrimary-checkmark.svg': OnButtonPrimarycheckmark,
    'onButtonSecondary-Purchase (Multiple Options).svg': Onbuttonsecondarypurchasemultipleoptions,
    'onButtonSecondary-download.svg': Onbuttonsecondarydownload,
    'onButtonSecondary-download_downloaded.svg': Onbuttonsecondarydownloaddownloaded,
    'onButtonSecondary-download_downloading.svg': Onbuttonsecondarydownloaddownloading,
    'onButtonSecondary-download_failed.svg': Onbuttonsecondarydownloadfailed,
    'onButtonSecondary-download_loading.svg': Onbuttonsecondarydownloadloading,
    'onButtonSecondary-download_start.svg': Onbuttonsecondarydownloadstart,
    'onButtonSecondary-geoblocked.svg': OnbuttonsecondaryGeoBlocked,
    'onButtonSecondary-info.svg': Onbuttonsecondaryinfo,
    'onButtonSecondary-play.svg': Onbuttonsecondaryplay,
    'onButtonSecondary-purchase.svg': Onbuttonsecondarypurchase,
    'onButtonSecondary-record.svg': Onbuttonsecondaryrecord,
    'onButtonSecondary-recordMultiple.svg': Onbuttonsecondaryrecordmultiple,
    'onButtonSecondary-recordMultiple_cancel.svg': Onbuttonsecondaryrecordmultiplecancel,
    'onButtonSecondary-record_cancel.svg': Onbuttonsecondaryrecordcancel,
    'onButtonSecondary-reminder_add.svg': Onbuttonsecondaryreminderadd,
    'onButtonSecondary-reminder_added.svg': Onbuttonsecondaryreminderadded,
    'onButtonSecondary-share.svg': Onbuttonsecondaryshare,
    'onButtonSecondary-startOver.svg': Onbuttonsecondarystartover,
    'onButtonSecondary-watchlist_add.svg': Onbuttonsecondarywatchlistadd,
    'onButtonSecondary-watchlist_added.svg': Onbuttonsecondarywatchlistadded,
    'onButtonSecondary-arrowUp.svg': OnbuttonsecondaryArrowUp,
    'onButtonSecondary-arrowDown.svg': OnbuttonsecondaryArrowDown,
    'onButtonSecondary-edit.svg': Onbuttonsecondaryedit,
    'onButtonSecondary-downloadLoading.svg': OnbuttonsecondarydownloadLoading,
    'onButtonSecondary-downloadFailed.svg': OnbuttonsecondarydownloadFailed,
    'onButtonSecondary-downloadDownloading.svg': OnbuttonsecondarydownloadDownloading,
    'onButtonSecondary-downloadDownloaded.svg': OnbuttonsecondarydownloadDownloaded,
    'onButtonSecondary-delete.svg': Onbuttonsecondarydelete,
    'onButtonTertiary-close.svg': Onbuttontertiaryclose,
    'onButtonTertiary-delete.svg': Onbuttontertiarydelete,
    'onButtonTertiary-dropdown.svg': Onbuttontertiarydropdown,
    'onButtonTertiary-download.svg': Onbuttontertiarydownload,
    'onButtonTertiary-download_downloaded.svg': Onbuttontertiarydownloaddownloaded,
    'onButtonTertiary-download_downloading.svg': Onbuttontertiarydownloaddownloading,
    'onButtonTertiary-download_failed.svg': Onbuttontertiarydownloadfailed,
    'onButtonTertiary-download_loading.svg': Onbuttontertiarydownloadloading,
    'onButtonTertiary-download_start.svg': Onbuttontertiarydownloadstart,
    'onButtonTertiary-edit.svg': Onbuttontertiaryedit,
    'onButtonTertiary-info.svg': Onbuttontertiaryinfo,
    'onButtonTertiary-record.svg': Onbuttontertiaryrecord,
    'onButtonTertiary-recordMultiple.svg': Onbuttontertiaryrecordmultiple,
    'onButtonTertiary-recordMultiple_cancel.svg': Onbuttontertiaryrecordmultiplecancel,
    'onButtonTertiary-record_cancel.svg': Onbuttontertiaryrecordcancel,
    'onButtonTertiary-reminder_add.svg': Onbuttontertiaryreminderadd,
    'onButtonTertiary-reminder_added.svg': Onbuttontertiaryreminderadded,
    'onButtonTertiary-share.svg': Onbuttontertiaryshare,
    'onButtonTertiary-startOver.svg': Onbuttontertiarystartover,
    'onButtonTertiary-watchlist_add.svg': Onbuttontertiarywatchlistadd,
    'onButtonTertiary-watchlist_added.svg': Onbuttontertiarywatchlistadded,
    'onButtonTertiary-sort.svg': Onbuttontertiarysort,
    'onButtonTertiary-skipNext.svg': OnbuttontertiaryskipNext,
    'onButtonTertiary-ratingEmpty.svg': OnbuttontertiaryratingEmpty,
    'onButtonTertiary-rating.svg': Onbuttontertiaryrating,
    'onButtonTertiary-filter.svg': Onbuttontertiaryfilter,
    'onDark-Dropdown.svg': Ondarkdropdown,
    'onDark-GoToLive.svg': Ondarkgotolive,
    'onDark-Purchase.svg': Ondarkpurchase,
    'onDark-Sort.svg': Ondarksort,
    'onDark-airplay.svg': Ondarkairplay,
    'onDark-allChannels.svg': Ondarkallchannels,
    'onDark-allEpisodes.svg': Ondarkallepisodes,
    'onDark-back.svg': Ondarkback,
    'onDark-back_rtl.svg': Ondarkbackrtl,
    'onDark-catchup.svg': Ondarkcatchup,
    'onDark-chromecast.svg': Ondarkchromecast,
    'onDark-chromecast_connected.svg': Ondarkchromecastconnected,
    'onDark-close.svg': Ondarkclose,
    'onDark-confirm.svg': Ondarkconfirm,
    'onDark-control_fastForward.svg': Ondarkcontrolfastforward,
    'onDark-control_forward10.svg': Ondarkcontrolforward10,
    'onDark-control_info.svg': Ondarkcontrolinfo,
    'onDark-control_pause.svg': Ondarkcontrolpause,
    'onDark-control_play.svg': Ondarkcontrolplay,
    'onDark-control_replay10.svg': Ondarkcontrolreplay10,
    'onDark-control_rewind.svg': Ondarkcontrolrewind,
    'onDark-control_skipNext.svg': Ondarkcontrolskipnext,
    'onDark-control_skipPrevious.svg': Ondarkcontrolskipprevious,
    'onDark-control_stop.svg': Ondarkcontrolstop,
    'onDark-control_volume_down.svg': Ondarkcontrolvolumedown,
    'onDark-control_volume_mute.svg': Ondarkcontrolvolumemute,
    'onDark-control_volume_off.svg': Ondarkcontrolvolumeoff,
    'onDark-control_volume_up.svg': Ondarkcontrolvolumeup,
    'onDark-download_downloaded.svg': Ondarkdownloaddownloaded,
    'onDark-download_downloading.svg': Ondarkdownloaddownloading,
    'onDark-download_failed.svg': Ondarkdownloadfailed,
    'onDark-download_loading.svg': Ondarkdownloadloading,
    'onDark-download_start.svg': Ondarkdownloadstart,
    'onDark-fullscreen.svg': Ondarkfullscreen,
    'onDark-fullscreen_exit.svg': Ondarkfullscreenexit,
    'onDark-overflow.svg': Ondarkoverflow,
    'onDark-pictureInPicture.svg': Ondarkpictureinpicture,
    'onDark-rating-empty.svg': Ondarkratingempty,
    'onDark-rating.svg': Ondarkrating,
    'onDark-record.svg': Ondarkrecord,
    'onDark-recordMultiple.svg': Ondarkrecordmultiple,
    'onDark-recordMultiple_cancel.svg': Ondarkrecordmultiplecancel,
    'onDark-record_cancel.svg': Ondarkrecordcancel,
    'onDark-restart.svg': Ondarkrestart,
    'onDark-select.svg': Ondarkselect,
    'onDark-settings.svg': Ondarksettings,
    'onDark-share.svg': Ondarkshare,
    'onDark-subtitles.svg': Ondarksubtitles,
    'onDark-tvGuide.svg': Ondarktvguide,
    'onDark-geoblocked.svg': Ondarkgeoblocked,
    'onDark-filter.svg': Ondarkfilter,
    'onDark-control_upsell.svg': Ondarkcontrol_upsell,
    'onDark-control_downloadStart.svg': Ondarkcontrol_downloadStart,
    'onDark-control_downloadLoading.svg': Ondarkcontrol_downloadLoading,
    'onDark-control_downloadFailed.svg': OndarkcontroldownloadFailed,
    'onDark-control_downloadDownloading.svg': OndarkcontroldownloadDownloading,
    'onDark-control_downloadDownloaded.svg': OndarkcontroldownloadDownloaded,
    'onDark-backToLive.svg': OndarkbackToLive,
    'onDark-videoQuality.svg': Ondarkvideoquality,
    'onLabel-Purchase.svg': Onlabelpurchase,
    'onLabel-catchup.svg': Onlabelcatchup,
    'onLabel-geoblocked.svg': Onlabelgeoblocked,
    'onLabel-locked.svg': Onlabellocked,
    'onLabel-record.svg': Onlabelrecord,
    'onLabel-recordMultiple.svg': Onlabelrecordmultiple,
    'onLabel-reminder.svg': Onlabelreminder,
    'onPrimary-Backspace.svg': Onprimarybackspace,
    'onPrimary-Calendar.svg': Onprimarycalendar,
    'onPrimary-image.svg': Onprimaryimage,
    'onPrimary-Sort.svg': Onprimarysort,
    'onPrimary-airplay.svg': Onprimaryairplay,
    'onPrimary-back.svg': Onprimaryback,
    'onPrimary-back_rtl.svg': Onprimarybackrtl,
    'onPrimary-capslockAll.svg': Onprimarycapslockall,
    'onPrimary-capslockOff.svg': Onprimarycapslockoff,
    'onPrimary-capslockOnce.svg': Onprimarycapslockonce,
    'onPrimary-chromecast.svg': Onprimarychromecast,
    'onPrimary-chromecast_connected.svg': Onprimarychromecastconnected,
    'onPrimary-clear.svg': Onprimaryclear,
    'onPrimary-close.svg': Onprimaryclose,
    'onPrimary-collapseDown.svg': Onprimarycollapsedown,
    'onPrimary-confirm.svg': Onprimaryconfirm,
    'onPrimary-control_forward10.svg': Onprimarycontrolforward10,
    'onPrimary-control_pause.svg': Onprimarycontrolpause,
    'onPrimary-control_play.svg': Onprimarycontrolplay,
    'onPrimary-control_replay10.svg': Onprimarycontrolreplay10,
    'onPrimary-control_skipNext.svg': Onprimarycontrolskipnext,
    'onPrimary-control_skipPrevious.svg': Onprimarycontrolskipprevious,
    'onPrimary-control_volumeHigh.svg': Onprimarycontrolvolumehigh,
    'onPrimary-control_volumeLow.svg': Onprimarycontrolvolumelow,
    'onPrimary-control_volumeMid.svg': Onprimarycontrolvolumemid,
    'onPrimary-control_volumeMute.svg': Onprimarycontrolvolumemute,
    'onPrimary-delete.svg': Onprimarydelete,
    'onPrimary-device_family.svg': Onprimarydevicefamily,
    'onPrimary-device_gameConsole.svg': Onprimarydevicegameconsole,
    'onPrimary-device_laptop.svg': Onprimarydevicelaptop,
    'onPrimary-device_smartphone.svg': Onprimarydevicesmartphone,
    'onPrimary-device_smartwatch.svg': Onprimarydevicesmartwatch,
    'onPrimary-device_tablet.svg': Onprimarydevicetablet,
    'onPrimary-device_tv.svg': Onprimarydevicetv,
    'onPrimary-download_downloaded.svg': Onprimarydownloaddownloaded,
    'onPrimary-download_downloading.svg': Onprimarydownloaddownloading,
    'onPrimary-download_failed.svg': Onprimarydownloadfailed,
    'onPrimary-download_loading.svg': Onprimarydownloadloading,
    'onPrimary-download_start.svg': Onprimarydownloadstart,
    'onPrimary-edit.svg': Onprimaryedit,
    'onPrimary-add.svg': Onprimaryadd,
    'onPrimary-error.svg': Onprimaryerror,
    'onPrimary-expiration.svg': Onprimaryexpiration,
    'onPrimary-field_clear.svg': Onprimaryfieldclear,
    'onPrimary-field_search.svg': Onprimaryfieldsearch,
    'onPrimary-filter.svg': Onprimaryfilter,
    'onPrimary-lock.svg': Onprimarylock,
    'onPrimary-geoblocked.svg': Onprimarygeoblocked,
    'onPrimary-myPin.svg': Onprimarymypin,
    'onPrimary-openExternal.svg': Onprimaryopenexternal,
    'onPrimary-overflow.svg': Onprimaryoverflow,
    'onPrimary-parental_control.svg': Onprimaryparentalcontrol,
    'onPrimary-payment_history.svg': Onprimarypaymenthistory,
    'onPrimary-payment_method.svg': Onprimarypaymentmethod,
    'onPrimary-purchase.svg': Onprimarypurchase,
    'onPrimary-rating.svg': Onprimaryrating,
    'onPrimary-rating–empty.svg': Onprimaryratingempty,
    'onPrimary-scroll_down.svg': Onprimaryscrolldown,
    'onPrimary-scroll_up.svg': Onprimaryscrollup,
    'onPrimary-seeAll.svg': Onprimaryseeall,
    'onPrimary-select.svg': Onprimaryselect,
    'onPrimary-share.svg': Onprimaryshare,
    'onPrimary-signOut.svg': Onprimarysignout,
    'onPrimary-spinner.svg': Onprimaryspinner,
    'onPrimary-subpage.svg': Onprimarysubpage,
    'onPrimary-subpage_rtl.svg': Onprimarysubpagertl,
    'onPrimary-subscription.svg': Onprimarysubscription,
    'onPrimary-viewChannel.svg': Onprimaryviewchannel,
    'onPrimary-viewTime.svg': Onprimaryviewtime,
    'onPrimary-technicalInfo.svg': OnprimarytechnicalInfo,
    'onPrimary-success.svg': Onprimarysuccess,
    'onPrimary-signIn.svg': OnprimarysignIn,
    'onPrimary-reminders.svg': Onprimaryreminders,
    'onPrimary-recordings.svg': Onprimaryrecordings,
    'onPrimary-ratingEmpty.svg': OnprimaryratingEmpty,
    'onPrimary-playbackSpeed100.svg': OnprimaryplaybackSpeed100,
    'onPrimary-play.svg': Onprimaryplay,
    'onPrimary-paymentMethod.svg': OnprimarypaymentMethod,
    'onPrimary-parentalControl.svg': OnprimaryparentalControl,
    'onPrimary-order.svg': Onprimaryorder,
    'onPrimary-link.svg': Onprimarylink,
    'onPrimary-language.svg': Onprimarylanguage,
    'onPrimary-info.svg': Onprimaryinfo,
    'onPrimary-history.svg': Onprimaryhistory,
    'onPrimary-favoriteChannelBorder.svg': OnprimaryfavoriteChannelBorder,
    'onPrimary-favoriteChannel.svg': OnprimaryfavoriteChannel,
    'onPrimary-downloadStart.svg': OnprimarydownloadStart,
    'onPrimary-downloadLoading.svg': OnprimarydownloadLoading,
    'onPrimary-downloadFailed.svg': OnprimarydownloadFailed,
    'onPrimary-downloadDownloading.svg': OnprimarydownloadDownloading,
    'onPrimary-downloadDownloaded.svg': OnprimarydownloadDownloaded,
    'onPrimary-download.svg': Onprimarydownload,
    'onPrimary-control_rewind10.svg': Onprimarycontrol_rewind10,
    'onPrimary-actor.svg': Onprimaryactor,
    'onTileMainButton-Purchase.svg': Ontilemainbuttonpurchase,
    'onTileMainButton-edit.svg': Ontilemainbuttonedit,
    'onTileMainButton-pause.svg': Ontilemainbuttonpause,
    'onTileMainButton-play.svg': Ontilemainbuttonplay,
    'onTileMainButton-delete.svg': Ontilemainbuttonpdelete,
    'onTileMainButton-cancel.svg': Ontilemainbuttonpcancel,
    'phone.svg': Phone,
    'phone_bkstgState_selected.svg': Phonebkstgstateselected,
    'play-primary.svg': Playprimary,
    'play-secondary.svg': Playsecondary,
    'play.svg': Play,
    'play_bkstgState_selected.svg': Playbkstgstateselected,
    'recordings.svg': Recordings,
    'recordings_bkstgState_selected.svg': Recordingsbkstgstateselected,
    'reminders.svg': Reminders,
    'reminders_bkstgState_selected.svg': Remindersbkstgstateselected,
    'replay.svg': Replay,
    'replay_bkstgState_selected.svg': Replaybkstgstateselected,
    'search.svg': Search,
    'search_bkstgState_selected.svg': Searchbkstgstateselected,
    'settings.svg': Settings,
    'settings_bkstgState_selected.svg': Settingsbkstgstateselected,
    'show-all.svg': Showall,
    'sign-out.svg': Signout,
    'social_facebook.svg': Socialfacebook,
    'social_instagram.svg': Socialinstagram,
    'social_twitter.svg': Socialtwitter,
    'social_youtube.svg': Socialyoutube,
    'star.svg': Star,
    'star_bkstgState_selected.svg': Starbkstgstateselected,
    'streaming.svg': Streaming,
    'streaming_bkstgState_selected.svg': Streamingbkstgstateselected,
    'tv-guide.svg': Tvguide,
    'tv.svg': Tv,
    'tv_bkstgState_selected.svg': Tvbkstgstateselected,
    'videoLibrary.svg': Videolibrary,
    'videoLibrary_bkstgState_selected.svg': Videolibrarybkstgstateselected,
    'videocam.svg': Videocam,
    'videocam_bkstgState_selected.svg': Videocambkstgstateselected,
    'vod.svg': Vod,
    'vod_bkstgState_selected.svg': Vodbkstgstateselected,
    'watchLater.svg': Watchlater,
    'watchLater_bkstgState_selected.svg': Watchlaterbkstgstateselected,
};
