import {
    ASYNC_STORAGE_KEY_DEFAULT_TEXT_TRACK,
    ASYNC_STORAGE_KEY_DEFAULT_AUDIO_TRACK,
    ASYNC_STORAGE_KEY_DEFAULT_VOLUME,
} from '@24i/nxg-core-utils/src/constants';
import { PlayerBase, ITrack, TrackKind, ITextTrack } from '@24i/player-base';
import { EventMapType } from '@24i/player-utils';
import { Profile } from '@24i/nxg-sdk-photon';
import { useEffect, useRef } from 'react';
import { Storage } from '@24i/nxg-sdk-quantum';

const findLanguage = <T extends ITrack>(tracks: T[], selectedTrack: string) =>
    tracks.find(({ language }) => language === selectedTrack);

export const useDefaultPlayerOptions = (
    playerRef: React.MutableRefObject<PlayerBase<EventMapType, EventMapType> | null>,
    selectedProfile: Profile | null
) => {
    const AUDIO_TRACK_KEY = `${ASYNC_STORAGE_KEY_DEFAULT_AUDIO_TRACK}_${selectedProfile?.id}`;
    const TEXT_TRACK_KEY = `${ASYNC_STORAGE_KEY_DEFAULT_TEXT_TRACK}_${selectedProfile?.id}`;
    const VOLUME_KEY = `${ASYNC_STORAGE_KEY_DEFAULT_VOLUME}_${selectedProfile?.id}`;
    const playerInitialized = useRef(false);
    const setAndSaveDefaultTextTrack = async () => {
        if (playerInitialized.current && playerRef.current?.hasLoadedMetadata) {
            const textTracks = playerRef.current?.textTracks;
            const selectedTextTrack = textTracks?.find(({ selected }) => selected);
            if (selectedTextTrack?.language) {
                Storage.setItem(TEXT_TRACK_KEY, selectedTextTrack?.language);
            } else if (selectedTextTrack?.kind.toLocaleLowerCase() === TrackKind.Empty) {
                Storage.setItem(TEXT_TRACK_KEY, 'off');
            }
        }
    };
    const setAndSaveDefaultAudioTrack = async () => {
        if (playerInitialized.current && playerRef.current?.hasLoadedMetadata) {
            const audioTracks = playerRef.current?.audioTracks;
            const selectedAudioTrack = audioTracks?.find(({ selected }) => selected);
            if (selectedAudioTrack?.language) {
                Storage.setItem(AUDIO_TRACK_KEY, selectedAudioTrack?.language);
            }
        }
    };
    const setAndSaveDefaultVolume = async () => {
        if (playerInitialized.current) {
            const volumeValue = playerRef.current?.volume;
            if (Number.isFinite(volumeValue)) {
                Storage.setItem(VOLUME_KEY, String(volumeValue));
            }
        }
    };

    const setDefaultOptions = async () => {
        const defaultTextTrackLanguage = await Storage.getItem(TEXT_TRACK_KEY);
        const defaultAudioTrackLanguage = await Storage.getItem(AUDIO_TRACK_KEY);
        const defaultVolumeValue = await Storage.getItem(VOLUME_KEY);

        if (playerRef.current) {
            const textTracks = playerRef.current?.textTracks;
            const audioTracks = playerRef.current?.audioTracks;

            let defaultTextTrack: ITextTrack | undefined;
            if (defaultTextTrackLanguage === 'off') {
                defaultTextTrack = textTracks.find(({ kind }) => kind?.toLowerCase() === 'empty');
            } else if (defaultTextTrackLanguage) {
                defaultTextTrack = findLanguage(textTracks, defaultTextTrackLanguage);
            }
            const defaultAudioTrack =
                defaultAudioTrackLanguage && findLanguage(audioTracks, defaultAudioTrackLanguage);

            if (defaultAudioTrack) {
                // eslint-disable-next-line no-param-reassign
                playerRef.current.audioTrack = defaultAudioTrack;
            }

            if (defaultTextTrack) {
                // eslint-disable-next-line no-param-reassign
                playerRef.current.textTrack = defaultTextTrack;
            }

            // Hasn't been tested
            if (defaultVolumeValue) {
                // eslint-disable-next-line no-param-reassign
                playerRef.current.volume = Number(defaultVolumeValue);
            }
            playerInitialized.current = true;
        }
    };

    useEffect(() => {
        playerRef.current?.addEventListener('texttrackchanged', setAndSaveDefaultTextTrack);
        playerRef.current?.addEventListener('audiotrackchanged', setAndSaveDefaultAudioTrack);
        playerRef.current?.addEventListener('volumechange', setAndSaveDefaultVolume);
        playerRef.current?.addEventListener('loadedmetadata', setDefaultOptions);

        return () => {
            playerRef.current?.removeEventListener('texttrackchanged', setAndSaveDefaultTextTrack);
            playerRef.current?.removeEventListener(
                'audiotrackchanged',
                setAndSaveDefaultAudioTrack
            );
            playerRef.current?.removeEventListener('volumechange', setAndSaveDefaultVolume);
            playerRef.current?.removeEventListener('loadedmetadata', setDefaultOptions);
        };
    }, [playerRef.current]);
};
