import React from 'react';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';
import PodcastPinBlockedModal, { PinRequiredCause } from '../..';

export const usePodcastPinBlockedModal = () => {
    const { closeModal, setModalChildren } = useModal();

    const openPodcastPinBlockedModal = (triggerPinControl: () => void, cause: PinRequiredCause) => {
        setModalChildren(
            <PodcastPinBlockedModal
                closeModal={closeModal}
                triggerPinControl={triggerPinControl}
                cause={cause}
            />
        );
    };

    return { openPodcastPinBlockedModal, closeBlockedModal: closeModal };
};
