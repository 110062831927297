import i18n from 'i18next';
import * as t from 'io-ts';
import { Platform } from 'react-native';

import { getDeviceId } from '@24i/nxg-core-utils';
import { ASYNC_STORAGE_KEY_USER_TOKEN } from '@24i/nxg-core-utils/src/constants';
import { Storage } from '@24i/nxg-sdk-quantum';
import { ErrorCodes } from '@24i/nxg-sdk-smartott/src/utils/errorCodesMapper/types';
import { DefaultTheme } from '@24i/nxg-integration-backstage/src/clients/ThemeDataClient/backstageDefaultTheme';

import { decodeApiResponseWith } from '../guards';
import { omitUndefinedOrNull } from '../object';

const BASE_URI = 'https://backstage-api.com';
const BASE_CDN_URI = 'https://cdn.backstage-api.com';

const getDeviceType = () => {
    let platform = 'undefined';

    if (Platform.isTV) {
        platform = 'smarttv';
    } else {
        platform = Platform.OS.toLowerCase();
    }

    return platform;
};

// Get token from default authorization token storage.
// Is used as fallback when no other token getter is provided.
const getToken = () => Storage.getItem(ASYNC_STORAGE_KEY_USER_TOKEN);
const setToken = async (token: string) => Storage.setItem(ASYNC_STORAGE_KEY_USER_TOKEN, token);
const removeToken = () => Storage.removeItem(ASYNC_STORAGE_KEY_USER_TOKEN);

// @TODO: getter should be idealy as part of BaseApi initialization
// BaseApi should not be tightly coupled with react library
const getLanguage = () => i18n.language;

export interface RuntimeParams {
    baseUri?: string;
    serviceId: string;
    applicationId: string;
}

export const isRuntimeParams = (params: unknown): params is RuntimeParams => {
    if (params && typeof params === 'object') {
        return 'serviceId' in params && 'applicationId' in params;
    }
    return false;
};

let refreshToken: Promise<number> | undefined;

const defaultRefetchToken = async () => ({
    status: 200,
    token: undefined,
});
export interface BaseApiParams extends RuntimeParams {
    token?: string;
    getToken?: () => Promise<string | undefined>;
    setToken?: (token: string) => Promise<void>;
    removeToken?: () => Promise<string | undefined>;
    baseUri?: string;
    defaultTheme?: DefaultTheme;
}

type RefetchToken = () => Promise<{ token: any; status: number }>;

export class BaseApi {
    opts: BaseApiParams;

    getToken: () => Promise<string | undefined>;

    setToken: (token: string) => Promise<void>;

    removeToken: () => Promise<void>;

    /**
     *
     * @param opts BaseApiParams
     * @param BaseApiParams.token [string] Authorization code used for Authorization header
     * @param BaseApiParams.getToken [() => string] Asynchronous function to obtain token used for Authorization header
     * @param BaseApiParams.baseUri [string] Base api url. Must not end with `/`
     */
    constructor(opts: BaseApiParams) {
        this.opts = opts;
        this.getToken = opts?.getToken ?? getToken;
        this.setToken = opts?.setToken ?? setToken;
        this.removeToken = opts?.removeToken ?? removeToken;
    }

    updateToken = async (
        resolve: (value: number) => void,
        reject: (reason?: unknown) => void,
        refetchToken: RefetchToken
    ) => {
        try {
            // Refresh JWT token, needed for keeping user logged in, when token is expired.
            const { token, status } = await refetchToken();

            if (token) {
                await this.setToken?.(token);
            }
            resolve(status);
        } catch (e) {
            reject(e);
        } finally {
            refreshToken = undefined;
        }
    };

    // eslint-disable-next-line consistent-return
    protected async request<EncodeTo>(
        context: RequestOpts<EncodeTo, unknown>,
        refetchToken: RefetchToken = defaultRefetchToken
    ): Promise<EncodeTo> {
        const { url, init } = await this.createFetchParams(context);
        let response = await fetch(url, init);

        const shouldRefetchToken = !!init.headers?.Authorization && response.status === 401;
        let shouldForceLogout = response.status === 401;

        if (shouldRefetchToken) {
            if (!refreshToken) {
                refreshToken = new Promise((res, rej) => this.updateToken(res, rej, refetchToken));
            }

            const status = await refreshToken;

            if (status === 200) {
                const { url: urlRecall, init: initRecall } = await this.createFetchParams(context);
                response = await fetch(urlRecall, initRecall);
            } else if (status !== 401) {
                shouldForceLogout = false;
            }
        }

        if (response.status === 401) {
            if (shouldForceLogout) {
                await this.removeToken?.();
            }
            throw new Error(ErrorCodes.INVALID_AUTHENTICATION_TOKEN);
        }

        const contentType = response.headers.get('content-type');

        if (response.status === 204) {
            // 204 NO CONTENT
            return undefined as unknown as EncodeTo;
        }

        if (contentType?.includes('text/html')) {
            const responseText = (await response.text()) as any;
            if (!response.ok) {
                // not status 200 - 299
                throw responseText;
            }
            return responseText;
        }

        const data = await response.json();

        if (!response.ok) {
            // not status 200 - 299
            throw data;
        }

        if (context.guard) {
            return decodeApiResponseWith(context.guard, data, context.path);
        }

        return data;
    }

    protected async createFetchParams<EncodeTo>(context: RequestOpts<EncodeTo, unknown>) {
        let url = (context.baseUri ?? this.opts.baseUri ?? BASE_URI) + context.path;

        if (context.query !== undefined && Object.keys(context.query).length !== 0) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            url += `?${querystring(context.query)}`;
        }

        const serviceId = context.serviceId ?? this.opts.serviceId;
        const applicationId = context.applicationId ?? this.opts.applicationId;
        const token = context.token ?? this.opts.token ?? (await this.getToken?.());

        const body =
            (typeof FormData !== 'undefined' && context.body instanceof FormData) ||
            context.body instanceof URLSearchParams ||
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            isBlob(context.body)
                ? context.body
                : JSON.stringify(context.body);

        const headers: HTTPHeaders = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Device-ID': await getDeviceId(),
            'X-Device-Type': getDeviceType(),
            'Accept-Language': getLanguage(),
            ...(serviceId && { 'X-Service-ID': serviceId }),
            ...(applicationId && { 'X-Application-ID': applicationId }),
            ...(token && { Authorization: `Bearer ${token}` }),
            ...context.headers,
        };

        const init = {
            method: context.method,
            headers: omitUndefinedOrNull(headers),
            body,
            signal: context?.signal,
        };

        return { url, init };
    }
}

const isBlob = (value: any) => typeof Blob !== 'undefined' && value instanceof Blob;

/**
 * @param params HTTPQuery query parametr object
 * @param prefix [string] optional prefix for query keys
 * @returns Encoded string representing http query parametr
 */
const querystring = (params: HTTPQuery, prefix = ''): string => {
    return Object.keys(params)
        .map((key) => {
            const fullKey = prefix + (prefix.length ? `[${key}]` : key);
            const value = params[key];
            if (value instanceof Array) {
                const multiValue = value
                    .map((singleValue) => encodeURIComponent(String(singleValue)))
                    .join(`&${encodeURIComponent(fullKey)}=`);
                return `${encodeURIComponent(fullKey)}=${multiValue}`;
            }
            if (value instanceof Object) {
                return querystring(value as HTTPQuery, fullKey);
            }
            return `${encodeURIComponent(fullKey)}=${encodeURIComponent(String(value))}`;
        })
        .filter((part) => part.length > 0)
        .join('&');
};

export type Json = any;
export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'HEAD';
export type HTTPHeaders = { [key: string]: string | null };
export type HTTPQuery = {
    [key: string]:
        | string
        | number
        | null
        | boolean
        | Array<string | number | null | boolean>
        | HTTPQuery;
};
export type HTTPBody = Json | FormData | URLSearchParams;

export interface FetchParams {
    url: string;
    init: RequestInit;
}

export interface RequestOpts<EncodeTo, DecodeFrom> {
    path: string;
    method: HTTPMethod;
    baseUri?: string;
    headers?: HTTPHeaders;
    query?: HTTPQuery;
    body?: HTTPBody;
    serviceId?: string;
    applicationId?: string;
    token?: string;
    guard?: t.Type<EncodeTo, EncodeTo, DecodeFrom>;
    signal?: AbortSignal;
}

export const isBaseCDNResource = (url: string) => {
    return url.startsWith(BASE_CDN_URI);
};
