import React, { ReactElement } from 'react';

import { usePlayerAnalytics } from '@24i/nxg-sdk-smartott-shared/src/analytics/player/usePlayerAnalytics';

import { PlaybackScreenProps } from './types';
import View from './View';
import { useViewModel } from './viewModel';

const Main = (props: PlaybackScreenProps): ReactElement | null => {
    const viewModelProps = useViewModel(props);
    const propsWithAnalytics = usePlayerAnalytics(viewModelProps);
    const { engine } = viewModelProps;

    return engine ? <View {...viewModelProps} {...propsWithAnalytics} /> : null;
};

export default {
    Main,
    View,
    useViewModel,
    // use when SSR is re-implemented
    // page: {
    //    player: prerenderFunctions,
    // },
};
