import { extendGuard, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { BroadcastGuard } from './broadcast';
import { ASSET_TYPE } from '../enums';
import { AssetGuard } from './asset';

export const ChannelGuard = extendGuard(
    'Channel',
    AssetGuard,
    {
        number: t.number,
        programs: nullable(t.array(BroadcastGuard)),
        label: nullable(t.string),
        isChannel: nullable(t.boolean),
        features: nullable(
            t.type({
                live: t.type({
                    options: t.type({
                        startover: t.boolean,
                    }),
                }),
            })
        ),
    },
    {}
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isChannel = (asset: any): asset is Channel => {
    return asset && asset.type === ASSET_TYPE.CHANNEL;
};

export type Channel = t.TypeOf<typeof ChannelGuard>;
