import autoBind from 'auto-bind';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';

import {
    ProcessReceiptRequest,
    PurchaseDataClient,
    Product,
    Edition,
    PurchasesProps,
    PLAYLIST_TYPE,
    Asset,
    ASSET_TYPE,
} from '@24i/nxg-sdk-photon';
import { BaseApiParams } from '@24i/nxg-core-utils/src/api';
import { log } from '@24i/nxg-core-utils/src/logger';

import { BackstageApiBase } from '../../base';
import { PlaylistResponseGuard } from '../ContentDataClient/guards';
import {
    ExternalCheckoutResponseGuard,
    ProcessReceiptResponseGuard,
    ProductsResponseGuard,
} from './guards';
import { mapSubscriptionProductsResponse } from './mappers';
import { ContentDataClient } from '../ContentDataClient';

export class BackstagePurchaseDataClient extends BackstageApiBase implements PurchaseDataClient {
    contentDataClient: ContentDataClient;

    constructor(params: BaseApiParams, contentDataClient) {
        super(params);
        autoBind(this);
        this.contentDataClient = contentDataClient;
    }

    // New
    async fetchAvailableSubscriptions() {
        return this.request({
            path: '/products/subscription',
            method: 'GET',
            guard: ProductsResponseGuard,
        }).then((res) => mapSubscriptionProductsResponse(res));
    }

    // New
    async fetchEntitledSubscriptions() {
        return this.request({
            path: '/user/products',
            method: 'GET',
            guard: ProductsResponseGuard,
        }).then((res) => mapSubscriptionProductsResponse(res));
    }

    async fetchUserProducts() {
        return this.request({
            path: '/user/products',
            method: 'GET',
            guard: ProductsResponseGuard,
        });
    }

    async fetchExternalCheckoutLink(productId: string) {
        return this.request({
            path: `/user/subscriptions/external-checkout/${productId}`,
            method: 'GET',
            guard: ExternalCheckoutResponseGuard,
        });
    }

    async fetchIsAssetPurchased(asset: PurchasesProps, editionId?: string) {
        const products = await this.fetchAvailableAssetPurchases(asset, editionId);

        if (isEmpty(products.availablePurchases)) {
            return { isPurchased: true };
        }

        return { isPurchased: false };
    }

    async fetchAvailableAssetPurchases(asset: PurchasesProps, editionId?: string) {
        let purchases: Product[] = [];
        if ([ASSET_TYPE.CUSTOM, ASSET_TYPE.IMAGE].includes(asset.type as any)) {
            return { availablePurchases: purchases };
        }
        try {
            const editions = await this.contentDataClient?.fetchEditions(asset as unknown as Asset);

            const availableEditions = editionId
                ? editions.filter((ed) => ed.id === editionId)
                : editions;

            const sharedProducts = availableEditions.reduce(
                (editionProducts: Product[], edition: Edition) => {
                    const editionProduct = edition?.blocked?.[0]?.resolvement?.data?.products;

                    if (editionProduct) {
                        return editionProducts.concat(editionProduct);
                    }

                    return editionProducts;
                },
                []
            );

            purchases = uniqBy(sharedProducts, 'id');
        } catch (e) {
            log(e);
        }

        return { availablePurchases: purchases };
    }

    fetchMyPlaylistByType = (type: PLAYLIST_TYPE) => {
        return this.request({
            path: `/playlists/${type}`,
            method: 'GET',
            guard: PlaylistResponseGuard,
        });
    };

    async fetchMyPurchases() {
        return this.fetchMyPlaylistByType(PLAYLIST_TYPE.PURCHASED);
    }

    async fetchMyRentals() {
        return this.fetchMyPlaylistByType(PLAYLIST_TYPE.RENTALS);
    }

    async fetchMyExpiredRentals() {
        return this.fetchMyPlaylistByType(PLAYLIST_TYPE.EXPIRED_RENTALS);
    }

    // New
    async cancelSubscription(subscriptionId: string) {
        return this.request({
            path: `/user/products/${subscriptionId}/cancel`,
            method: 'POST',
        });
    }

    // New
    async processPayment(productId: string, receipt: ProcessReceiptRequest) {
        return this.request({
            path: `/user/products/${productId}/record-receipt`,
            method: 'POST',
            body: receipt,
            guard: ProcessReceiptResponseGuard,
        });
    }
}

export const createBackstagePurchaseDataClient = (params: BaseApiParams, contentDataClient) =>
    new BackstagePurchaseDataClient(params, contentDataClient);
