import { StyleSheet, ViewStyle } from 'react-native';
import { CSSProperties } from 'react';
import { Theme } from '@24i/nxg-sdk-photon/src';
import { GenericIconProps, IconVariant } from './types';

export const isURL = (input: string) => input.startsWith('https://');

// FIXME: Fix type casting
export const getIconStyles = (iconStyles: GenericIconProps['iconStyles'], size: number) => {
    const svgStyles = StyleSheet.flatten(iconStyles) as unknown as ViewStyle;
    const imageStyles = { width: size, height: size, ...svgStyles } as unknown as CSSProperties;

    return {
        imageStyles,
        svgStyles,
    };
};

type Params = {
    theme: Theme;
    iconName: string;
    variant: IconVariant;
    isActive?: boolean;
};
export const extractIconPath = ({ theme, iconName, variant, isActive }: Params) => {
    switch (variant) {
        case 'theme': {
            const themeIcon = theme.icons[iconName as string];
            return { ...themeIcon, path: themeIcon?.svg?.[0]?.path };
        }

        case 'navigation': {
            let navigationIcon = theme.icons.menu.iconsMap[iconName as string];
            if (!navigationIcon && iconName.startsWith('menu-icons-')) {
                navigationIcon = theme.icons.menu.iconsMap[iconName.substring(11)];
            }
            const basePath =
                navigationIcon != null && 'uri' in navigationIcon ? navigationIcon.uri : null;
            const activePath =
                navigationIcon != null && 'activeUri' in navigationIcon
                    ? navigationIcon.activeUri
                    : null;
            const idealPath = isActive ? activePath : basePath;
            return { ...navigationIcon, path: idealPath ?? basePath ?? activePath };
        }

        default: {
            const themeIcon = theme.icons[iconName as string];
            return { ...themeIcon, path: undefined };
        }
    }
};
