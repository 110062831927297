import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Platform } from 'react-native';

import {
    Asset,
    ASSET_TYPE,
    BlockedItem,
    BLOCKING_REASON_TYPES,
    Edition,
    QUERY_KEYS,
} from '@24i/nxg-sdk-photon';
import { log } from '@24i/nxg-core-utils/src/logger';
import { usePurchaseData } from '@24i/nxg-sdk-smartott-shared/src/context/PurchaseData';
import { useEntitlements } from '@24i/nxg-sdk-smartott-shared/src/context/Entitlements';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';
import { useAdobePrimetime } from '@24i/adobe-primetime/src';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import {
    AUTHORIZATION_PROTECTION_LEVEL,
    AUTHORIZATION_STATUS,
} from '@24i/adobe-primetime/src/shared/accessEnablerConstants';

import { useFirebase } from '../../context/Firebase';
import { useShouldSignIn } from '../useShouldSignIn';
import { CustomAsset } from '../../screens/DynaRowScreen/types';
import useBlockedModal from '../../components/BlockedModal/hooks';
import { BlockModalTypes, Blocker } from '../../components/BlockedModal/types';
import useErrorModal from '../../components/GenericModal/hooks/useErrorModal';
import { useStore } from '../../context/ApplicationStore';

export interface AssetBlockersValidationProps {
    onGeoBlockOverride?: (blocker?: BlockedItem | null) => void;
    onSubscriptionBlockOverride?: (blocker?: BlockedItem | null) => void;
    onAuthenticationBlockOverride?: () => void;
    onErrorOverride?: (blocker?: BlockedItem | null) => void;
}

/**
 * The useAssetBlockersValidation hook provides functions to fetch and handle asset blockers from an asset that may have multiple blockers. When this happens
 * in order to provide a consistent user experience, this functions will allow to resolve them in a single order.
 * @param: onGeoBlockOverride, onAuthenticationBlockOverride,onSubscriptionBlockOverride, onErrorOverride - Optional params that will be used when calling handleBlockersCheck validation.
 * If these are not provided, when calling handleBlockersCheck, if an asset has a blocker user will be presented with default modals.
 * @returns: Functions handleBlockersCheck,fetchAssetBlocker - When an asset has multiple blockers, fetchAssetBlocker will return an array of blockers, while handleBlockersCheck will handle the
 * expected behaviour in case a blocker is present. It will present default modals, which can be overriden by the hook optional params.
 */
const useAssetBlockersValidation = ({
    onGeoBlockOverride,
    onAuthenticationBlockOverride,
    onSubscriptionBlockOverride,
    onErrorOverride,
}: AssetBlockersValidationProps) => {
    const queryClient = useQueryClient();
    const shouldSignIn = useShouldSignIn();
    const { fetchIsAssetPurchased } = usePurchaseData();
    const { fetchEditions }: { fetchEditions: (asset: Asset) => Promise<Edition[]> } =
        useContentData();
    const { recordError } = useFirebase();
    const { checkForBlocker } = useEntitlements();
    const { openBlockedModal } = useBlockedModal();
    const { openErrorModal } = useErrorModal();
    const { t } = useTranslation();
    const {
        checkAuthorization,
        authState: { authenticated },
    } = useAdobePrimetime();

    const adobePrimetimeEnabled = useFeature('adobePrimetime')?.enabled;

    const { userData } = useStore();
    const isUserLogged = !!userData;

    const fetchAssetBlockers = async (
        asset: Asset | CustomAsset
    ): Promise<Blocker[] | undefined> => {
        const [{ isPurchased }, { blocker }, editions = []] = await Promise.all([
            queryClient.fetchQuery({
                queryKey: [QUERY_KEYS.isPurchased, asset.id],
                queryFn: () => fetchIsAssetPurchased(asset, asset.editionId ?? undefined),
            }),
            queryClient.fetchQuery({
                queryKey: [QUERY_KEYS.blockers, asset.id],
                queryFn: () => checkForBlocker(asset),
            }),
            queryClient.fetchQuery({
                queryKey: [QUERY_KEYS.editions, asset.id],
                queryFn: () => fetchEditions(asset).catch(() => {}),
            }),
        ]);

        const blockers: Blocker[] = [];

        if (editions && editions.length) {
            const nonblockedEdition = editions.some((edition) => !edition.blocked);
            if (!nonblockedEdition) {
                const editionBlocker = editions[0].blocked?.find((b) => b.reason);
                if (editionBlocker?.reason) {
                    blockers.push({
                        type: editionBlocker.reason,
                        blocker: editionBlocker,
                    });
                }
            }
        }
        if (blocker && blocker.reason) blockers.push({ type: blocker.reason, blocker });
        const hasSubscriptionBlocker = blockers.some((assetBlocker) =>
            [
                BLOCKING_REASON_TYPES.SUBSCRIPTION_TYPE,
                BLOCKING_REASON_TYPES.PRODUCTS_MISMATCH,
                BLOCKING_REASON_TYPES.MVPD_TYPE,
            ].includes(assetBlocker.type)
        );
        if (!isPurchased && isUserLogged) {
            blockers.push({ type: BLOCKING_REASON_TYPES.REQUIRES_PURCHASE });
        } else if (
            (shouldSignIn() && !asset.isTrailer) ||
            (!isUserLogged && hasSubscriptionBlocker)
        ) {
            blockers.push({ type: BLOCKING_REASON_TYPES.AUTHENTICATION });
        }

        return blockers;
    };

    const handleBlockersCheck = async ({
        asset,
        handlePurchase,
        openAPTModalOnBlock = true,
        noop = false,
    }: {
        asset: Asset | CustomAsset;
        handlePurchase?: (asset: Asset) => void;
        openAPTModalOnBlock?: boolean;
        noop?: boolean;
    }): Promise<{ hasBlocker: boolean; MVPCustomMessage?: string }> => {
        if (asset?.type === ASSET_TYPE.IMAGE) {
            return { hasBlocker: false };
        }
        try {
            if (adobePrimetimeEnabled) {
                const { externalAuthDetails } = asset;
                if (externalAuthDetails?.primetime?.isProtected) {
                    if (!authenticated) {
                        if (openAPTModalOnBlock)
                            openBlockedModal(BlockModalTypes.MVPD_PROTECTION_LEVEL_ACCESS);
                        return { hasBlocker: true };
                    }
                    if (
                        externalAuthDetails?.primetime?.level ===
                        AUTHORIZATION_PROTECTION_LEVEL.FULL
                    ) {
                        const authorization = await checkAuthorization(asset);
                        if (authorization.status === AUTHORIZATION_STATUS.FAILURE) {
                            const errorDetails = authorization.code.length
                                ? authorization.code
                                : t('error.E03.body');
                            if (openAPTModalOnBlock) {
                                openBlockedModal(
                                    BlockModalTypes.MVPD_PROTECTION_LEVEL_FULL,
                                    errorDetails
                                );
                            }
                            return { hasBlocker: true, MVPCustomMessage: errorDetails };
                        }
                    }
                }
                return { hasBlocker: false };
            }
            const blockers: Blocker[] | undefined = await fetchAssetBlockers(asset);
            const geoBlocker = blockers?.find(
                (blocker) => blocker.type === BLOCKING_REASON_TYPES.GEO_TYPE
            );
            const authenticationBlocker = blockers?.find(
                (blocker) => blocker.type === BLOCKING_REASON_TYPES.AUTHENTICATION
            );
            const subscriptionBlocker = !asset?.isTrailer
                ? blockers?.find(
                      (blocker) =>
                          blocker.type === BLOCKING_REASON_TYPES.SUBSCRIPTION_TYPE ||
                          blocker.type === BLOCKING_REASON_TYPES.PRODUCTS_MISMATCH ||
                          blocker.type === BLOCKING_REASON_TYPES.MVPD_TYPE
                  )
                : null;
            const purchasedBlocker = blockers?.find(
                (blocker) => blocker.type === BLOCKING_REASON_TYPES.REQUIRES_PURCHASE
            );

            if (noop) return { hasBlocker: Boolean(blockers && blockers.length > 0) };

            // Order from: https://aferian.atlassian.net/wiki/spaces/PRJ001NXG/pages/50276806/Blocking+order

            // 1 - MVPD

            // 2 - Geoblock check
            if (geoBlocker) {
                if (onGeoBlockOverride) onGeoBlockOverride(geoBlocker.blocker);
                else openBlockedModal(BlockModalTypes.GEOBLOCK, t('error.E05.body') as string);
                return { hasBlocker: true };
            }

            // 3 - Authentication check
            if (authenticationBlocker) {
                if (onAuthenticationBlockOverride) onAuthenticationBlockOverride();
                else openBlockedModal(BlockModalTypes.ACCESS);
                return { hasBlocker: true };
            }

            // 4.1 - Entitlement check
            if (purchasedBlocker && !!handlePurchase) {
                handlePurchase(asset);
                return { hasBlocker: true };
            }

            // 4.2 - Subscription check
            if (subscriptionBlocker) {
                let subscriptionBlockerDetail = subscriptionBlocker.blocker;
                if (onSubscriptionBlockOverride) {
                    onSubscriptionBlockOverride(subscriptionBlockerDetail);
                } else if (subscriptionBlockerDetail) {
                    if (!subscriptionBlockerDetail.message) {
                        let errorCode: string;
                        if (!isUserLogged) errorCode = 'E27';
                        else if (Platform.OS === 'web') errorCode = 'E21';
                        else errorCode = 'E26';
                        subscriptionBlockerDetail = {
                            reason: subscriptionBlockerDetail.reason,
                            message: t(`error.${errorCode}.body`),
                        };
                    }
                    openBlockedModal(BlockModalTypes.ACCOUNT, subscriptionBlockerDetail);
                }
                return { hasBlocker: true };
            }

            return { hasBlocker: false };
        } catch (err) {
            log(err);
            recordError?.(err);

            // If request throws error, shows generic error modal
            if (onErrorOverride) onErrorOverride();
            else openErrorModal(t('error.A00.title'), t('error.A00.body'));

            return { hasBlocker: true };
        }
    };

    return {
        handleBlockersCheck,
        fetchAssetBlockers,
    };
};

export default useAssetBlockersValidation;
